
import { defineComponent, PropType } from 'vue';
import { LOUNGES_CATEGORY_TYPES } from '@white-label-configuration/constants';

import RadioButton from '../radio-button/radio-button.vue';

export default defineComponent({
  name: 'SearchLoungesFilter',

  components: {
    IconButton: () => import('@white-label-icon/icon-button'),
    IconFilter: () => import('@white-label-icon/icon-filter'),
    RadioButton,
  },

  props: {
    filterOptions: {
      type: Array as PropType<
        { name: string; count: number; category_type: string }[]
      >,
      required: true,
    },

    value: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      listShown: false,
      LOUNGES_CATEGORY_TYPES,
    };
  },

  computed: {
    isScrollLocked(): boolean {
      return (
        (this.isMobileResolution || this.isTabletResolution) && this.listShown
      );
    },
  },

  methods: {
    onFilterSelect(categoryType: string): void {
      this.$emit('input', categoryType);
      this.listShown = false;
    },
  },
});
