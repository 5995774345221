var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"scroll-lock",rawName:"v-scroll-lock",value:(_vm.isScrollLocked),expression:"isScrollLocked"}],class:[
    'search-parking-sort',
    { 'search-parking-sort--applied': _vm.value !== _vm.PARKING_SORT_OPTIONS.RECOMMENDED }
  ]},[_c('span',{staticClass:"hide-on-mobile mr-2"},[_vm._v("\n    "+_vm._s(_vm.$t('searchParkingSort.sortBy'))+"\n  ")]),_vm._v(" "),_c('button',{staticClass:"search-parking-sort__toggle-button",on:{"click":function($event){_vm.listShown =! _vm.listShown}}},[_c('IconSort',{staticClass:"hide-on-desktop mr-2"}),_vm._v(" "),_c('span',{staticClass:"hide-on-desktop"},[_vm._v("\n      "+_vm._s(_vm.$t('searchParkingSort.sort'))+"\n    ")]),_vm._v(" "),_c('span',{staticClass:"hide-on-mobile",attrs:{"data-testid":"sort-by-text"}},[_vm._v("\n      "+_vm._s(_vm.$t(`searchParkingSort.${_vm.value}`))+"\n    ")]),_vm._v(" "),_c('IconArrowDown',{staticClass:"hide-on-mobile ml-3"})],1),_vm._v(" "),(_vm.listShown)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => _vm.listShown = false),expression:"() => listShown = false"}],staticClass:"search-parking-sort__list-wrapper",on:{"click":function($event){$event.stopPropagation();_vm.listShown = false},"touchstart":function($event){_vm.listShown = false}}},[_c('div',{staticClass:"search-parking-sort__list",on:{"touchstart":function($event){$event.stopPropagation();}}},[_c('p',{staticClass:"search-parking-sort__list-header hide-on-desktop"},[_vm._v("\n        "+_vm._s(_vm.$t('searchParkingSort.sortBy'))+"\n      ")]),_vm._v(" "),_c('IconButton',{staticClass:"hide-on-desktop",attrs:{"icon-name":"IconClose"},on:{"click":function($event){_vm.listShown = false}}}),_vm._v(" "),_vm._l((_vm.sortOptions),function(option,index){return _c('button',{key:index,class:[
          'search-parking-sort__list-item',
          { 'search-parking-sort__list-item--active': option === _vm.value },
          'ga__search-sort--' + option,
        ],on:{"click":function($event){return _vm.onOptionSelect(option)}}},[_vm._v("\n        "+_vm._s(_vm.$t(`searchParkingSort.${option}`))+"\n        "),(option === _vm.value)?_c('IconCheck',{staticClass:"text-brand-100"}):_vm._e()],1)})],2)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }