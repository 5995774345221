
import { defineComponent, PropType } from 'vue';
import { formatPrice } from '@white-label-helper/helper-payment';
import { readItems } from '@white-label-store/cart';

// Types
import type {
  SearchProducts,
  ProductOptions,
} from '@white-label-types/parking-booking';
import Button from '../button/button.vue';
import ProductTags from '../product-tags/product-tags.vue';
import { TranslateResult } from 'vue-i18n';

export default defineComponent({
  components: {
    ControlButton: Button,
    IconCheck: () => import('@white-label-icon/icon-check'),
    IconLeft: () => import('@white-label-icon/icon-left'),
    IconPlus: () => import('@white-label-icon/icon-plus'),
    IconClose: () => import('@white-label-icon/icon-close'),
    IconSuccess: () => import('@white-label-icon/icon-success'),
    IconCabinBaggage: () => import('@white-label-icon/icon-cabin-baggage'),
    IconBaggage: () => import('@white-label-icon/icon-baggage'),
    IconRestaurant: () => import('@white-label-icon/icon-restaurant'),
    IconClock: () => import('@white-label-icon/icon-clock'),
    ProductTags,
  },

  props: {
    item: {
      type: Object as PropType<SearchProducts>,
      default: () => ({}),
    },
    selectedId: {
      type: [String, Number] as PropType<string | number>,
      default: 0,
    },
    multiBasket: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      photoIndex: 0,
      displayUpgradeModal: false,
      productFullInfoExpanded: false,
      product: {
        name: 'Shiny New Car Park',
        features: ['I am a Feature', 'I am a Feature2'],
        isCurrentBooking: false,
        logo: {
          src: '',
        },
        photos: [
          {
            src: '',
          },
        ],
        id: Math.random(),
        productOptions: [
          {
            sub_options: [],
            fees: [],
            taxes: [],
            details: { cancellation_protection: { is_available: false } },
            id: -1,
            totals: {
              fees: 0,
              total: 60,
              subtotal: 60,
              discount: 0,
              taxes: 0,
              currency: 'USD',
              exchange: null,
              channel_based: { total: 60, total_discounted: 60 },
            },
            is_available: true,
            meta: {
              product_option_code: '',
              direction: '',
            },
          },
        ],
        hasDiscount: false,
        baggage_type: null,
        meal_type: null,
        visiting_duration: null,
        visiting_duration_message: '',
      },
      baggageTypes: {
        checked_baggage: {
          icon: 'IconBaggage',
          text: this.$t('privateLoungeFeatures.baggage.checked_baggage'),
        },
        cabin_baggage: {
          icon: 'IconCabinBaggage',
          text: this.$t('privateLoungeFeatures.baggage.cabin_baggage'),
        },
      },
      mealTypes: {
        carte_menu: {
          icon: 'IconRestaurant',
          text: this.$t('privateLoungeFeatures.meals.carte_menu'),
        },
        no_food_included: {
          icon: 'IconRestaurant',
          text: this.$t('privateLoungeFeatures.meals.no_food_included'),
        },
      },
    };
  },

  computed: {
    cartItems(): ReturnType<typeof readItems> {
      return readItems(this.$store);
    },

    hasDiscount(): boolean {
      return this.product?.hasDiscount;
    },

    isCurrentBooking(): boolean {
      return !!this.product.isCurrentBooking;
    },

    price(): number {
      const productTotal =
        this.product?.productOptions[0]?.totals?.channel_based?.total;
      const totalDiscounted =
        this.product?.productOptions[0]?.totals?.channel_based
          ?.total_discounted;

      return this.hasDiscount ? totalDiscounted : productTotal;
    },

    fullPrice(): string | Intl.NumberFormat {
      return formatPrice(
        this.product?.productOptions[0]?.totals?.channel_based?.total
      );
    },

    flooredPrice(): string {
      const formattedPrice = formatPrice(+this.price);
      return typeof formattedPrice === 'string'
        ? formattedPrice.split('.')[0]
        : '';
    },

    isPreloader(): boolean {
      return !Object.keys(this.item).length;
    },

    priceDecimal(): string {
      return formatPrice(this.price).toString().slice(-2);
    },

    customersPrices(): string[] {
      const mapSubOptions: { [key: string]: ProductOptions } = {};
      this.product.productOptions[0].sub_options.forEach(
        (item: ProductOptions) => {
          if ('name' in item.details) {
            mapSubOptions[item.details.name as string] = item;
          }
        }
      );
      return ['senior', 'adult', 'child', 'infant']
        .map((key) => {
          const item = mapSubOptions[key];
          if (item) {
            const name = item.details?.name_formatted ?? item.details.name;
            const quantity = item?.totals?.quantity || 0;
            const price = this.hasDiscount
              ? item.totals?.channel_based?.total_discounted
              : item.totals?.channel_based?.total;
            if (item && quantity) {
              return `${quantity} x ${name} for ${formatPrice(price)}`;
            }
          }
          return '';
        })
        .filter((item) => item);
    },

    isInBasket(): boolean {
      return this.item.hasOwnProperty('cart_item_id');
    },

    isPrivateLounge(): boolean {
      return this.productOption === 'lounge_private';
    },

    productOption(): string {
      return this.product.productOptions[0]?.meta?.product_option_code;
    },
    enableAetherImprovements() {
      return this.$launchDarkly.variation(
        'PREM-636-Aether-Phase-1-Improvements'
      );
    },
  },

  watch: {
    item: {
      handler(value) {
        if (Object.keys(value).length) {
          this.product = { ...value };
        }
      },
      immediate: true,
    },
  },
});
