
import { defineComponent, PropType } from 'vue';
import {
  formatWithLocale,
  DATE_TIME_FORMATS,
  parseISO,
  guessTimezone,
} from '@white-label-helper/date-utilities';
import { convertTimeStringToObject } from '@white-label-helper/time-helpers';
import { getAppVariable } from '@white-label-helper/get-app-variable';

// Types
import type { FormData } from '@white-label-types/component';
// Components
import SearchParkingEditForm from '../search-parking-edit-form/search-parking-edit-form.vue';

export default defineComponent({
  components: {
    IconButton: () => import('@white-label-icon/icon-button'),
    IconPencil: () => import('@white-label-icon/icon-pencil'),
    SearchParkingEditForm,
  },

  props: {
    formData: {
      type: Object as PropType<FormData>,
      required: true,
    },

    updatingSearch: {
      type: Boolean,
      required: false,
    },

    editMode: {
      type: Boolean,
      required: true,
    },
    customErrorMessages: {
      type: Object,
      required: false,
      default: () => {},
    },
    apiRequestRunning: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showDesktopBackdrop: false,
    };
  },

  computed: {
    partnerTimeZone(): string {
      return getAppVariable('poi.timezone') || guessTimezone();
    },

    localEditMode: {
      get(): boolean {
        return this.editMode;
      },
      set(val: boolean) {
        this.$emit('update:editMode', val);
      },
    },

    localUpdatingSearch: {
      get(): boolean {
        return this.updatingSearch;
      },
      set(val: boolean) {
        this.$emit('update:updatingSearch', val);
      },
    },

    formattedEntryDate(): string {
      return this.formData.entryDate
        ? formatWithLocale(
            parseISO(this.formData.entryDate),
            DATE_TIME_FORMATS.month_day,
            this.$i18n.locale
          )
        : '';
    },

    formattedExitDate(): string {
      return this.formData.exitDate
        ? formatWithLocale(
            parseISO(this.formData.exitDate),
            DATE_TIME_FORMATS.month_day,
            this.$i18n.locale
          )
        : '';
    },

    formattedEntryTime(): string {
      return this.formData.entryTime
        ? this.formData.entryTime.name.replace(/\s/g, '')
        : '';
    },

    formattedExitTime(): string {
      return this.formData.exitTime
        ? this.formData.exitTime.name.replace(/\s/g, '')
        : '';
    },

    showEditForm(): boolean {
      return this.isDesktopResolution || this.localEditMode;
    },

    isScrollLocked(): boolean {
      return (
        (this.isMobileResolution || this.isTabletResolution) &&
        this.showEditForm
      );
    },
  },
  mounted() {
    this.$emit('mounted');
  },
  created() {
    this.$emit('created');
  },

  methods: {
    setSearchCriteria(data: { [key: string]: any }) {
      this.formData.entryDate = data.date || '';
      this.formData.entryTime = data.time
        ? convertTimeStringToObject(data.time)
        : null;
      this.formData.exitDate = data.exitDate || '';
      this.formData.exitTime = data.exitTime
        ? convertTimeStringToObject(data.exitTime)
        : null;
      this.formData.airport = data.airport || '';
      this.formData.terminal = data.terminal || '';
      this.formData.airline = data.airline || '';
    },
    onClickOutside(): void {
      this.onCancelChanges();
    },

    onCancelChanges(): void {
      this.localEditMode = false;
    },

    updateSearch(payload: FormData) {
      this.$emit('change-date-time', payload);
    },

    updateDesktopBackdrop(payload: boolean): void {
      this.showDesktopBackdrop = payload;
    },
  },
});
