
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ModalCenter',

  components: {
    IconClose: () => import('@white-label-icon/icon-close'),
  },

  props: {
    propClass: {
      type: String,
      default: '',
    },

    showCloseIcon: {
      type: Boolean,
    },

    typeModalError: {
      type: Boolean,
    },

    closeOnClickOutside: {
      type: Boolean,
    },

    closeButtonId: {
      type: String,
      default: '',
    },

    isScrollLocked: {
      type: Boolean,
      default: true,
    },
  },

  mounted() {
    window.addEventListener('keydown', this.keydownListener);
    // We need to check original mounted hook because we use a lot of slots
    this.$emit('is-mounted');
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.keydownListener);
  },

  methods: {
    onOutsideClick() {
      if (this.closeOnClickOutside) {
        this.closeModal();
      }
    },

    closeModal() {
      this.$emit('close-modal');
    },

    keydownListener(e) {
      if (e.key === 'Enter') {
        this.$emit('on-keydown-enter');
      }
    },
  },
});
