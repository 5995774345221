/* eslint-disable @typescript-eslint/no-explicit-any */
import { RootState } from '@white-label-types/stores';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';

// Types
import type { Booking } from '@white-label-types/account-booking';
import type { ParsedPagination } from '@white-label-types/api';

// Helpers
import { getBookingsForUser } from '@white-label-helper/api-parkings-bookings';
import { parseOrdersResponse } from '@white-label-helper/data-parsers-bookings';
import { parseJSONApiPagination } from '@white-label-helper/json-api';

type BookingContext = ActionContext<State, RootState>;

export type State = {
  bookings: Booking[]
  pagination: ParsedPagination
}

const state = (): State => ({
  bookings: [],
  pagination: {
    currentPage: 0,
    hasNextPage: false,
    hasPreviousPage: false,
    perPage: 0,
    from: 0,
    to: 0,
    totalCount: 0,
    links: {
      first: null,
      last: null,
      next: null,
      prev: null,
    }
  },
});

type Getters = {
  getBookings (state: State): State['bookings']
  currentPage (state: State): State['pagination']['currentPage']
  hasNextPage (state: State): State['pagination']['hasNextPage']
  hasPreviousPage (state: State): State['pagination']['hasPreviousPage']
  nextPageCount (state: State): number
}

const getters: Getters = {
  /**
   * Get the user's bookings.
   * @param state - the state object for bookings
   */
  getBookings: (state: State) => state.bookings,

  /**
   * Get the current page number.
   * @param state - the state object for bookings
   */
  currentPage: (state: State) => state.pagination.currentPage,

  /**
   * Get whether there is a next page of results.
   * @param state - the state object for bookings
   */
  hasNextPage: (state: State) => state.pagination.hasNextPage,

  /**
   * Get whether there is a previous page of results.
   * @param state - the state object for bookings
   */
  hasPreviousPage: (state: State) => state.pagination.hasNextPage,

  /**
   * Get the number of bookings to be loaded on the next page.
   * @param state - the state object for bookings
   */
  nextPageCount: (state: State) => {
    if (!state.pagination.hasNextPage) {
      return 0;
    } else if ((state.pagination.totalCount - state.pagination.to) < state.pagination.perPage) {
      return state.pagination.totalCount - state.pagination.to;
    } else {
      return state.pagination.perPage;
    }
  },
};

const mutations = {
  /**
   * Sets the user's bookings
   * @param state - the state object for bookings
   * @param payload.bookings - the bookings for this user
   */
  commitBookings: (state: State, payload: { bookings: Booking[], append: boolean }) => {
    if (!payload.append) {
      state.bookings = [...payload.bookings];
    } else {
      state.bookings = [...state.bookings, ...payload.bookings];
    }
  },
  commitPaginationMeta: (state: State, payload: { pagination: ParsedPagination }) => {
    state.pagination = { ...payload.pagination };
  }
};

const actions = {
  /**
   * Get the user's bookings from the API
   */
  getUserBookings: async ({state, commit}: BookingContext, payload: { bearerToken: string, queryParams?: Record<string, any> }) => {
    const currentPage = state.pagination.currentPage;
    return getBookingsForUser(payload.bearerToken, payload.queryParams)
      .then(({ data, links, meta }) => {
        const bookings = parseOrdersResponse(data)
        const pagination = parseJSONApiPagination({ meta, links });
        const appendBookings = pagination.currentPage > currentPage;
        commit('commitBookings', { bookings, append: appendBookings });
        commit('commitPaginationMeta', { pagination });
      })
      .catch((error: any) => {
        throw new Error(error);
      })
  },

  /**
   * Get the next page of user's bookings from the API.
   */
  loadNextPage: async ({state, dispatch}: BookingContext, payload: { bearerToken: string, queryParams?: Record<string, any> }) => {
    const { bearerToken, queryParams } = payload;
    return await dispatch('getUserBookings', { bearerToken, queryParams: { ...queryParams, page: state.pagination.currentPage + 1 }});
  }
};

const { read, commit, dispatch } = getStoreAccessors<State, RootState>('bookings')

// Getters
export const readBookings = read(getters.getBookings);
export const readBookingsHasNextPage = read(getters.hasNextPage);
export const readBookingsHasPreviousPage = read(getters.hasPreviousPage);
export const readBookingsNextPageCount = read(getters.nextPageCount);

// Mutations
export const commitBookings = commit(mutations.commitBookings);

// Actions
export const fetchUserBookings = dispatch(actions.getUserBookings);
export const fetchUserBookingsNextPage = dispatch(actions.loadNextPage);

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
