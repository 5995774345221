
// Packages
import { PropType, defineComponent } from 'vue';

// Types
import type { CookieSerializeOptions } from 'cookie';
import type { Partners } from '@white-label-types/partners';
import { Booking } from '@white-label-types/account-booking';

// Constants
import {
  HTTP_STATUS_CODES,
  BOOKING_STATUSES,
  NAMED_ROUTES,
  CookiesNames,
} from '@white-label-configuration/constants';

// Helpers
import {
  guessTimezone,
  DATE_TIME_FORMATS,
  parseISO,
  format,
  isValid,
} from '@white-label-helper/date-utilities';
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { searchBooking } from '@white-label-helper/api-manage-booking';

// Mixins
import auth from '@white-label-helper/mixin-auth';

// Components
import DotsLoader from '../dots-loader/dots-loader.vue';
import BookingStatus from '../booking-status/booking-status.vue';
import TextButton from '../text-button/text-button.vue';

export default defineComponent({
  name: 'BookingItem',

  components: {
    BookingStatus,
    DotsLoader,
    TextButton,
  },

  mixins: [auth],

  props: {
    booking: {
      type: Object as PropType<Booking>,
      required: true,
    },
  },

  data() {
    return {
      loadingManageBooking: false,
    };
  },

  computed: {
    bookingStatus(): string {
      return this.booking.status.toUpperCase();
    },

    displayBookingStatus(): boolean {
      return this.bookingStatus !== BOOKING_STATUSES.CONFIRMED;
    },

    formattedDatesMobile(): string {
      return this.getFormattedDates(DATE_TIME_FORMATS.month_day_year);
    },

    formattedDatesDesktop(): string {
      const dateFormat =
        this.bookingStatus === BOOKING_STATUSES.PAST_BOOKING ||
        this.bookingStatus === BOOKING_STATUSES.CANCELLED
          ? DATE_TIME_FORMATS.month_day_year
          : DATE_TIME_FORMATS.full_month_day_year;

      return this.getFormattedDates(dateFormat);
    },

    displaySmallStatus(): boolean {
      return (
        this.displayBookingStatus &&
        this.bookingStatus !== BOOKING_STATUSES.IN_PROGRESS
      );
    },

    partnerTimeZone(): Partners['poi']['timezone'] {
      return getAppVariable('poi.timezone') || guessTimezone();
    },
  },

  methods: {
    /**
     * Checks if the booking is a parking booking and returns the formatted start & end date,
     * otherwise returns just the formatted start date.
     * @param {string} dateFormat - Date format
     * @returns {string} - Formatted dates
     */
    getFormattedDates(dateFormat: string): string {
      const entryDate = parseISO(
        `${this.booking.startDatetime.date} ${this.booking.startDatetime.time}`
      );

      const entryDateFormatted = isValid(entryDate)
        ? format(entryDate, dateFormat)
        : '';

      let exitDate, exitDateFormatted = '';
      if (this.booking.product_code === 'parking') {
        exitDate = parseISO(
          `${this.booking.endDatetime.date} ${this.booking.endDatetime.time}`
        );
        exitDateFormatted = isValid(exitDate)
          ? format(exitDate, dateFormat)
          : '';
      }

      return exitDateFormatted.length > 1
        ? `${entryDateFormatted} - ${exitDateFormatted}`
        : entryDateFormatted;
    },

    showRequestError() {
      this.$openModal('GlobalModalError', {
        header: this.$t('shared.modals.errors.header'),
        body: this.$t('shared.modals.errors.requestFailed.body'),
        btnText: this.$t('shared.buttons.close'),
        btnType: 'custom',
      }).catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
    },

    async goToBooking() {
      try {
        this.loadingManageBooking = true;
        const { token, expiration } = await searchBooking({
          email: this.auth0User!.email as string,
          reference_id: this.booking.id,
        });
        const options = {
          maxAge: expiration - new Date().getTime() / 1e3,
          path: '/',
          sameSite: true,
        } as CookieSerializeOptions;

        if (process.env.NODE_ENV === 'production') {
          options.domain = process.env.NUXT_ENV_DOMAIN;
        }

        this.$cookies.set(
          CookiesNames.manage,
          { token, timestamp: new Date() },
          options
        );
        await this.$router.push(NAMED_ROUTES.manage_booking.default);
      } catch (error: any) {
        const errorResponse = JSON.parse(error.message);

        if (errorResponse?.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
          this.$emit('update:isTouched', true);
          this.$emit('update:isInvalid', true);
        } else {
          this.showRequestError();
        }
      } finally {
        this.loadingManageBooking = false;
      }
    },
  },
});
