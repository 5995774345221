// eslint-disable-next-line @typescript-eslint/no-var-requires
import plugin from 'tailwindcss/plugin';

/** @type {import('tailwindcss').Config} */
export default {
  future: {},
  purge: {
    content: ['node_modules/library-wl-www/components/**/*.vue'],
  },
  theme: {
    container: {
      padding: {
        default: '1.5rem',
        md: '2.5rem',
        lg: '5rem',
      },
    },
    extend: {
      screens: {
        sm: '414px',
      },
      height: {
        14: '3.5rem',
        25: '6.25rem',
      },
      width: {
        14: '3.5rem',
        25: '6.25rem',
        60: '15rem',
        80: '20rem',
        88: '22rem',
      },
      inset: {
        100: '100%',
      },
      colors: {
        transparent: 'transparent',
        current: 'currentColor',
        brand: {
          8: '#4e7dd214',
          16: '#4e7dd229',
          24: '#4e7dd23D',
          50: '#4e7dd280',
          100: '#4e7dd2',
        },
        'mag-elements': {
          light: '#F5F5FC',
          dividers: '#E9E9F0',
          grey: '#BEC1D2',
          'icons-on-dark': '#64687E',
          'icons-on-light': '#A2A5BB',
          dark: '#282C4A',
          'gradient-start': '#F8F9FD',
          'gradient-end': '#F4F7FF',
          'input-hover': '#282C4A',
          'input-focus': '#5EC2EE',
          'input-error': '#D41818',
          'ticket-background': '#FAFBFD',
          'discount-amount': '#5A822B',
        },
        alert: {
          8: '#D4181814',
          16: '#FC5B5B28',
          100: '#D41818',
        },
        success: {
          8: '#90C65214',
          16: '#8BC34A28',
          100: '#90C652',
        },
        highlight: {
          8: '#2E91CF14',
          100: '#2E91CF',
        },
        info: {
          8: '#FF7F4414',
          16: '#FF7F4428',
          100: '#FF7F44',
        },
        indigo: {
          500: '#6366F1',
        },
        yellow: {
          300: '#FCD34D',
        },
        'white-90': '#ffffffeb', // Shadow for modal scroller header rgba(255, 255, 255, 0.92),
        'green-accent': '#38831E',
        'orange-accent': '#E48D4C',
      },
      fontSize: {
        xxs: '0.625rem',
        '1_5xl': '1.375rem',
        '3_5xl': '2rem',
      },
      boxShadow: {
        'light-glow': '0 8px 16px 0 rgba(43, 46, 49, 0.04)',
      },
      backgroundOpacity: {
        30: '0.3',
      },
    },
  },
  variants: {},
  plugins: [
    plugin(({ addUtilities }) => {
      addUtilities({
        '.break-inside-avoid': {
          breakInside: 'avoid',
        },
      });
    }),
  ],
};
