import Vue from 'vue';
import { mapState } from 'vuex';
import { contentPreloaderModule } from '@white-label-module/content-preloader';

// Types
import type { Plugin } from '@nuxt/types';

Vue.mixin({
  computed: {
    ...mapState('contentPreloader', {
      $contentPreloaderEnabled: 'enabled',
    }),
  },
});

const contentPreloaderPlugin: Plugin = (context, inject) => {
  contentPreloaderModule(context, inject);
}

export default contentPreloaderPlugin;
