
import IconArrow from '@white-label-icon/icon-arrow';

export default {
  name: 'SummaryProductHeader',

  components: {
    IconArrow,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    price: {
      type: String,
      default: '',
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
    isButton: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    parseString (text) {
      const words = text.split(' ');
      if (words.length === 0) { return [...words]; }

      const firstBlock = words.filter((_, index) => index !== words.length - 1).join(' ');

      return [firstBlock, words[words.length - 1]];
    },
  },
};
