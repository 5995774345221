// TODO Need to delete this whole file when dev will moved to hosting, which supports Nuxt middleware
import { getStoreAccessors } from 'typesafe-vuex';
import { RootState } from '@white-label-types/stores';

export type State = {
  isAppMounted: boolean;
}

const helperState = (): State => ({
  isAppMounted: false,
});

const getters = {
  isAppMounted(state: State): boolean {
    return state.isAppMounted;
  },
};

const mutations = {
  appInitialized(state: State, payload: boolean): void {
    state.isAppMounted = payload;
  },
};

const { commit, read } = getStoreAccessors<State, RootState>('helpers');

export const commitAppInitialized = commit(mutations.appInitialized);
export const readIsAppMounted = read(getters.isAppMounted);

export default {
  namespaced: true,
  state: helperState,
  getters,
  mutations,
};
