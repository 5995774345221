
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    homePageUrl: {
      type: String,
      default: ''
    },

    imageUrl: {
      type: String,
      default: ''
    },

    partnerName: {
      required: true,
      type: String
    }
  },

  computed: {
    homeUrlWithProtocol (): string | undefined {

      // If homePageUrl is not a string then return early
      if (typeof this.homePageUrl !== 'string') {
        return;
      }

      // else perform checks on homePageUrl
      if (this.homePageUrl.startsWith('https://') || this.homePageUrl.startsWith('http://')) {
        return this.homePageUrl
      }
      
      return `//${this.homePageUrl}`
    },

    // TODO: 
    // Remove the typescript ignore directive and replace this computed property with a standard prop. 
    // 
    // Leaf (dumb) components should not be fetching values and instead should be receiving them via props.
    // 
    altText (): string {
      // @ts-ignore
      return this.$t('layouts.logo.alt', { partnerName: this.$props.partnerName }) as string
    }
  }
})
