
import { defineComponent, PropType } from 'vue';
import type { SearchBoxLoungesComponent } from '@white-label-types/lounges-component';
import { readSearchErrorApi } from '@white-label-store/search-results';
import SearchBoxLounges from '../search-box-lounges/search-box-lounges.vue';
import SearchLoungesInfoPanel from '../search-lounges-info-panel/search-lounges-info-panel.vue';

export default defineComponent({
  name: 'SearchLoungeWidget',
  components: {
    IconButton: () => import('@white-label-icon/icon-button'),
    SearchBoxLounges,
    SearchInfoPanel: SearchLoungesInfoPanel,
  },
  props: {
    panelParams: {
      type: Object,
      default() {
        return {};
      },
    },
    customErrorMessages: {
      type: Object,
      required: false,
      default: () => {},
    },
    apiRequestRunning: {
      type: Boolean,
      default: false,
    },
    minEntryDate: {
      type: [String, Date] as PropType<string | Date>,
      default: () => new Date(),
    },
    enableExtrasProductInput: {
      type: Boolean,
      default: false,
    },
    showAgeRanges: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      editMode: false,
      searchCriteria: {},
      disabledParams: {},
    };
  },
  computed: {
    searchErrorApi(): ReturnType<typeof readSearchErrorApi> {
      return readSearchErrorApi(this.$store);
    },
  },
  watch: {
    searchErrorApi(value) {
      if (value && this.isMobileResolution) this.editMode = true;
    },
  },
  methods: {
    submitForm(data: any) {
      this.$nextTick(
        () =>
          this.$refs.form &&
          (this.$refs.form as SearchBoxLoungesComponent).submitForm(data)
      );
    },
    setSearchCriteria(data: any) {
      (this.$refs.form as SearchBoxLoungesComponent).setSearchCriteria(data);
    },
    pushGTM() {
      (this.$refs.form as SearchBoxLoungesComponent).pushGTM();
    },
    submit(data: any) {
      if (!data.error) {
        this.disabledParams = { ...data.form };
        this.searchCriteria = data.searchCriteria;
        this.editMode = false;
        this.$emit('submit', data);
      } else {
        this.editMode = true;
        if (!Object.keys(this.searchCriteria).length) {
          this.searchCriteria = data.searchCriteria;
        }
      }
    },
    focus() {
      this.editMode = true;
      (this.$refs.form as SearchBoxLoungesComponent).focus();
    },
  },
});
