
import { defineComponent, PropType } from 'vue';
import type VueI18n from 'vue-i18n';
import type { Time } from '@white-label-types/time-picker';
import {
  add,
  formatWithLocale,
  parseISO,
  DATE_TIME_FORMATS,
} from '@white-label-helper/date-utilities';

import ErrorMessage from '../error-message/error-message.vue';
import DatePicker from '../datepicker/datepicker.vue';
import TimePicker from '../timepicker/timepicker.vue';
import Backdrop from '../back-drop/back-drop.vue';

export default defineComponent({
  components: {
    DatePicker,
    TimePicker,
    Backdrop,
    ErrorMessage,
    IconClock: () => import('@white-label-icon/icon-clock'),
    IconCalendar: () => import('@white-label-icon/icon-calendar'),
  },
  props: {
    date: {
      type: String as PropType<string>,
      default: '',
    },

    minDate: {
      type: [String, Date] as PropType<string | Date>,
      default: () => new Date(),
    },

    maxDate: {
      type: [String, Date] as PropType<string | Date>,
      default: () => add(new Date(), { years: 1 }),
    },

    time: {
      type: Object as PropType<Time | null>,
      default: null,
    },

    timeZone: {
      type: String,
      default: '',
    },

    minTime: {
      type: [String, Date] as PropType<string | Date>,
      default: () => new Date(),
    },

    dateError: {
      type: Boolean,
      default: false,
    },

    timeError: {
      type: Boolean,
      default: false,
    },

    commonError: {
      type: Boolean,
      default: false,
    },

    errorMessage: {
      type: [String, Array] as PropType<
        string | string[] | VueI18n.TranslateResult
      >,
      default: '',
    },

    timePickerStep: {
      type: Number,
      default: 15, // minutes
    },

    dateFormat: {
      type: String,
      default: DATE_TIME_FORMATS.month_day_year,
    },

    label: {
      type: String,
      default: '',
    },

    labelTimepickerDesktop: {
      type: String,
      default: '',
    },

    labelTimepickerMobile: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      dateDropdownOpened: false,
      timeDropdownOpened: false,
    };
  },

  computed: {
    displayDateError(): boolean {
      return this.dateError && !this.dateDropdownOpened;
    },

    displayTimeError(): boolean {
      return this.timeError && !this.timeDropdownOpened;
    },

    displayErrorMessage(): boolean {
      return this.displayDateError || this.displayTimeError || this.commonError;
    },

    hasBackdrop: {
      get(): boolean {
        return (
          (this.isMobileResolution || this.isTabletResolution) &&
          (this.dateDropdownOpened || this.timeDropdownOpened)
        );
      },
      set(): void {
        this.closeDatePicker();
        this.closeTimePicker();
      },
    },

    formattedDate(): string {
      return this.date
        ? formatWithLocale(
            parseISO(this.date),
            this.dateFormat,
            this.$i18n.locale
          )
        : '';
    },
  },

  methods: {
    onDateChange(date: string): void {
      this.closeDatePicker();
      this.$emit('date-change', date);
    },

    onTimeChange(time: Time): void {
      this.timeDropdownOpened = false;
      this.closeDatePicker();
      this.$emit('time-change', time);
    },

    openDatePicker() {
      this.dateDropdownOpened = true;
      this.$emit('focus');
    },

    closeDatePicker() {
      this.dateDropdownOpened = false;
      this.$emit('blur');
    },

    openTimePicker() {
      this.timeDropdownOpened = true;
      this.$emit('focus');
    },

    closeTimePicker() {
      this.timeDropdownOpened = false;
      this.$emit('blur');
    },

    onDatePickerClickOutside() {
      if (this.timeDropdownOpened && this.dateDropdownOpened) {
        this.dateDropdownOpened = false;
        return;
      }
      if (this.dateDropdownOpened) {
        this.closeDatePicker();
      }
    },

    onTimePickerClickOutside() {
      if (this.timeDropdownOpened && this.dateDropdownOpened) {
        this.timeDropdownOpened = false;
        return;
      }
      if (this.timeDropdownOpened) {
        this.closeTimePicker();
      }
    },
  },
});
