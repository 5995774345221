var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ModalCenter',{staticClass:"modal-email-receipt",attrs:{"close-button-id":_vm.closeButtonId,"is-scroll-locked":true,"close-on-click-outside":!_vm.loading,"show-close-icon":!_vm.loading && _vm.userIsLoggedIn},on:{"close-modal":function($event){return _vm.closeModal()}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"modal-email-receipt__title"},[_vm._v("\n      "+_vm._s(_vm.$t('manageBooking.emailReceiptModal.header'))+"\n    ")])]},proxy:true},{key:"body",fn:function(){return [_c('p',{staticClass:"modal-email-receipt__body-text"},[_vm._v("\n      "+_vm._s(_vm.$t(
          `manageBooking.emailReceiptModal.body.${
            _vm.userIsLoggedIn ? 'auth' : 'nonAuth'
          }`
        ))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"modal-email-receipt__content-wrapper"},[(_vm.userIsLoggedIn)?_c('TextDropdown',{staticClass:"modal-email-receipt__dropdown",attrs:{"input-type":"email","input-mode":"email","label":_vm.$t('manageBooking.emailReceiptModal.label'),"placeholder":_vm.$t('manageBooking.emailReceiptModal.placeholder'),"error":_vm.$v.formData.email,"items":_vm.getEmailDropdownItems},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}):_vm._e(),_vm._v(" "),(!_vm.userIsLoggedIn)?_c('ButtonControl',{staticClass:"modal-email-receipt__cancel-btn",attrs:{"id":_vm.closeButtonId,"label":_vm.$t('ui.buttons.cancel'),"disabled":_vm.loading,"type-of-element":"button","button-type":"secondary"},on:{"btnClick":function($event){return _vm.closeModal()}}},[_c('span',[_vm._v(_vm._s(_vm.$t('UI.buttons.cancel')))])]):_vm._e(),_vm._v(" "),_c('ButtonControl',{class:{
          'modal-email-receipt__send-btn--authorised': _vm.userIsLoggedIn,
          'modal-email-receipt__send-btn--unauthorised': !_vm.userIsLoggedIn,
        },attrs:{"label":_vm.$t('manageBooking.emailReceiptModal.button'),"loading":_vm.loading,"disabled":_vm.loading,"type-of-element":"button","button-type":"primary"},on:{"btnClick":_vm.sendEmailReceipt}},[_c('span',[_vm._v(_vm._s(_vm.$t('manageBooking.emailReceiptModal.button')))])])],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }