
// Types
import type { UpgradableProduct } from '@white-label-types/parking-booking';
import type VueI18n from 'vue-i18n';

import { defineComponent, PropType } from 'vue';
import { trackCheckoutStep } from '@white-label-helper/helper-tracking';
import {
  smoothScrolling,
  findScrollableParent,
} from '@white-label-helper/scroll';
import { formatPrice } from '@white-label-helper/helper-payment';
import imgError from '@white-label-helper/mixin-img-error';

import Button from '../button/button.vue';
import IconWrapper from '../icon-wrapper/icon-wrapper.vue';
import ModalScrollableBody from '../modal-scrollable-body/modal-scrollable-body.vue';
import ToggleContent from '../toggle-content/toggle-content.vue';

export default defineComponent({
  name: 'SearchParkingUpgradeModal',

  components: {
    ControlButton: Button,
    IconBulb: () => import('@white-label-icon/icon-bulb'),
    IconCheck: () => import('@white-label-icon/icon-check'),
    IconWrapper,
    ModalScrollableBody,
    ToggleContent,
  },

  mixins: [imgError],

  props: {
    product: {
      type: Object as PropType<UpgradableProduct>,
      required: true,
    },

    processAddingToCart: {
      type: Boolean,
    },
  },

  data() {
    return {
      scrollAtTheTop: true,
      upgradeProduct: false,
      anchorTop: 0,
    };
  },

  computed: {
    displayProductFeatures(): boolean {
      return !!this.validFeatures && !!this.validFeatures.length;
    },

    validFeatures(): string[] {
      return this.product.features.filter((i) => !!i);
    },

    title(): VueI18n.TranslateResult {
      const { delta, name } = this.product;

      if (delta < 0) {
        return this.$t('searchProductUpgrade.upgradeTitleSave', {
          product: name,
          price: formatPrice(delta * -1),
        });
      }
      if (+delta === 0) {
        return this.$t('searchProductUpgrade.upgradeTitleFree', {
          product: name,
        });
      }
      return this.$t('searchProductUpgrade.upgradeTitle', {
        product: name,
        price: formatPrice(delta),
      });
    },

    additionalInfoType(): VueI18n.TranslateResult {
      return this.$t(
        `searchProductUpgrade.${this.displayMap ? 'infoMap' : 'info'}`
      );
    },

    hasAdditonalInfo(): boolean {
      return !!this.product.description.length || !!this.product.map_image;
    },

    displayMap(): boolean {
      return !!this.product.map_image?.src;
    },
  },

  methods: {
    onMoreInfoToggle(expanded: boolean): void {
      if (
        this.isMobileResolution &&
        expanded &&
        this.$refs.mainInfo instanceof HTMLElement
      ) {
        const scrollableElement = findScrollableParent(this.$refs.mainInfo);
        const currentScrollPosition = scrollableElement.scrollTop;
        // nextTick for wait until additional information be rendered because refs used for calculation
        this.$nextTick(() => {
          const productNameHeight = (this.$refs.productName as HTMLElement)
            .clientHeight;
          const additionalInfoTypeHeight = (
            this.$refs.additionalInfoType as HTMLElement
          ).clientHeight;
          /*
            targetScrollPosition is the sum of:
              - current scroll position in the popup,
              - height of the product name (may span multiple lines),
              - height of the more-info subtitle (additionalInfoType)
          */
          const targetScrollPosition =
            currentScrollPosition +
            productNameHeight +
            additionalInfoTypeHeight;
          smoothScrolling(
            scrollableElement,
            targetScrollPosition,
            currentScrollPosition
          );
        });
      }
    },

    upgrade(): void {
      this.upgradeProduct = true;

      trackCheckoutStep(this.$gtm, 2, 'Select product');
      // TODO Need to add a cart revalidation check
      this.$emit('on-parking-select', this.product);
    },

    dontUpgrade(): void {
      trackCheckoutStep(this.$gtm, 2, 'Select product');
      this.$emit('on-parking-select');
    },
  },
});
