
// Packages
import { defineComponent } from 'vue';
import { datadogRum } from '@datadog/browser-rum';

// Helpers
import {
  goToApp,
  ECOMMERCE_URLS,
  DOMAINS,
} from '@white-label-helper/switch-app';

// Store
import {
  fetchUserBookings,
  fetchUserBookingsNextPage,
  readBookings,
} from '@white-label-store/bookings';

// Mixins
import auth from '@white-label-helper/mixin-auth';

// Components
import Button from '../button/button.vue';
import BookingsPreloader from '../bookings-preloader/bookings-preloader.vue';
import BookingsNoResults from '../bookings-no-results/bookings-no-results.vue';
import BookingsList from '../bookings-list/bookings-list.vue';

export default defineComponent({
  name: 'Bookings',

  components: {
    ControlButton: Button,
    BookingsPreloader: BookingsPreloader,
    BookingsNoResults: BookingsNoResults,
    BookingsList: BookingsList,
  },

  mixins: [auth],

  data() {
    return {
      perPage: 30, // TODO CUS-568: High `perPage` count to last until filtering added to endpoint.
      loadingNextPage: false,
    };
  },

  computed: {
    displayNoReservationsMessage(): boolean {
      return !this.bookings.length;
    },
    bookings(): ReturnType<typeof readBookings> {
      return readBookings(this.$store);
    },
  },

  async mounted() {
    try {
      await fetchUserBookings(
        this.$store,
        {
          bearerToken: this.getToken,
          queryParams: {
            per_page: this.perPage,
          },
        },
      );
    } catch (e: any) {
      datadogRum.addError(new Error(e));
      this.$openModal('GlobalModalError', {
        header: this.$t('shared.modals.errors.technicalIssue.header'),
        body: this.$t('shared.modals.errors.technicalIssue.body'),
        btnText: this.$t('shared.buttons.tryAgain'),
        btnType: 'custom',
      });
    } finally {
      this.$global_disableContentPreloader();
    }
  },

  beforeDestroy() {
    this.$global_disableContentPreloader();
  },

  methods: {
    goToBooking(): void {
      return goToApp(`${DOMAINS.ECOMMERCE}`, ECOMMERCE_URLS.HOME);
    },
    async loadNextPage(): Promise<void> {
      try {
        this.loadingNextPage = true;
        await fetchUserBookingsNextPage(this.$store, {
          bearerToken: this.getToken,
          queryParams: { per_page: this.perPage },
        });
      } catch (e: any) {
        datadogRum.addError(new Error(e));
        this.$openModal('GlobalModalError', {
          header: this.$t('shared.modals.errors.technicalIssue.header'),
          body: this.$t('shared.modals.errors.technicalIssue.body'),
          btnText: this.$t('shared.buttons.tryAgain'),
          btnType: 'custom',
        });
      } finally {
        this.loadingNextPage = false;
      }
    },
  },
});
