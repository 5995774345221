// Package
import * as LDClient from 'launchdarkly-js-client-sdk';

// Types
import type { Plugin } from '@nuxt/types';
import { datadogRum } from '@datadog/browser-rum';

const context: LDClient.LDContext = {
  kind: 'multi',
  user: {
    anonymous: true,
  },
  organisation: {
    key: 'organisation',
    channelToken: process.env.NUXT_ENV_AFFILIATE_APPLICATION_TOKEN,
  },
};

let client: LDClient.LDClient;

const launchDarkly: Plugin = async (_, inject): Promise<void> => {
  if (process.env.NUXT_ENV_LAUNCH_DARKLY_CLIENT_ID === undefined) {
    console.warn('Launch darkly client ID is missing, feature flags will not work');
  } else {
    client = LDClient.initialize(process.env.NUXT_ENV_LAUNCH_DARKLY_CLIENT_ID, context, {
      streaming: true, inspectors: [{
        type: 'flag-used', name: 'dd-inspector', method: (key: string, detail: LDClient.LDEvaluationDetail) => {
          datadogRum.addFeatureFlagEvaluation(key, detail.value);
        }
      }]
    });
  }

  if (client !== null && client !== undefined) {
    await client.waitUntilReady();

    inject('launchDarkly', client);
  } else {
    inject('launchDarkly', {
      variation() { },
    });
  }
};

export default launchDarkly;
