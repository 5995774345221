var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ModalCenter',{staticClass:"scrollable-modal",attrs:{"prop-class":_vm.propClass,"close-button-id":_vm.closeButtonId,"is-scroll-locked":false,"close-on-click-outside":_vm.closeOnClickOutside,"show-close-icon":_vm.showCloseIcon},on:{"close-modal":function($event){return _vm.$emit('close')},"is-mounted":_vm.toggleScrollBarVisibility},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"scrollable-modal__main",class:{
        'scrollable-modal__main--scroll-at-the-top': _vm.scrollAtTheTop,
        'scrollable-modal__main--desktop': _vm.isLargeDesktopResolution,
        'scrollable-modal__main--sticky-transparent':
          _vm.isStickyHeaderTransparent,
        'scrollable-modal__main--hide-scroll-bar': _vm.hideScrollBarUnderHeader,
      }},[_c('div',{staticClass:"scrollable-modal__sticky-header"},[_vm._t("header"),_vm._v(" "),_c('button',{staticClass:"scrollable-modal__close-btn",attrs:{"id":_vm.closeButtonId},on:{"click":function($event){return _vm.$emit('close')}}},[_c('IconClose',{staticClass:"text-mag-elements-icons-on-dark"})],1)],2),_vm._v(" "),_c('div',{directives:[{name:"bar",rawName:"v-bar"}],ref:"scrollAreaBody",staticClass:"scrollable-modal__body"},[_c('div',{directives:[{name:"scroll-listener",rawName:"v-scroll-listener",value:(_vm.scrollListener),expression:"scrollListener"},{name:"scroll-lock",rawName:"v-scroll-lock",value:(true),expression:"true"}],ref:"scrollArea",staticClass:"scrollable-modal__scroll-area"},[_vm._t("body",null,{"scrollAtTheTop":_vm.scrollAtTheTop})],2)]),_vm._v(" "),(_vm.$slots.footerButtons)?_c('div',{staticClass:"scrollable-modal__footer-btns"},[_vm._t("footerButtons")],2):_vm._e()])]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }