var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clickOutSide),expression:"clickOutSide"}],staticClass:"relative"},[_c('div',{staticClass:"dropdown__wrapper",on:{"click":_vm.onClickDropdownTarget}},[_vm._t("dropdown-target",function(){return [_c('div',{class:[
          'dropdown',
          {
            'dropdown--active': _vm.localDropdownOpened,
            'dropdown--targetable': !_vm.readonly,
            'dropdown--error': _vm.error,
          },
        ]},[(_vm.$scopedSlots['prefix'])?_c('div',{staticClass:"dropdown-target__prefix"},[_vm._t("prefix")],2):_vm._e(),_vm._v(" "),_c('div',{class:['dropdown-target__content']},[(_vm.localValue)?_c('span',{staticClass:"dropdown__value"},[_vm._v("\n            "+_vm._s(_vm.localValue)+"\n          ")]):_c('span',{staticClass:"dropdown__placeholder"},[_vm._v("\n            "+_vm._s(_vm.label)+"\n          ")])]),_vm._v(" "),(!_vm.readonly)?_c('IconArrow',{staticClass:"field-label-icon field-label-icon--rotatable",class:{ 'rotate-180': _vm.localDropdownOpened }}):_vm._e()],1)]}),_vm._v(" "),(_vm.error)?_c('ErrorMessage',{staticClass:"dropdown__error-message",attrs:{"message":_vm.errorMessage,"html":_vm.errorHtml}}):_vm._e()],2),_vm._v(" "),_vm._t("default",function(){return [_c('DropdownList',{attrs:{"items":_vm.items,"dropdown-opened":_vm.dropdownOpened,"mobile-pop-up-title":_vm.label},on:{"close":function($event){_vm.localDropdownOpened = false}},scopedSlots:_vm._u([{key:"dropdown-item",fn:function({ item, index }){return [_vm._t("dropdown-item",null,null,{ item, index })]}}],null,true),model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}})]},{"dropdownOpened":_vm.dropdownOpened,"localDropdownOpened":_vm.localDropdownOpened})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }