
import { defineComponent } from 'vue';
import type { Route } from 'vue-router';

// Helpers
import { getAppVariable } from '@white-label-helper/get-app-variable';

// Constants
import { COLOUR_SCHEMES } from '@white-label-configuration/constants';

type ColourScheme = (typeof COLOUR_SCHEMES)[keyof typeof COLOUR_SCHEMES];

type State = 'undone' | 'done' | 'in-progress' | 'blocked';

type Step = {
  routeName: string;
  state: State;
};

export default defineComponent({
  name: 'ProgressIndicator',

  components: {
    IconCheck: () => import('@white-label-icon/icon-check'),
  },

  props: {
    skipExtras: {
      type: Boolean,
    },

    homeRoute: {
      type: String,
      default: '/',
    },

    searchRoute: {
      type: String,
      default: '/',
    },

    extrasRoute: {
      type: String,
      default: '/',
    },

    checkoutRoute: {
      type: String,
      default: 'checkout',
    },

    queryParams: {
      type: String,
      default: '',
    },

    queryObject: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      isBlockedTipShown: false,
      steps: [
        {
          // This is the URL not the name
          routeName: this.homeRoute,
          state: 'undone',
        },
        {
          // This is the URL not the name
          routeName: this.searchRoute,
          state: 'undone',
        },
        {
          // This is the URL not the name
          routeName: this.extrasRoute,
          state: this.skipExtras ? 'blocked' : 'undone',
        },
        {
          routeName: this.checkoutRoute,
          state: 'undone',
        },
      ] as Step[],
    };
  },

  computed: {
    progressRouteNames(): string[] {
      return [
        this.homeRoute,
        this.searchRoute,
        this.extrasRoute,
        this.checkoutRoute,
      ];
    },
    colourScheme(): ColourScheme {
      const scheme = getAppVariable('colours.header.font');
      if (scheme === COLOUR_SCHEMES.LIGHT || scheme === COLOUR_SCHEMES.DARK) {
        return scheme;
      }
      return COLOUR_SCHEMES.DARK;
    },
  },

  watch: {
    '$i18n.locale': function() {
      this.changeStepsState();
    },
    $route: function(to: Route, from: Route): void {
      this.changeStepsState();

      this.clearSearchOnRouteChange(to.name, this.homeRoute, from.name, [
        this.homeRoute,
      ]);

      this.clearSearchOnRouteChange(to.name, this.searchRoute, from.name, [
        this.homeRoute,
        this.searchRoute,
      ]);
    },

    skipExtras(to: Route): void {
      const step = this.steps.find(
        (step: Step) => step.routeName === this.extrasRoute
      );

      if (step) {
        if (to) {
          step.state = 'blocked';
        } else {
          step.state = 'undone';
        }
      }
    },
  },

  created() {
    this.changeStepsState();
  },

  methods: {
    changeStepsState() {
      // calculates current route index and changes other steps state according
      // to current route's position in the array
      const getIndex = (target: string): number =>
        this.steps.findIndex((i) => i.routeName === target);
      const routeIndex: number = getIndex(this.$route.name || '');

      for (const step of this.steps) {
        if (step.state === 'blocked') {
          continue;
        }
        const stepIndex = getIndex(step.routeName);
        if (stepIndex === routeIndex) {
          this.steps[stepIndex].state = 'in-progress';
        } else if (stepIndex < routeIndex) {
          this.steps[stepIndex].state = 'done';
        } else if (stepIndex > routeIndex) {
          this.steps[stepIndex].state = 'undone';
        }
      }
    },

    changeTipState(step: Step['state'], tip?: boolean) {
      const defaultTip =
        typeof tip === 'boolean' ? tip : !this.isBlockedTipShown;
      this.isBlockedTipShown = step === 'blocked' && defaultTip;
    },

    navigateTo(step: Step) {
      if (
        step.state !== 'blocked' &&
        !this.navigatingForward(this.$route.name, step.routeName)
      ) {
        if (step.routeName.includes('http')) {
          const route = this.queryParams
            ? `${step.routeName}?${this.queryParams}`
            : step.routeName;
          window.location.assign(route);
        } else {
          const route = {
            name: step.routeName,
            ...(this.queryObject && { query: this.queryObject }),
          };
          this.$router.push(route);
        }
      }
    },

    // Going to (specified)earlier page from latter pages,
    // which are filtered from all based on (specified)earlier+current pages,
    // will result in clearing search data
    clearSearchOnRouteChange(
      to: Route['name'],
      page: string,
      from: Route['name'],
      pages: string[]
    ) {
      const filterRoutes = this.progressRouteNames.filter(
        (routeName) => !pages.includes(routeName)
      );
      if (to === page && filterRoutes.includes(from || '')) {
        this.$store.commit('searchResults/clearSearchResults');
      }
    },

    showBlockedTip(step: Step) {
      return step.state === 'blocked' && this.isBlockedTipShown;
    },

    navigatingForward(from: Route['name'], to: Step['routeName']) {
      const getIndex = (target: Route['name']) =>
        this.steps.findIndex((i) => i.routeName === target);
      return getIndex(from) < getIndex(to);
    },
  },
});
