/**
 * Finds and removes any existing Feefo script tags before re-initialising.
 * This allows the use of multiple Feefo widgets in different components on a page.
 * Also adds a style script to the shadow-dom of each Feefo widget.
 */

export function initFeefoReviews(merchantId: string): void {
  // remove existing Feefo script if present
  const scriptTag = document.querySelector(`script[src='https://api.feefo.com/api/javascript/${merchantId}']`);
  scriptTag?.remove();

  // add script to end of doc
  const feefoScript = document.createElement('script');
  feefoScript.setAttribute('src', `https://api.feefo.com/api/javascript/${merchantId}`);
  feefoScript.setAttribute('type', 'text/javascript');
  feefoScript.setAttribute('async', 'true');
  document.body.appendChild(feefoScript);
}

export function updateFeefoStyles(brandColor: string): void {
  setTimeout(() => {
    document.querySelectorAll('.feefo-product-stars-widget').forEach((item) => {
      const style = document.createElement('style');
      style.innerHTML = `
      .summary-rating .reviews-count {
        font-weight: bold;
        font-family: 'DM Sans', sans-serif;
        font-size: 0.875rem;
        color: rgb(${brandColor});
      }`;
      if (item.shadowRoot && item.shadowRoot.querySelectorAll('style').length < 1) {
        item.shadowRoot.appendChild(style);
      }
    });
  }, 500);
}
