var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{class:['input-container', { 'input-container--error' : _vm.error && _vm.error.$error }]},[(_vm.label)?_c('span',{staticClass:"label-name",class:{ 'label-name--star': _vm.addRequiredStar },attrs:{"data-testid":_vm.label}},[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"input-container__wrapper",class:{ 'flex': _vm.slotExists }},[(_vm.prependIcon)?_c('span',{staticClass:"input-container__prepend-icon"},[_c(_vm.prependIcon,{tag:"component",class:_vm.prependIconClass})],1):_vm._e(),_vm._v(" "),_c('input',{class:[
        'input-control',
        `input-control--${_vm.type}`,
        `${_vm.customClasses}`,
        { 'error': _vm.isErrorExists },
        { 'input-control--append-icon': _vm.appendIcon },
        { 'input-control--prepend-icon': _vm.prependIcon }
      ],attrs:{"type":_vm.type,"placeholder":_vm.placeholder,"autocomplete":"password","readonly":_vm.readonly,"data-testid":_vm.testId},domProps:{"value":_vm.value},on:{"click":_vm.onClick,"input":_vm.onInput,"focus":_vm.onFocus,"blur":_vm.onBlur,"keypress":_vm.onKeypress,"paste":_vm.onPaste}}),_vm._v(" "),(_vm.appendIcon)?_c('span',{staticClass:"input-container__append-icon"},[_c(_vm.appendIcon,{tag:"component",class:_vm.appendIconClass})],1):_vm._e(),_vm._v(" "),_vm._t("button")],2),_vm._v(" "),(_vm.isErrorExists && _vm.displayErrorMessage)?_c('p',{staticClass:"error-msg"},[_vm._v("\n    "+_vm._s(_vm.errorMessage)+"\n  ")]):_vm._e(),_vm._v(" "),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }