/**
 * Set up pusher connection for use within the apps.
 * @see [Pusher Connection]{@link https://pusher.com/docs/channels/using_channels/connection/}
 */

import Pusher from 'pusher-js';
import type PusherType from 'pusher-js/types/src/core/pusher';
import { datadogRum } from '@datadog/browser-rum';

let channel: PusherType;

export default function getPusherChannel(): PusherType {
  if (!channel) {
    const pusherKey = process.env.NUXT_ENV_PUSHER_APP_KEY;

    if (typeof pusherKey !== 'string') {
      throw new TypeError('NUXT_ENV_PUSHER_APP_KEY has not been set');
    }

    channel = new Pusher(pusherKey, {
      cluster: process.env.NUXT_ENV_PUSHER_APP_CLUSTER,
    });

    // When a connection error occurs, log an error to the reporting platform.
    channel.connection.bind('error', (error: unknown) => {
      datadogRum.addError(error);
    });
  }

  return channel;
}
