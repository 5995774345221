
import { defineComponent, PropType } from 'vue';
import type { PurchaseProduct, InventoryItem, Policy, EntryMethod } from '@white-label-types/parking-booking';

interface ReceiptItem extends PurchaseProduct {
  inventory_item: InventoryItem;
  entry_methods?: EntryMethod[],
  inventory_option: {
    cancellation_policies: Policy[];
    amendment_policies: Policy[];
  }
}

import BTicketAdditionalInfo from '../b-ticket-additional-info/b-ticket-additional-info.vue';
import BTicket from '../b-ticket/b-ticket.vue';

export default defineComponent({

  components: {
    BTicketAdditionalInfo: BTicketAdditionalInfo,
    BTicket
  },

  props: {
    isParkingProduct: {
      type: Boolean,
      default: false,
    },

    item: {
      type: Object as PropType<ReceiptItem>,
      required: true,
    },

    orderReference: {
      type: String,
      default: '',
    },

    subItems: {
      type: Array,
      default() {
        return [];
      },
    },

    modifications: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    cancellationPolicies(): Policy[] {
      if (this.item.cancellation_protection) {
        return this.item.cancellation_protection.cancellation_policies;
      }
      return this.item.inventory_option.cancellation_policies;
    },
  },
});
