
import { defineComponent } from 'vue';

export default defineComponent({
  functional: true,
  props: {
    isContainerFullWidth: {
      type: Boolean,
      default: false,
    },
  },
  render(createElement, { data, props, children }) {

    const elementData = { ...data };

    elementData.staticClass = props.isContainerFullWidth ? '' : data.staticClass ? `container ${data.staticClass}` : 'container';

    return createElement('div', elementData, [...children]);
  },
});
