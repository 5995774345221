
// Packages
import { defineComponent, PropType } from 'vue';
import { PortalTarget } from 'portal-vue';
import debounce from 'lodash/debounce';

// Helpers
import { smoothScroll } from '@white-label-helper/scroll';
import { alphaNumSpace, email, notNumber, required, requiredIf, sameAs } from '@white-label-helper/vuelidate';
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { isBookingPortal } from '@white-label-helper/is-booking-portal';

// Constants
import { PAYMENT_PROVIDERS, TEXT_INPUT_DEBOUNCE_TIME } from '@white-label-configuration/constants';

// Mixins
import checkoutForm from '@white-label-helper/mixin-checkout-form';
import auth from '@white-label-helper/mixin-auth';

// Store
import {
  dispatchRemoveDiscount,
  dispatchValidateDiscountCode,
  readDiscountObject
} from '@white-label-store/discount-code';

import {
  dispatchUserMarketingSubscription,
  readMarketingPreferences,
  readUserMarketingSubscription
} from '@white-label-store/marketing-preferences';

import { commitClearFormData, commitStoreFormData, readPaymentFormData } from '@white-label-store/payment-form';

import { readCartItemsType } from '@white-label-store/cart-checkout';

import { fetchUserDetails, readPhoneNumbers, readUserDetails, readVehicleDetails } from '@white-label-store/user-info';

// Components
import IconMessage from '../icon-message/icon-message.vue';
import TextField from '../text-field/text-field.vue';
import Button from '../button/button.vue';
import PaymentPolicies from '../payment-policies/payment-policies.vue';
import DiscountCode from '../discount-code/discount-code.vue';
import PaymentGatewayBraintree from '../payment-gateway-braintree/payment-gateway-braintree.vue';
import PaymentGatewayStripe from '../payment-gateway-stripe/payment-gateway-stripe.vue';
import PaymentMarketingChannels from '../payment-marketing-channels/payment-marketing-channels.vue';
import CancellationAndAmendmentPolicy from '../cancellation-and-amendment-policy/cancellation-and-amendment-policy.vue';

// Types
import type VueI18n from 'vue-i18n';
import type { Policy } from '@white-label-types/parking-booking';
import type {
  BraintreeConfig,
  CartItem,
  ParsedDiscount,
  StripeConfig,
  Total
} from '@white-label-types/parking-checkout';
import type { UserData, MetaData } from '@white-label-types/stores';
import Checkbox from '../checkbox/checkbox.vue';
import PhoneField from '../phone-field/phone-field.vue';

import { datadogRum } from '@datadog/browser-rum';

export default defineComponent({
  components: {
    ButtonControl: Button,
    Policies: PaymentPolicies,
    Checkbox: Checkbox,
    PaymentGatewaysSharedBraintree: PaymentGatewayBraintree,
    PaymentGatewaysSharedStripe: PaymentGatewayStripe,
    IconArrow: () => import('@white-label-icon/icon-arrow'),
    IconAlert: () => import('@white-label-icon/icon-alert'),
    IconLockStar: () => import('@white-label-icon/icon-lock-star'),
    IconMessage,
    DiscountCode: DiscountCode,
    TextField,
    PhoneField,
    MarketingChannels: PaymentMarketingChannels,
    PortalTarget,
    CancellationAndAmendmentPolicy
  },

  mixins: [checkoutForm, auth],

  props: {
    title: {
      type: String,
      default: ''
    },
    cancellationPolicies: {
      type: Array as PropType<Policy[]>,
      default: () => []
    },
    amendmentPolicies: {
      type: Array as PropType<Policy[]>,
      default: () => []
    },
    paymentsConfig: {
      type: Object as PropType<BraintreeConfig | StripeConfig>,
      required: true,
      default: () => ({
        token: '',
        apiKey: ''
      })
    },
    paymentsConfigReceived: {
      type: Boolean,
      required: true,
      default: false
    },
    paymentIntentUpdated: {
      type: Boolean,
      default: false
    },
    orderTotal: {
      type: Number,
      default: 0
    },
    cartToken: {
      type: String,
      default: '',
    },
    isProtected: {
      type: Boolean,
      required: true,
    },
    cancellationProtection: {
      type: Object as PropType<CartItem['cancellation_protection']>,
      required: true,
    },
  },

  validations() {
    const userDetailsValidations = {
      first_name: {
        required,
        notNumber
      },
      last_name: {
        required,
        notNumber
      },
      license_plate: {
        required: requiredIf(function checkIfRequired() {
          // @ts-ignore - `this` is not recognized in its context
          return this.checkoutForm_checkIfFieldRequired('plate_number');
        }),
        alphaNumSpace(val: string) {
          // Fix when an empty field is considered an error
          // We have a separate validation to check if this field is required
          if (val) {
            return alphaNumSpace(val);
          }
          return true;
        }
      },
      email: {
        required,
        email
      },
      phone: {
        phone_number: {
          required: requiredIf(() => {
            // @ts-ignore - `this` is not recognized in its context
            return this.checkoutForm_checkIfFieldRequired('phone_number') ||
            // @ts-ignore - `this` is not recognized in its context
            ( this.userDetails.marketing_channels?.sms && this.checkoutForm_checkFieldVisibility('phone_number') )
          }),

          isValid: () : boolean => {
            const isEmpty = !this.userDetails.phone?.phone_number?.length;
            return !isEmpty ? this.phoneValid : true;
          }
        }
      },
      zipcode: {
        required: requiredIf(function checkIfRequired() {
          // @ts-ignore - `this` is not recognized in its context
          return this.checkoutForm_checkIfFieldRequired('zipcode');
        })
      },
      outbound_flight: {
        required: requiredIf(function checkIfRequired() {
          // @ts-ignore - `this` is not recognized in its context
          return this.checkoutForm_checkIfFieldRequired(
            'outbound_flight_number'
          );
        })
      },
      inbound_flight: {
        required: requiredIf(function checkIfRequired() {
          // @ts-ignore - `this` is not recognized in its context
          return this.checkoutForm_checkIfFieldRequired(
            'inbound_flight_number'
          );
        })
      },
      ...(isBookingPortal
        ? {
          others_email: {
            email
          },
          booker_email: {
            // @ts-ignore - `this` is not recognized in its context
            required: requiredIf(() => isBookingPortal),
            email
          }
        }
        : {})
    };
    const validations = {
      userDetails: {
        ...userDetailsValidations
      },
      emailConfirmation: {
        noPaste(): boolean {
          // @ts-ignore - `this` is not recognized in its context
          return !this.attemptToPaste;
        },
        sameAs: sameAs(function sameAsEmail() {
          // @ts-ignore - `this` is not recognized in its context
          return this.userDetails.email;
        }),
        required
      }
    };
    return validations;
  },

  data() {
    return {
      disableButton: false,
      userDetails: {
        first_name: '',
        last_name: '',
        email: '',
        phone: {
          phone_number: '',
          formatted_number: '',
          country_code: '',
          country_iso_code: ''
        },
        license_plate: '',
        zipcode: '',
        outbound_flight: '',
        inbound_flight: '',
        marketing_channels: {},
        booker_email: '',
        others_email: '',
        send_booker_email: false,
        send_customer_email: false,
        send_others_email: false,

        // Remove this property once the feature flag `WL-MarketingPreference` is no longer needed.
        is_subscribe: false
      } as UserData,
      metaData: {
        cost_centre: ''
      } as MetaData,
      emailConfirmation: '',
      attemptToPaste: false,
      phoneValid: false,
      showDiscountCode: false,
      validatingDiscount: false,
      discountError: '',
      onEmailChangeDebounced: null as (() => void) | null,
      showMarketingPreferences: false,
      isBookingPortal,
      booker_email: '',
      others_email: '',
    };
  },

  computed: {
    discountCode(): ReturnType<typeof readDiscountObject> {
      return readDiscountObject(this.$store);
    },

    emailError(): any {
      // @ts-ignore
      if (
        !this.$v.emailConfirmation.sameAs &&
        !this.$v.userDetails.email.$error
      ) {
        // @ts-ignore
        return this.$v.emailConfirmation;
      }
      // @ts-ignore
      return this.$v.userDetails.email;
    },

    displayEmailErrorMessage(): boolean {
      // @ts-ignore
      return (
        this.$v.userDetails.email.$error && this.$v.emailConfirmation.noPaste
      );
    },

    displayBraintreeGateway(): boolean {
      return (
        process.env.NUXT_ENV_PAYMENT_PROVIDER === PAYMENT_PROVIDERS.BRAINTREE &&
        this.paymentsConfigReceived &&
        this.paymentNeeded
      );
    },

    displayStripeGateway(): boolean {
      return (
        process.env.NUXT_ENV_PAYMENT_PROVIDER === PAYMENT_PROVIDERS.STRIPE &&
        this.paymentsConfigReceived &&
        this.paymentNeeded
      );
    },

    displayInfoMessage(): boolean {
      return (
        // @ts-ignore - Mixin method
        this.checkoutForm_paymentMethod ===
        this.checkoutForm_PAYMENT_METHODS.BRAINTREE.PAYPAL &&
        this.paymentNeeded
      );
    },

    submitButtonLabel(): VueI18n.TranslateResult {
      if (!this.paymentNeeded) {
        return this.$t('UI.button.payForBooking');
      }

      // @ts-ignore - Mixin method
      if (
        this.checkoutForm_paymentMethod ===
        this.checkoutForm_PAYMENT_METHODS.BRAINTREE.PAYPAL
      ) {
        return this.$t('UI.button.paypal');
      }

      if (isBookingPortal) {
        return this.$t('UI.button.makeBooking');
      }
      return this.$t('UI.button.payForBooking');
    },

    submitButtonId(): string {
      // @ts-ignore - Mixin method
      if (
        this.checkoutForm_paymentMethod ===
        this.checkoutForm_PAYMENT_METHODS.BRAINTREE.PAYPAL
      ) {
        return 'GAPaymentSubmitPaypal';
      }
      return 'GAPaymentSubmitCard';
    },

    submitButtonDisabled(): boolean {
      // @ts-ignore
      return this.$v.$error || this.disableButton;
    },

    paymentNeeded(): boolean {
      return this.orderTotal !== 0;
    },

    isDiscountFieldEnabled(): boolean {
      return getAppVariable(
        'discount_visibility_rules.is_enabled_in_payment_page'
      );
    },

    userMarketingSubscription(): ReturnType<typeof readUserMarketingSubscription> {
      return readUserMarketingSubscription(this.$store);
    },

    partnerMarketingPreferences(): ReturnType<typeof readMarketingPreferences> {
      return readMarketingPreferences(this.$store);
    },

    // Remove feature flag when MP is ready for full QA.
    marketingPreferencesFeatureFlag(): boolean {
      return this.$launchDarkly.variation('WL-MarketingPreference') === true;
    },

    cartItemsType(): ReturnType<typeof readCartItemsType> {
      return readCartItemsType(this.$store);
    },
    isBookerEmailCheckboxDisabled(): boolean {
      return !(this.userDetails.booker_email && this.userDetails.booker_email.length)
    },
    isCustomerEmailCheckboxDisabled(): boolean {
      return !(this.userDetails.email && this.userDetails.email.length)
    },
    isOthersEmailCheckboxDisabled(): boolean {
      return !(this.userDetails.others_email && this.userDetails.others_email.length)
    },

    /**
     * Gets all user data form userInfo store
     */
    getAllUserDetails(): ReturnType<typeof readUserDetails> {
      return readUserDetails(this.$store);
    },

    /**
     * Returns the email data from Vuex store userInfo
     */
    getUserInfoEmail(): ReturnType<typeof readUserDetails>['email'] {
      return this.getAllUserDetails.email;
    },

    /**
     * Returns the first name data from Vuex store userInfo
     */
    getUserInfoFirstName(): ReturnType<typeof readUserDetails>['firstName'] {
      return this.getAllUserDetails.firstName;
    },

    /**
     * Returns the last name data from Vuex store userInfo
     */
    getUserInfoLastName(): ReturnType<typeof readUserDetails>['lastName'] {
      return this.getAllUserDetails.lastName;
    },

    /**
     * Returns the postal code data from Vuex store userInfo
     */
    getUserPostalCode(): ReturnType<typeof readUserDetails>['postalCode'] {
      return this.getAllUserDetails.postalCode;
    },

    /**
     * Returns the license plate data from Vuex store userInfo
     */
    getUserInfoLicense() {
      return readVehicleDetails(this.$store).registrationNumber;
    },

    /**
     * Returns the phone number data from Vuex store userInfo
     */
    getPhoneNumbers(): ReturnType<typeof readPhoneNumbers> {
      return readPhoneNumbers(this.$store);
    }
  },

  watch: {
    userDetails: {
      deep: true, // watch nested data properties
      handler(formData: UserData) {
        // Send fields based on whether marketing preferences feature flag is turned on.
        if (this.marketingPreferencesFeatureFlag) {
          // eslint-disable-next-line no-param-reassign
          delete formData.is_subscribe;
          commitStoreFormData(this.$store, { formData });
        } else {
          // eslint-disable-next-line no-param-reassign
          delete formData.marketing_channels;
          commitStoreFormData(this.$store, { formData });
        }

        // Uncheck recipients checkbox if email value is empty
        if(!formData.email) {
          this.userDetails.send_customer_email=false
        }
        if(!formData.others_email) {
          this.userDetails.send_others_email=false
        }
      },
    },
  },

  async mounted() {
    if (this.$loggedUser) {
      // If user is logged in retrieve data from userInfo store to populate fields
      await this.getUserData();
    }
    if (this.marketingPreferencesFeatureFlag) {
      this.onEmailChangeDebounced = debounce(
        this.handleEmailChange,
        TEXT_INPUT_DEBOUNCE_TIME
      );
    }
    if (isBookingPortal) {
      this.handleEmailBookerChange();
    }
  },

  methods: {
    onFormFieldBlur(field: string): void {
      if (field.startsWith('email') && this.onEmailChangeDebounced) {
        this.onEmailChangeDebounced();
      }

      if (field === 'emailConfirmation') {
        this.$v[field].$touch();
      } else {
        this.$v.userDetails[field].$touch();
      }
    },
    onPaste(): void {
      this.attemptToPaste = true;
      // @ts-ignore
      this.$v.emailConfirmation.$touch();
      setTimeout(() => {
        this.attemptToPaste = false;
        // @ts-ignore
        if (!this.$v.$error) {
          // @ts-ignore
          this.$v.emailConfirmation.$reset();
        }
      }, 3000);
    },

    handleEmailBookerChange(): void {
      const userDetails = this.$auth;
      this.userDetails.booker_email = userDetails.$state.user.email;
    },

    handleEmailChange() {
      // @ts-ignore - no access to $v
      if (
        !this.$v.userDetails.email.$invalid &&
        this.userDetails.email === this.emailConfirmation
      ) {
        dispatchUserMarketingSubscription(this.$store, this.userDetails.email)
          .then(() => {
            if (this.userDetails.marketing_channels) {
              const marketingChannels: UserData['marketing_channels'] = {};

              if (this.isMarketingChannelAvailable('email')) {
                marketingChannels.email =
                  !!this.userMarketingSubscription?.marketing_channels?.email;
              }

              if (this.isMarketingChannelAvailable('sms')) {
                marketingChannels.sms =
                  !!this.userMarketingSubscription?.marketing_channels?.sms;
              }

              this.userDetails = {
                ...this.userDetails,
                marketing_channels: marketingChannels
              };
            }

            this.showMarketingPreferences = true;
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },

    isMarketingChannelAvailable(channel: string): boolean {
      return !!this.partnerMarketingPreferences?.marketing_channels?.includes(
        channel
      );
    },

    /*
    * Samuel Bruton
    * Confirming with another dev on what this actually is being used for.
    * Looks like it can be removed.
    * */
    loadStoredData(): void {
      const formData = readPaymentFormData(this.$store);
      if (formData) {
        this.userDetails = {
          ...formData,
          // @ts-ignore mixin method
          phone: this.checkoutForm_checkFieldVisibility('phone_number')
            ? { ...formData.phone }
            : { ...this.userDetails.phone },
          // @ts-ignore mixin method
          license_plate: this.checkoutForm_checkFieldVisibility('plate_number')
            ? formData.license_plate
            : '',
          // @ts-ignore mixin method
          zipcode: this.checkoutForm_checkFieldVisibility('zipcode')
            ? formData.zipcode
            : '',
          // @ts-ignore mixin method
          outbound_flight: this.checkoutForm_checkFieldVisibility('outbound_flight_number')
            ? formData.outbound_flight
            : '',
          // @ts-ignore mixin method
          inbound_flight: this.checkoutForm_checkFieldVisibility('inbound_flight_number')
            ? formData.inbound_flight
            : ''
        };
        commitClearFormData(this.$store);
      }
    },

    /** API call to get the user data */
    async getUserData(): Promise<void> {
      if (this.tokenIsValid) {
        const token = this.getToken;
        await fetchUserDetails(this.$store, token)
          .then(() => {
            this.userDetails = {
              email: this.getUserInfoEmail,
              first_name: this.getUserInfoFirstName,
              last_name: this.getUserInfoLastName,
              license_plate: this.checkoutForm_checkFieldVisibility('plate_number')
                ? this.getUserInfoLicense
                : '',
              zipcode: this.checkoutForm_checkFieldVisibility('zipcode')
                ? this.getUserPostalCode
                : '',
              phone: this.checkoutForm_checkFieldVisibility('phone_number')
                ? {
                    phone_number: this.getPhoneNumbers.phoneNumber,
                    formatted_number: this.getPhoneNumbers.phoneNumberWithDialingCode,
                    country_code: this.getPhoneNumbers.dialingCode,
                    country_iso_code: this.getPhoneNumbers.isoCode,
                  }
                : {
                  // empty data needed otherwise api call will fail
                    phone_number: '',
                    formatted_number: '',
                    country_code: '',
                    country_iso_code: '',
                },
              marketing_channels: this.partnerMarketingPreferences
            };
          })
          .catch((error: Error) => {
            console.error('Error with fetchUserDetails. Error logged with Datadog.')
            datadogRum.addError(error);
          })
      }
    },

    validateForm(): void {
      // @ts-ignore
      this.$v.$touch();

      // @ts-ignore
      if (this.$v.$error) {
        smoothScroll('.input-container--error');
        return;
      }

      this.disableButton = true;

      if (!this.paymentNeeded || isBookingPortal) {
        // @ts-ignore - Mixin method
        this.checkoutForm_handlePaymentCompleted({
          payment_method_id: 'zero',
          merchant_id: 'zero'
        });
      } else {
        // @ts-ignore
        this.$refs.paymentGateway.submitForm();
      }
    },

    addDiscount(val: string): void {
      this.validatingDiscount = true;
      this.disableButton = true;

      const params = {
        cartToken: this.cartToken,
        code: val.replace(/[/\\]/g, '|')
      };

      dispatchValidateDiscountCode(this.$store, params)
        .then(({ orderTotals, items }: ParsedDiscount) => {
          this.$emit('updateItems', items);
          this.$emit('updateOrderTotals', orderTotals);
          this.discountError = '';
        })
        .catch((e: Error) => {
          this.$emit('handleAddDiscountError');
          const errorResponse = JSON.parse(e.message);
          this.discountError = errorResponse.body.message;
        })
        .finally(() => {
          this.validatingDiscount = false;
          this.disableButton = false;
        });
    },

    removeDiscount(val: string): void {
      this.validatingDiscount = true;
      this.disableButton = true;

      const params = {
        cartToken: this.cartToken,
        code: val
      };

      dispatchRemoveDiscount(this.$store, params)
        .then(
          ({ orderTotals, items }: { orderTotals: Total; items: CartItem }) => {
            this.$emit('updateItems', items);
            this.$emit('updateOrderTotals', orderTotals);
            this.discountError = '';
          }
        )
        .catch((e: Error) => {
          const errorResponse = JSON.parse(e.message);
          this.discountError = errorResponse.body.message;
        })
        .finally(() => {
          this.validatingDiscount = false;
          this.disableButton = false;
        });
    },

    errorHandler(): void {
      this.disableButton = false;
      this.$emit('error');
    }
  }
});
