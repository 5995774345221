
import { defineComponent, PropType } from 'vue';
import type { EntryMethod, InventoryItem, Policy } from '@white-label-types/parking-booking';
import type { User } from '@white-label-types/parking-shared';
import BTicketDirectionsInformation from '../b-ticket-directions-information/b-ticket-directions-information.vue';
import BTicketPaymentSummary from '../b-ticket-payment-summary/b-ticket-payment-summary.vue';
import BTicketPolicies from '../b-ticket-policies/b-ticket-policies.vue';
import BTicketPersonalDetails from '../b-ticket-personal-details/b-ticket-personal-details.vue';

export default defineComponent({
  name: 'BTicketAdditionalInfo',

  components: {
    BTicketDirectionsInformation,
    BTicketPaymentSummary,
    BTicketPersonalDetails,
    BTicketPolicies
  },

  props: {
    displayCancellationPolicy: {
      type: Boolean,
      default: true,
    },

    hideDirections: {
      type: Boolean,
      default: false,
    },

    showPaymentForPrint: {
      type: Boolean,
      default: false,
    },

    inventoryItem: {
      type: Object as PropType<InventoryItem>,
      required: true,
    },

    user: {
      type: Object as PropType<User>,
      required: true,
    },

    barrierAccess: {
      default: () => ({}),
      type: Object as PropType<EntryMethod>,
    },

    cancellationPolicies: {
      type: Array as PropType<Policy[]>,
      default: () => [],
    },

    amendmentPolicies: {
      type: Array as PropType<Policy[]>,
      default: () => [],
    },

    isParkingProduct: {
      type: Boolean,
      default: false,
    },
  },
});
