import { render, staticRenderFns } from "./update-personal-details.vue?vue&type=template&id=063b6cea&scoped=true"
import script from "./update-personal-details.vue?vue&type=script&lang=ts"
export * from "./update-personal-details.vue?vue&type=script&lang=ts"
import style0 from "./update-personal-details.vue?vue&type=style&index=0&id=063b6cea&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "063b6cea",
  null
  
)

export default component.exports