

// Types
import type { SearchProducts } from '@white-label-types/parking-booking';

import { defineComponent, PropType } from 'vue';

// Components
import SearchParkingUpgradeModal from '../search-parking-upgrade-modal/search-parking-upgrade-modal.vue';
import ProductCardPreloader from '../product-card-preloader/product-card-preloader.vue';
import SearchParkingMoreInfoModal from '../search-parking-more-info-modal/search-parking-more-info-modal.vue';
import SearchParkingProductCard from '../search-parking-product-card/search-parking-product-card.vue';

// remove this when ECOM-176 will be ready for live
type ProductInfo = {
  name: string;
  info: {
    map: string;
    content: string;
    mapDescription: string;
  };
}

export default defineComponent({
  components: {
    ParkingsProductCardPreloader: ProductCardPreloader,
    SearchParkingMoreInfoModal,
    SearchParkingProductCard,
    SearchParkingUpgradeModal,
  },

  props: {
    products: {
      type: Array as PropType<SearchProducts[]>,
      required: true,
    },

    processAddingToCart: {
      type: Boolean,
    },

    contentLoading: {
      type: Boolean,
    },

    searchCriteria: {
      type: Object,
      required: true,
    },
  },

  // remove this when ECOM-176 will be ready for live
  defaultModalInfo: {
    name: '',
    info: {
      map: '',
      content: '',
      mapDescription: '',
    },
  },

  data() {
    return {
      // remove this when ECOM-176 will be ready for live
      modalInfo: {
        name: '',
        info: {
          map: '',
          content: '',
          mapDescription: '',
        },
      },
      displayMoreInfoModal: false,
      displayMoreInfoBookButton: true,
      moreInfoModalProductId: null as null | number,
      selectedId: '' as string | number,
      openMoreInfoModalOnReviewTab: false,
      displayUpgradeModal: false,
      upgradeModalProductId: null as null | number,
    };
  },

  computed: {
    hasProducts(): boolean {
      return !!this.products.length;
    },

    preloaderItemsCount(): undefined[] {
      if (this.isLargeDesktopResolution) {
        return Array(3);
      }
      if (this.isTabletResolution || this.isDesktopResolution) {
        return Array(2);
      }
      return Array(1);
    },

    moreInfoModalProductInfo(): SearchProducts | ProductInfo | undefined {
      return this.products.find((product) => product.id === this.moreInfoModalProductId);
    },

    upgradeModallProductInfo(): SearchProducts | undefined {
      return this.products.find((product) => product.id === this.upgradeModalProductId);
    },
  },

  methods: {
    onMoreInfoClick(payload: number | ProductInfo, isOpenOnReviewTab: boolean, isCurrentBooking: boolean): void {
      if (typeof payload === 'number') {
        this.moreInfoModalProductId = payload;
      } else {
        // @ts-ignore
        this.modalInfo = payload;
      }

      this.openMoreInfoModalOnReviewTab = isOpenOnReviewTab;
      this.displayMoreInfoBookButton = !isCurrentBooking;
      this.displayMoreInfoModal = true;
    },

    closeMoreInfoModal(): void {
      this.displayMoreInfoModal = false;

      this.moreInfoModalProductId = null;
    },

    showUpgradeModal(productId: number): void {
      this.displayMoreInfoModal = false;
      this.upgradeModalProductId = productId;
      this.displayUpgradeModal = true;
    },

    closeUpgradeModal(): void {
      this.upgradeModalProductId = null;
      this.displayUpgradeModal = false;
    },

    onParkingSelect(parking: SearchProducts): void {
      this.$emit('on-parking-select', parking);
    },

    onParkingRemove(parking: SearchProducts): void {
      this.$emit('on-parking-remove', parking);
    },

    onUpgradeModalParkingSelect(parking: SearchProducts): void {
      if (typeof this.upgradeModalProductId === 'string') {
        this.selectedId = this.upgradeModalProductId;
      }
      if (parking) {
        this.$emit('on-parking-select', parking, true);
      } else {
        this.$emit('on-parking-select', this.upgradeModallProductInfo, true);
      }
    },
  },
});
