
// Packages
import { defineComponent, PropType } from 'vue';

// Constants
import { BOOKING_STATUSES } from '@white-label-configuration/constants';

// Components
import DotsLoader from '../dots-loader/dots-loader.vue';

// Helpers
import {
  guessTimezone,
  parseISO,
  utcToZonedTime,
  isBefore,
  isAfter,
} from '@white-label-helper/date-utilities';
import { getAppVariable } from '@white-label-helper/get-app-variable';

// Types
import type { Booking } from '@white-label-types/account-booking';

// Store
import {
  readBookingsHasNextPage,
  readBookingsNextPageCount,
} from '@white-label-store/bookings';

// Components
import BookingsNoResults from '../bookings-no-results/bookings-no-results.vue';
import BookingItem from '../booking-item/booking-item.vue';
import TextButton from '../text-button/text-button.vue';

export default defineComponent({
  name: 'BookingsList',

  components: {
    BookingItem,
    BookingsNoResults,
    DotsLoader,
    TextButton,
  },

  props: {
    bookings: {
      type: Array as PropType<Booking[]>,
      required: true,
    },
    loadingNextPage: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      displayAllPastBookings: false,
      currentDate: utcToZonedTime(
        new Date(),
        getAppVariable('poi.timezone') || guessTimezone()
      ),
    };
  },

  computed: {
    partnerTimeZone(): string {
      return getAppVariable('poi.timezone') || guessTimezone();
    },

    currentBookings(): Booking[] {
      return this.bookings.filter((booking) => {
        const entryDateTime = parseISO(
          `${booking.startDatetime.date} ${booking.startDatetime.time}`
        );
        const exitDateTime = parseISO(
          `${booking.endDatetime.date} ${booking.endDatetime.time}`
        );

        return (
          isAfter(this.currentDate, entryDateTime) &&
          isBefore(this.currentDate, exitDateTime) &&
          booking.status.toUpperCase() !== BOOKING_STATUSES.CANCELLED
        );
      });
    },

    currentBookingsCount(): number {
      return this.currentBookings.length;
    },

    currentBookingsExists(): boolean {
      return !!this.currentBookings.length;
    },

    upcomingBookings(): Booking[] {
      return this.bookings.filter((booking: Booking) => {
        const entryDateTime = parseISO(
          `${booking.startDatetime.date} ${booking.startDatetime.time}`
        );

        return (
          isAfter(entryDateTime, this.currentDate) &&
          booking.status.toUpperCase() !== BOOKING_STATUSES.CANCELLED
        );
      });
    },

    upcomingBookingsCount(): number {
      return this.upcomingBookings.length;
    },

    upcomingBookingsExists(): boolean {
      return !!this.upcomingBookings.length;
    },

    pastBookings(): Booking[] {
      return this.bookings.filter((booking) => {
        const exitDateTime = parseISO(
          `${booking.endDatetime.date} ${booking.endDatetime.time}`
        );

        return (
          isBefore(exitDateTime, this.currentDate) ||
          booking.status.toUpperCase() === BOOKING_STATUSES.CANCELLED
        );
      });
    },

    pastBookingsCount(): number {
      return this.pastBookings.length;
    },

    pastBookingsExists(): boolean {
      return !!this.pastBookings.length;
    },

    displayViewMorePastBookingsButton(): boolean {
      return !this.loadingNextPage && readBookingsHasNextPage(this.$store);
    },

    remainingPastBookingsToggleBtnText(): string {
      return this.$tc(
        'bookings.viewMorePastBookings',
        this.remainingPastBookingsCount
      );
    },

    remainingPastBookingsCount(): number {
      return readBookingsNextPageCount(this.$store);
    },
  },

  mounted() {
    // making bookings array reactive
    setInterval(() => {
      this.currentDate = utcToZonedTime(new Date(), this.partnerTimeZone);
    }, 60000);
  },

  methods: {
    emitLoadNextPage(): void {
      this.$emit('load-next-page');
    },
  },
});
