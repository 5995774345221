var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ui-modal-button-wrapper"},[_c('button',{class:[
      'ui-button button button--full',
      _vm.getTypeOfStateClass,
      _vm.getEnvClass,
      _vm.getDisabledState,
      _vm.getJustifyClass,
    ],attrs:{"id":_vm.id,"data-testid":_vm.id,"type":_vm.type,"disabled":_vm.disabled},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.btnClick.apply(null, arguments)}}},[(_vm.loading)?_c('DotsLoader'):[(_vm.prependIcon)?_c('div',{staticClass:"mr-2"},[_c(_vm.prependIcon,{tag:"component"})],1):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),(_vm.appendedIcon)?_c('div',{staticClass:"ml-2"},[_c(_vm.appendedIcon,{tag:"component"})],1):_vm._e()]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }