import type { BookingFee } from '@white-label-types/parking-booking';

type CustomAxiosResponse<ITEMS, TOTALS> = {
  body: {
    data: {
      items: ITEMS[];
      totals: TOTALS;
    }
  },
}

export function parseCartResponse<ITEMS, TOTALS>(res: CustomAxiosResponse<ITEMS, TOTALS>):
  { item: ITEMS, items: ITEMS[], totals: TOTALS, booking_fee?: BookingFee } {
  const { items, totals } = res.body.data;
  return {
    item: items[0],
    items,
    totals,
  };
}
