import { getStoreAccessors } from 'typesafe-vuex';

import type { CartItem } from '@white-label-types/parking-checkout';

export type State = {
  items: CartItem[];
};

const cartState = (): State => ({
  items: [],
});

type CartGetters = {
  cartItemsType(state: State): string;
  cartItems(state: State): CartItem[];
};

type CartMutations = {
  setCartItems(state: State, items: CartItem[]): void;
  updateCancellationProtection(state: State, isProtected: boolean): void;
};

const cartGetters: CartGetters = {
  cartItemsType(state) {
    return (state.items.length && state.items[0]?.product_code) || '';
  },
  cartItems(state) {
    return state.items;
  },
};

const mutations: CartMutations = {
  setCartItems(state, items) {
    state.items = items;
  },
  updateCancellationProtection(state, isProtected) {
    state.items[0].is_protected = isProtected;
  }
};

const actions = {};

const { read, commit } = getStoreAccessors<State, State>('cart');

export const readCartItemsType = read(cartGetters.cartItemsType);
export const readCartItems = read(cartGetters.cartItems);
export const commitCartItems = commit(mutations.setCartItems);
export const commitUpdateCancellationProtection = commit(mutations.updateCancellationProtection);

export default {
  state: cartState,
  getters: cartGetters,
  mutations,
  actions,
};
