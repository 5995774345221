
import { defineComponent, PropType } from 'vue';
import TextField from '../text-field/text-field.vue';
import ErrorMessage from '../error-message/error-message.vue';
import DropdownList from '../dropdown-list/dropdown-list.vue';

// Types
import { Partners } from '@white-label-types/partners';

type Airport = Partners['poi'];
export default defineComponent({
  name: 'AirportSelect',

  components: {
    DropdownList,
    ErrorMessage,
    TextField,
    IconArrow: () => import('@white-label-icon/icon-arrow'),
  },

  props: {
    airports: {
      type: Array as PropType<Airport[]>,
      required: true,
    },

    customClasses: {
      type: String,
      required: false,
      default: ''
    },

    value: {
      type: String,
      required: true,
    },

    error: {
      type: Boolean,
      default: false,
    },

    errorHtml: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      selectedAirport: null as null | Airport,
      airportSearch: '',
      dropdownOpened: false,
      localDropdownOpened: false,
    };
  },

  computed: {
    selectedAirportName(): string {
      return this.selectedAirport?.name || '';
    },

    filteredAirports(): Airport[] {
      const airports = this.airports.map((airport) => {
        let name: string;

        // Striping out the following text from the airport names: 'International', Airport' and 'London'.
        // Exception for 'BFS' and 'LCY'.
        if (airport.code === 'BFS') {
          name = 'Belfast International';
        } else if (airport.code === 'LCY') {
          name = 'London City';
        } else {
          name = airport.name.replace(/International|Airport|London/g, '');
        }

        return {
          ...airport,
          name,
        };
      });

      if (!this.airportSearch || this.isMobileResolution || this.isTabletResolution) {
        return airports;
      }
      return airports.filter((airport) =>
        airport.name.toLowerCase().includes(this.airportSearch.toLowerCase())
      );
    },
  },

  mounted() {
    const airport = this.airports.find((item) => item.code === this.value);


    if (airport) {
      this.selectedAirport = airport;
      this.airportSearch = airport.name;
    }
  },

  methods: {
    focusHandler(): void {
      this.dropdownOpened = true;
      this.airportSearch = '';
    },

    clickHandler() : void {
      this.dropdownOpened = true;
    },

    dropdownSelectHandler(airport: Airport): void {
      this.selectedAirport = airport;
      this.airportSearch = airport.name;
      this.$emit('input', airport.code);
      this.dropdownOpened = false;
    },

    clickOutsideHandler(): void {
      this.dropdownOpened = false;
    },
  },
});
