// Types
import { Booking, Order } from '@white-label-types/account-booking';

/**
 * Parses the `/account/orders/:channel_id` response data into an array of Booking objects
 * containing only the fields needed by the MA app.
 * @param bookings order data from `/account/orders/:channel_id` endpoint
 * @returns an array of Bookings containing only fields needed on MA app.
 */
export function parseOrdersResponse (bookings: Order[]): Booking[] {
  return bookings.map((booking: Order) => {
    const {
      reference,
      order_items,
    } = booking;

    const orderItem = order_items[order_items.length - 1];
    const product = orderItem.product;
    const startDateTime = product?.started_at?.datetime.split('T');
    const closedDateTime = product?.closed_at?.datetime.split('T');


    return {
      id: reference,
      product_code: orderItem?.product_code,
      title: orderItem?.order_inventory?.display_name,
      status: orderItem?.status,
      address: product?.poi.address,
      startDatetime: {
        date: startDateTime ? startDateTime[0] : '',
        time: startDateTime ? startDateTime[1] : '',
      },
      endDatetime: {
        date: closedDateTime ? closedDateTime[0] : '',
        time: closedDateTime ? closedDateTime[1] : '',
      }
    };
  })
}
