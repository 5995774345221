import { render, staticRenderFns } from "./full-screen-preloader.vue?vue&type=template&id=379188f2&scoped=true"
import script from "./full-screen-preloader.vue?vue&type=script&lang=ts"
export * from "./full-screen-preloader.vue?vue&type=script&lang=ts"
import style0 from "./full-screen-preloader.vue?vue&type=style&index=0&id=379188f2&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "379188f2",
  null
  
)

export default component.exports