import defaultsDeep from 'lodash/defaultsDeep';
import type { Options } from './axios-config';
import BaseConfig from './http.base-instance-config';

const { interceptors } = BaseConfig;

interface ProductInstance extends Options {
  baseURL: string | undefined;
}

const options: ProductInstance = defaultsDeep({
  baseURL: process.env['NUXT_ENV_PRODUCT_API_BASE_URL'],
},
BaseConfig.options,
);

export default {
  interceptors,
  options,
};
