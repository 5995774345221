import { httpBooking } from '@white-label-configuration/http';
import { parseManageBookingState } from '@white-label-helper/data-parsers-manage-booking';

// Types
import type { User } from '@white-label-types/parking-shared';

interface UpdateBookingDetailsBody {
    order_reference: string,
    user: User,
}

interface ResendConfirmation {
    email: string,
    entry_date: string
}

interface ResendConfirmationType extends ResendConfirmation {
  product_type: 'lounges' | 'parking'
}

interface SearchBookingBody {
    email: string,
    reference_id: string | (string | null)[],
}

interface CancelBookingBody {
  order_item_reference: string,
  reason: string,
  meta: Record<string, unknown>,
}

export const updateBookingDetails = (body: UpdateBookingDetailsBody) => (
  httpBooking.put('pii-data', { body })
);

export const getManageBookingState = (manageBookingToken: string) => (
  httpBooking.get('account/booking/management', { headers: { 'Management-Token': manageBookingToken } })
    .then((res) => parseManageBookingState(res.body.data))
);

export const resendConfirmation = (body: ResendConfirmation | ResendConfirmationType) => (
  httpBooking.post('booking/receipt/resend', { body })
    .then((data) => data.body)
);

export const searchBooking = (body: SearchBookingBody) => (
  httpBooking.post('account/booking/management/token', { body })
    .then((res) => res.body.data)
);

export const cancellationCancelBooking = (manageBookingToken: string, body: CancelBookingBody) => (
  httpBooking.patch('booking/order/item', { headers: { 'Management-Token': manageBookingToken }, body })
);

export const cancellationEstimateBooking = (manageBookingToken: string) => (
  httpBooking.get('/booking/cancel-receipt/estimate', { headers: { 'Management-Token': manageBookingToken }})
    .then((res) => parseManageBookingState(res.body.data))
);

export const cancellationReceiptBooking = (manageBookingToken: string) => (
  httpBooking.get('/booking/cancel-receipt', { headers: { 'Management-Token': manageBookingToken }})
    .then((res) => res.body.data)
);
