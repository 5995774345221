import defaultsDeep from 'lodash/defaultsDeep';
import { Options } from './axios-config';
import BaseConfig from './http.base-instance-config';

const { interceptors } = BaseConfig;

interface CartInstance extends Options {
  baseURL: string | undefined;
}

const options: CartInstance = defaultsDeep(
  {
    baseURL: process.env['NUXT_ENV_CART_API_BASE_URL'],
  },
  BaseConfig.options,
);

export default {
  interceptors,
  options,
};
