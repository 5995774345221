
import { defineComponent } from 'vue';
import VueI18n from 'vue-i18n';

import type { SearchProducts } from '@white-label-types/parking-booking';
import { commitStoreItemIndex } from '@white-label-store/cart';

// Components
import ControlButton from '../control-button/control-button.vue';
import SearchLoungesProductCard from '../search-lounges-product-card/search-lounges-product-card.vue';
import AddedToBasketMessage from '../added-to-basket-message/added-to-basket-message.vue';
import NoResultsMessage from '../no-results-message/no-results-message.vue';


export default defineComponent({
  name: 'SearchResults',
  components: {
    ControlButton,
    NoResultsMessage,
    SearchLoungesProductCard,
    AddedToBasketMessage: AddedToBasketMessage,
  },
  props: {
    searching: {
      type: Boolean,
      default: true,
    },
    selectedId: {
      type: [Boolean, String],
      default: false,
    },
    searchPerformed: {
      type: Boolean,
      default: false,
    },
    multiBasket: {
      type: Boolean,
      default: false,
    },
    result: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
    };
  },
  computed: {
    noResultsMessage(): {
      title: VueI18n.TranslateResult;
      description: VueI18n.TranslateResult;
      button: VueI18n.TranslateResult;
    } {
      return {
        title: this.$t('searchNoResults.titleChangeDates'),
        description: this.$t('searchNoResults.descriptorChangeDates'),
        button: this.$t('searchNoResults.changeSearch'),
      };
    }
  },
  methods: {
    action(event: string, index: number, product: SearchProducts) {
      commitStoreItemIndex(this.$store, index);
      this.$emit('action', event, index, product);
    },
  },
});
