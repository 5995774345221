
import { defineComponent, PropType } from 'vue';
import { PortalTarget } from 'portal-vue';

import type { PurchaseProduct, InventoryItem, Policy, EntryMethod } from '@white-label-types/parking-booking';

import BTicket from '../b-ticket/b-ticket.vue'

interface ReceiptItem extends PurchaseProduct {
  inventory_item: InventoryItem;
  entry_methods?: EntryMethod[],
  inventory_option: {
    cancellation_policies: Policy[];
    amendment_policies: Policy[];
  }
}

import BTicketAdditionalInfo from '../b-ticket-additional-info/b-ticket-additional-info.vue';

export default defineComponent({

  components: {
    BTicketAdditionalInfo,
    BTicket,
    PortalTarget
  },

  props: {
    isParkingProduct: {
      type: Boolean,
      default: false,
    },

    items: {
      type: Array as PropType<ReceiptItem[]>,
      required: true,
    },

    orderReference: {
      type: String,
      default: '',
    },

    subItems: {
      type: Array,
      default() {
        return [];
      },
    },

    modifications: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    cancellationPolicies(): Policy[] {
      if (this.items[0]?.cancellation_protection) {
        return this.items[0].cancellation_protection.cancellation_policies;
      }
      return this.items[0]?.inventory_option?.cancellation_policies;
    },
  },

  methods: {
    getBTicketProps(item: ReceiptItem) {
      return {
        ticketData: item,
        orderReference: this.orderReference,
        subItems: this.subItems,
        showDirections: this.itemIsParkingProduct(item),
        showPolicies: true,
        isEntryExitProduct: this.itemIsParkingProduct(item),
        ...!this.itemIsParkingProduct(item) && {
          hideLocationInfo: this.modifications?.hideLocationInfo,
          hideAccessSection: this.modifications?.hideAccessSection,
          accessInfoFromEntranceInfo: this.modifications?.accessInfoFromEntranceInfo,
        },
        collapsible: true,
      }
    },
    itemIsParkingProduct(item: ReceiptItem): boolean {
      return item.product_code === 'parking';
    },
  }
});
