var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.classesForMobileOrDesktop,style:({ top: `${_vm.topPosition}px` })},[_c('div',{class:['added-to-basket-wrapper'],style:({ top: `${_vm.topPosition}px` }),on:{"click":_vm.hide}}),_vm._v(" "),_c('div',{staticClass:"w-full max-w-screen-xl px-20 flex justify-center"},[_c('div',{class:[
        'added-to-basket',
        { 'added-to-basket-mobile': !_vm.isDesktopResolution },
      ]},[_c('div',{staticClass:"flex items-center"},[_c('IconCheck',{staticClass:"text-brand-100",attrs:{"width":"24","height":"24"}}),_vm._v(" "),_c('span',{class:[
            _vm.isDesktopResolution
              ? 'flex-1 flex text-xl pl-2 text-1_5xl'
              : 'justify-center flex-1 flex text-xl',
          ]},[_vm._v("\n          Added to basket \n          "),(!_vm.isDesktopResolution)?_c('span',{staticClass:"text-mag-elements-icons-on-dark"},[_vm._v("\n            ("+_vm._s(_vm.cartItems?.length)+")\n          ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"cursor-pointer justify-end items-end text-mag-elements-icons-on-dark",on:{"click":function($event){return _vm.hide()}}},[_c('IconClose',{attrs:{"width":"24","height":"24"}})],1)],1),_vm._v(" "),(_vm.isDesktopResolution)?_c('div',{staticClass:"border-b border-gray-250 py-2 px-0 pb-0",on:{"mouseover":function($event){_vm.hasBeenHovered = true}}}):_vm._e(),_vm._v(" "),(_vm.isDesktopResolution)?_c('div',{staticClass:"pl-8 pt-2 p-6"},[_c('div',{staticClass:"flex justify-between text-lg font-bold py-2"},[_c('div',{staticClass:"flex justify-center items-start flex-col"},[_c('span',[_vm._v(" "+_vm._s(_vm.latestBasketItem?.inventory_item.name))])]),_vm._v(" "),_c('span',{staticClass:"whitespace-nowrap"},[_vm._v(_vm._s(_vm._f("formatPrice")(_vm.latestBasketItem?.totals.total)))])]),_vm._v(" "),(_vm.productTags.length)?_c('div',{staticClass:"bg-mag-elements-input-hover font-bold text-white text-xs tracking-wider gap-2 rounded py-0.5 px-1.5 w-20 h-5 flex items-center justify-center"},[_c('span',[_vm._v(" Flying out")])]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.isDesktopResolution)?_c('ControlButton',{staticClass:"flex-1 text-center",attrs:{"button-type":"primary","type":"button","type-of-element":"button","is-content-centered":""},on:{"btnClick":_vm.viewBasket}},[_vm._v("\n        "+_vm._s(_vm.buttonText)+"\n      ")]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }