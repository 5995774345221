import { httpCart } from '@white-label-configuration/http';
import { parseCartResponse } from '@white-label-helper/data-parsers-cart';
import { isBookingPortal } from '@white-label-helper/is-booking-portal';
import { myApp } from '@white-label-plugin/global-app-context';

// @ts-expect-error property
httpCart.onRequest((config) => {
  if (isBookingPortal) {
    // @ts-expect-error property
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${myApp.$auth?.user['https://user_metadata/channel_token']}`;
  }
});

interface AddToCartData {
  cartToken: string,
  searchProductResultId: string,
  inventoryOptionId: number,
  searchToken?: string,
  couponCode?: string | null,
  originalOrderItemIsProtected?: boolean,
  language: string,
  meta?: Record<string, string | number>,
}

interface AddToCartBody {
  search_product_result_id?: string,
  inventory_option_id?: number,
  customer_id: null,
  search_token?: string,
  original_order_item_is_protected?: boolean,
  coupon_code?: string,
  language?: string,
  items?: {inventory_option_id: number, search_product_result_id: string}[]
  meta?: Record<string, string | number>
}

interface UpdateCancellationProtectionBody {
  is_protected: boolean
}

export const fetchCartItems = (cartToken: string) => (
  httpCart.get(`public/cart/${cartToken}`)
    .then(({ body }) => body.data)
);

export const revalidateCartRequest = (cartToken: string) => (
  httpCart.patch(`public/cart/${cartToken}`)
);

export const addToCartRequest = (cartToken: string, body: AddToCartBody) => (
  httpCart.post(`public/cart/${cartToken}/items`, { body })
);

export const addToCart = <I, T>({
  cartToken,
  searchProductResultId,
  inventoryOptionId,
  originalOrderItemIsProtected,
  meta,
  couponCode,
  searchToken,
  language,
}: AddToCartData): Promise<{item: I, totals: T}> => {
  const body: AddToCartBody = {
    search_product_result_id: searchProductResultId,
    inventory_option_id: inventoryOptionId,
    customer_id: null,
    search_token: searchToken,
    language,
  };

  if (originalOrderItemIsProtected) { body.original_order_item_is_protected = originalOrderItemIsProtected; }
  if (couponCode) { body.coupon_code = couponCode; }
  if (meta) { body.meta = { ...meta }; }

  return httpCart.post(`public/cart/${cartToken}/items`, { body }).then((res) => parseCartResponse<I, T>(res));
};

export const addToCartItems = <I, T>({
  cartToken,
  searchProductResultId,
  inventoryOptionIds,
  originalOrderItemIsProtected,
  meta,
  couponCode,
  searchToken,
  language,
}: any) : Promise<any> => {
  const body: AddToCartBody = {
    search_product_result_id: searchProductResultId,
    customer_id: null,
    search_token: searchToken,
    language,
    items: inventoryOptionIds.map((id: string) => ({
      inventory_option_id: id,
      search_product_result_id: searchProductResultId,
    })),
  };

  if (originalOrderItemIsProtected) { body.original_order_item_is_protected = originalOrderItemIsProtected; }
  if (couponCode) { body.coupon_code = couponCode; }
  if (meta) { body.meta = { ...meta }; }

  return httpCart.post(`public/cart/${cartToken}/items-bulk`, { body }).then((res) => parseCartResponse<I, T>(res));
};

export const deleteCartItem = (
  cartToken: string,
  itemId: string,
) => httpCart.delete(`/public/cart/${cartToken}/items/${itemId}`).then((res) => res);

export const updateCancellationProtectionRequest = <I, T>(
  cartId: string,
  cartToken: string,
  body: UpdateCancellationProtectionBody,
): Promise<{item: I, totals: T}> => httpCart.patch(`/public/cart/${cartToken}/item/${cartId}/protection/update`, { body }).then((res) => parseCartResponse<I, T>(res));
