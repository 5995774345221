import { createAxiosInstance } from './setup';

import BaseConfig from './config/http.base-instance-config';
import SettingsBooking from './config/http.booking-instance-config';
import SettingsUser from './config/http.user-instance-config';
import SettingsCart from './config/http.cart-instance-config';
import SettingsProduct from './config/http.product-instance-config';
import SettingsMarketing from './config/http.marketing-instance-config';

const httpClient = createAxiosInstance({
  options: BaseConfig.options,
  interceptors: BaseConfig.interceptors,
});

const httpProduct = createAxiosInstance({
  options: SettingsProduct.options,
  interceptors: SettingsProduct.interceptors,
});

const httpCart = createAxiosInstance({
  options: SettingsCart.options,
  interceptors: SettingsCart.interceptors,
});

const httpBooking = createAxiosInstance({
  options: SettingsBooking.options,
  interceptors: SettingsBooking.interceptors,
});

export const httpUser = createAxiosInstance({
  options: SettingsUser.options,
  interceptors: SettingsUser.interceptors,
});

export const httpMarketing = createAxiosInstance({
  options: SettingsMarketing.options,
  interceptors: SettingsMarketing.interceptors,
});

export {
  httpClient, httpBooking, httpCart, httpProduct,
};
