// Types
import type { ManageBookingOriginalState, ManageBookingParsedState } from '@white-label-types/parking-booking';
import { convertTimeStringToObj } from '@white-label-helper/time-helpers';

export function parseManageBookingState(state: ManageBookingOriginalState): ManageBookingParsedState {
  const {
    created_at,
    order_reference,
    status,
    booking_fee,
    language,
  } = state;

  const orderTotals = state.totals;

  const {
    id,
    discount,
    inventory_item,
    inventory_option,
    product,
    user,
    pii_data_token,
    totals,
    taxes,
    fees,
    cancellation_protection,
    entry_methods,
    original_order_item_id,
    cancellation_info,
    total_refunded,
    amendment_fee,
    search_criteria,
    product_code
  } = state.items[0];

  const {
    started_at,
    closed_at,
    poi,
  } = product;

  // CUS-443: `started_at` is an object on Dev and Test, and a string on UAT
  const parsedStartedAt = typeof started_at === 'string'
    ? convertTimeStringToObj(started_at)
    : convertTimeStringToObj(started_at?.datetime);
  const parsedClosedAt = typeof closed_at === 'string'
    ? convertTimeStringToObj(closed_at)
    : convertTimeStringToObj(closed_at?.datetime);

  const showLicensePlate = typeof user.license_plate === 'string';

  const parseCustomerAgeGroups = () => {
    if (search_criteria?.lounges?.groups) {
      const groups = search_criteria.lounges.groups as { [k: string]: number };
      return Object.keys(groups).map(key => {
        const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
        return `${groups[key]} x ${capitalizedKey}`;
      });
    } else {
      return []
    }
  }

  const is_cancellable = 'cancellable_items' in state
    ? state.items.length === state.cancellable_items.length
    : state.items[0].is_cancellable;

  const is_amendable = 'amendable_items' in state
    ? state.items.length === state.amendable_items.length
    : state.items[0].is_amendable;

  return {
    showLicensePlate,
    id,
    discount,
    piiToken: pii_data_token,
    orderTotals,
    entryExitInfo: {
      entryDate: parsedStartedAt.date,
      entryTime: parsedStartedAt.time,
      exitDate: parsedClosedAt.date,
      exitTime: parsedClosedAt.time,
      terminal: (search_criteria?.parking ?? search_criteria?.lounges)?.terminal ?? null
    },
    originalOrderItemId: original_order_item_id,
    status: status.toUpperCase(),
    isCancellable: is_cancellable,
    isAmendable: is_amendable,
    paymentDate: created_at,
    orderId: order_reference,
    orderItemReference: state.items[0].order_reference,
    locationInfo: poi,
    user,
    groups: parseCustomerAgeGroups(),
    cancellationPolicies: inventory_option.cancellation_policies,
    amendmentPolicies: inventory_option.amendment_policies,
    parkingName: inventory_item.product_name,
    inventoryItem: inventory_item,
    entry_methods,
    cancellationProtection: cancellation_protection,
    cancellationInfo: cancellation_info,
    totals,
    taxes,
    fees,
    totalRefunded: total_refunded,
    amendmentFee: amendment_fee,
    items: state.items,
    bookingFee: booking_fee,
    productCode: product_code,
    language,
    searchCriteria: search_criteria
  };
}
