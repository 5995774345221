
// Packages
import { defineComponent } from 'vue';

// Helpers
import { goToApp, DOMAINS, ACCOUNT_URLS } from '@white-label-helper/switch-app';
import { isBookingPortal } from '@white-label-helper/is-booking-portal';

// Components
import IconWrapper from '../icon-wrapper/icon-wrapper.vue';
import BackButton from '../back-button/back-button.vue';

export default defineComponent({
  name: 'SearchParkingOriginalParkingInfo',
  components: {
    BackButton: BackButton,
    IconWrapper,
    IconAlert: () => import('@white-label-icon/icon-alert'),
    IconArrow: () => import('@white-label-icon/icon-arrow'),
  },
  props: {
    originalPrice: {
      type: String,
      default: '',
    },
  },
  data() {
    return { isBookingPortal };
  },

  methods: {
    goToManageBooking() {
      goToApp(DOMAINS.ACCOUNT, ACCOUNT_URLS.MANAGE_BOOKING);
    },

    openRefundModal() {
      this.$openModal('GlobalModalError', {
        header: this.$t('originalParkingInfo.refundModalTitle'),
        body: this.$t('originalParkingInfo.refundModalBody'),
        btnText: this.$t('UI.button.close'),
        btnType: 'custom',
        isInfo: true,
      }).catch((error) => {
        console.error(error);
      });
    },
  },
});
