
import { defineComponent } from 'vue';
import { commitShowBasketSlideOver } from '@white-label-store/multi-basket';
import {
  readTotals,
  readCartItems,
  readLatestItem,
} from '@white-label-store/cart';
import { formatPrice } from '@white-label-helper/helper-payment';
import ControlButton from '../control-button/control-button.vue';

export default defineComponent({
  name: 'AddedToBasketMessage',
  components: {
    ControlButton,
    IconClose: () => import('@white-label-icon/icon-close'),
    IconCheck: () => import('@white-label-icon/icon-check'),
  },
  filters: {
    formatPrice,
  },
  data() {
    return {
      productTags: [],
      topPosition: 0,
      hasBeenHovered: false,
    };
  },
  computed: {
    buttonText(): string {
      const cartTotal = formatPrice(Number(readTotals(this.$store)?.total));
      return `VIEW BASKET ${cartTotal} (${this.cartItems.length})`;
    },
    latestBasketItem(): ReturnType<typeof readLatestItem> {
      return readLatestItem(this.$store);
    },
    cartItems(): ReturnType<typeof readCartItems> {
      return readCartItems(this.$store);
    },
    classesForMobileOrDesktop() {
      const desktopClasses =
        'basket-notification__notSticked w-full basket-notification flex justify-center';
      const mobileClasses = 'h-12 w-full flex justify-center relative z-20';

      return this.isDesktopResolution ? desktopClasses : mobileClasses;
    },
  },
  mounted() {
    this.setTopPosition();
  },
  created() {
    setTimeout(() => {
      this.autoClose();
    }, 3000);

    let body = document.querySelector('body');
    if (body) {
      body.style.overflow = 'hidden';
    }
  },
  beforeDestroy() {
    let body = document.querySelector('body');
    if (body) {
      body.style.overflow = 'auto';
    }
  },
  methods: {
    autoClose() {
      if (this.hasBeenHovered) return;
      this.hide();
    },
    hide() {
      this.$store.commit('multiBasket/showBasketNotification', false);
    },
    viewBasket(): void {
      commitShowBasketSlideOver(this.$store, true);
      this.hide();
    },
    setTopPosition(): void {
      if (!this.isDesktopResolution) return;

      const topNavigation = document.getElementsByClassName('navbar');
      const stickyNavigation = document.getElementsByClassName('sticky-nav');
      const stickyNavigationIsInUse =
        stickyNavigation[0]?.classList.contains('scroll-element') || false;

      if (stickyNavigationIsInUse) {
        const rect = stickyNavigation[0].getBoundingClientRect();
        this.topPosition = rect.bottom;
        return;
      }

      if (topNavigation.length) {
        const rect = topNavigation[0].getBoundingClientRect();
        this.topPosition = rect.bottom > 0 ? rect.bottom : 0;
      }
    },
  },
});
