
// Packages
import { defineComponent } from 'vue';

// Constants
import {
  PROGRESS_INDICATOR_PAGES,
  MY_BOOKINGS_REDIRECTION_PAGES,
} from '@white-label-configuration/constants';

// Helpers
import { DOMAINS, ACCOUNT_URLS } from '@white-label-helper/switch-app';
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { getAppHeroProduct } from '@white-label-helper/get-app-hero-product';
import { isBookingPortal } from '@white-label-helper/is-booking-portal';

// Stores
import { readDiscount } from '@white-label-store/deep-link';

// Types
import type { TabData } from '@white-label-types/navbar';

// Components
import Navbar from '../navbar/navbar.vue';
import ProgressIndicator from '../progress-indicator/progress-indicator.vue';

type RedirectionPagesKeys = keyof typeof MY_BOOKINGS_REDIRECTION_PAGES;
type RedirectionPagesValues =
  (typeof MY_BOOKINGS_REDIRECTION_PAGES)[RedirectionPagesKeys];

export default defineComponent({
  name: 'EcommerceNavbar',

  components: {
    NavbarParkings: Navbar,
    ProgressIndicator,
  },

  data() {
    return {
      PROGRESS_INDICATOR_PAGES,
      isParkingProduct: getAppHeroProduct('lounges') === 'parking',
    };
  },

  computed: {
    displayProgress(): boolean {
      return Object.values(this.pages).includes(
        this.$route.name as RedirectionPagesValues
      );
    },

    pages(): typeof PROGRESS_INDICATOR_PAGES {
      return PROGRESS_INDICATOR_PAGES;
    },

    tabData(): TabData {
      const bookingLink =
        typeof DOMAINS.ACCOUNT === 'string'
          ? DOMAINS.ACCOUNT.concat(ACCOUNT_URLS.HOME)
          : '#';

      if (this.isParkingProduct && isBookingPortal) {
        return {};
      }
      return {
        tabsLinks: {
          BOOKING_LINK: bookingLink,
        },
      };
    },

    partner_name() {
      return getAppVariable('partner_name');
    },

    discountQuery(): ReturnType<typeof readDiscount> {
      return (
        readDiscount(this.$store) && { discount: readDiscount(this.$store) }
      );
    },

    logoURL() {
      return getAppVariable('logo');
    },

    logoRedirectURL() {
      return getAppVariable('logo_redirect_url');
    },
  },
});
