import { APP_HERO_PRODUCTS } from '@white-label-configuration/constants'

type HeroProducts = typeof APP_HERO_PRODUCTS
type HeroProduct = HeroProducts[keyof HeroProducts]

export function getAppHeroProduct(defaultValue: HeroProduct): HeroProduct {

  const heroProduct = process.env['NUXT_ENV_DEFAULT_HERO_PRODUCT']

  if (heroProduct === undefined) {
    throw new ReferenceError('The "NUXT_ENV_DEFAULT_HERO_PRODUCT" environment variables must be set')
  }

  if (heroProduct === '') {
    throw new ReferenceError('The "NUXT_ENV_DEFAULT_HERO_PRODUCT" environment variables must be a string')
  }

  const heroProductRecognised = Object.values(APP_HERO_PRODUCTS).includes(heroProduct as HeroProduct)

  if (heroProductRecognised === true) {
    return heroProduct as HeroProduct
  }

  // Warn and return the default value if not set
  console.warn(`You have not set a default hero product to fall back to`);
  return defaultValue
}
