
import { PropType, defineComponent } from 'vue';
import IconWrapper, {
  sizes,
  typesOfIcon,
} from '../icon-wrapper/icon-wrapper.vue';

export default defineComponent({
  name: 'IconMessage',

  components: {
    IconWrapper,
  },

  props: {
    iconType: {
      type: String as PropType<keyof typeof typesOfIcon>,
      default: 'info',
    },

    iconSize: {
      type: String as PropType<keyof typeof sizes>,
      default: 'small',
    },
  },

  computed: {
    getIconTypeClass(): string {
      return this.iconType ? `icon-message--${this.iconType}` : '';
    },
  },
});
