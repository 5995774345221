
import { defineComponent, PropType } from 'vue';
import { SCREENS } from '@white-label-configuration/constants';

import {
  format,
  utcToZonedTime,
  startOfDay,
  isBefore,
  parseISO,
  zonedTimeToUtc,
  isAfter,
  endOfDay,
  isSameDay,
  add,
  DATE_TIME_FORMATS,
} from '@white-label-helper/date-utilities';
import IconButton from '@white-label-icon/icon-button';

type ValueProp = {
  date: string;
  time: string;
  value?: string;
};

type HourObj = {
  name: string;
  value: string;
  $isDisabled: boolean;
};

export default defineComponent({
  components: {
    IconArrow: () => import('@white-label-icon/icon-arrow'),
    IconButton,
  },
  props: {
    scrollLock: {
      type: Boolean,
      default: false,
    },

    value: {
      type: Object as PropType<ValueProp>,
      default: () => {},
    },

    /** Available time slots depend on the date, we can't select a time in the past */
    date: {
      type: String,
      default: '',
    },

    /** Minimum time to start at */
    minTime: {
      type: Date,
      default: '',
    },

    /** Selected airport timezone */
    timeZone: {
      type: String,
      default: '',
    },

    step: {
      type: Number,
      default: 15, // 15 minutes
    },

    isMobilePopUp: {
      type: Boolean,
      default: false,
    },

    mobilePopUpTitle: {
      type: String,
      required: false,
      default: '',
    },

    labelMobile: {
      type: String,
      default: '',
    },

    labelDesktop: {
      type: String,
      default: '',
    },
  },

  computed: {
    timeList(): HourObj[] {
      const hours = [];
      const timeZone =
        this.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;
      const { date, step } = this;

      let targetDate = parseISO(date);
      if (isNaN(targetDate.getTime())) {
        targetDate = add(new Date(), { days: 5 });
      }
      const zonedDateStart = zonedTimeToUtc(startOfDay(targetDate), timeZone);
      const zonedDateEnd = zonedTimeToUtc(endOfDay(targetDate), timeZone);

      const currentTime = new Date();
      const utcToLocalTime = utcToZonedTime(currentTime, timeZone);

      let timeSlot = zonedDateStart;

      while (!isAfter(timeSlot, zonedDateEnd)) {
        const localTimeSlot = utcToZonedTime(timeSlot, timeZone);
        const $isDisabled = isBefore(localTimeSlot, utcToLocalTime) || isBefore(localTimeSlot, this.minTime);
        hours.push({
          name: format(localTimeSlot, DATE_TIME_FORMATS.time),
          value: format(localTimeSlot, DATE_TIME_FORMATS.time_24hr),
          $isDisabled,
        });
        timeSlot = add(timeSlot, { minutes: step });
      }
      return hours;
    },

    /** Get scroll area element */
    getScrollArea(): HTMLDivElement {
      if (this.$refs['scrollArea'] instanceof HTMLDivElement) {
        return this.$refs.scrollArea;
      }

      return document.createElement('div');
    },
  },

  mounted() {
    this.scrollToDefaultPosition();
  },

  methods: {
    /** Click on button shall scroll 4 points up */
    scrollUp() {
      this.getScrollArea.scrollTop -= this.getTimeItemHeight() * 4;
    },

    /** click on button shall scroll 4 points down */
    scrollDown() {
      this.getScrollArea.scrollTop += this.getTimeItemHeight() * 4;
    },

    getTimeItemHeight(): number {
      return this.getScrollArea.firstElementChild instanceof HTMLButtonElement
        ? this.getScrollArea.firstElementChild.offsetHeight
        : 0;
    },

    selectTime(time: HourObj) {
      if (time.$isDisabled) {
        return;
      }

      this.$emit('input', time);
      this.$emit('on-select', time);
    },

    checkIfTimeSelected(time: HourObj) {
      return time.value === this.value?.value;
    },

    scrollToDefaultPosition() {
      let timeIndex;
      // offset to center item in scroll area, 5 for desktop & 2 for mobile/tablet
      const offsetSize = window.outerWidth > SCREENS.LG ? 5 : 2;

      if (this.value?.value) {
        timeIndex = this.timeList.findIndex(
          (time) => time.value === this.value?.value
        );
      } else if (isSameDay(parseISO(this.date), new Date())) {
        timeIndex = this.timeList.findIndex((time) => !time.$isDisabled);
      } else {
        // 12 PM shall be shown in the middle by default
        timeIndex = this.timeList.findIndex((time) => time.value === '12:00');
      }

      if (this.getScrollArea) {
        this.getScrollArea.scrollTop +=
          this.getTimeItemHeight() * (timeIndex - offsetSize);
      }
    },
  },
});
