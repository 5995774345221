
import { defineComponent } from 'vue';
import IconTrash from '@white-label-icon/icon-trash';
import IconMessage from '../icon-message/icon-message.vue';

export default defineComponent({
  name: 'ManageBookingCancelHeader',
  components: {
    IconTrash,
    IconMessage,
  },
});
