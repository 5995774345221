
import { defineComponent } from 'vue';
import { Notification } from 'ui-shared-components';

const DELAY_TO_CLOSE_NOTIFICATION = 3000 as const;

type Notification = {
  id: number,
  message: string,
}

export default defineComponent({
  name: 'NotificationsContainer',

  components: {
    Notification,
  },

  data () {
    return {
      notifications: [] as Notification[],
    };
  },

  mounted (): void {
    this.$global_eventEmitter.$on('setNotificationData', this.setNotificationData);
    this.$global_eventEmitter.$emit('notificationsContainerMounted');
  },

  beforeDestroy (): void {
    this.$global_eventEmitter.$off('setNotificationData', this.setNotificationData);
  },

  methods: {
    setNotificationData (message: string): void {
      this.notifications.push({
        message,
        id: (new Date()).getTime(),
      });
    },

    close (id: number): void {
      const index = this.notifications.findIndex(notification => notification.id === id);
      this.notifications.splice(index, 1);
    },

    closeWithDelay (id: number): void {
      setTimeout(() => {
        this.close(id);
      }, DELAY_TO_CLOSE_NOTIFICATION);
    },
  },
});
