
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    IconCheck: () => import('@white-label-icon/icon-check'),
  },
  props: {
    name: {
      type: String,
      required: true,
      default: '',
    },

    value: {
      type: Boolean,
      default: false,
    },

    color: {
      type: String,
      default: 'brand',
    },

    width: {
      type: [String, Number],
      default: 14,
    },

    height: {
      type: [String, Number],
      default: 14,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onChange () {
      this.$emit('input', !this.value);
    },
  },
});
