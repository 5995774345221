import { render, staticRenderFns } from "./scroll-top-button.vue?vue&type=template&id=e6d82554&scoped=true"
import script from "./scroll-top-button.vue?vue&type=script&lang=ts"
export * from "./scroll-top-button.vue?vue&type=script&lang=ts"
import style0 from "./scroll-top-button.vue?vue&type=style&index=0&id=e6d82554&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e6d82554",
  null
  
)

export default component.exports