
// Packages
import { defineComponent, PropType } from 'vue';
import { ExpansionPanel } from 'ui-shared-components';

// Stores
import {
  readMarketingPreferences,
  readMarketingStrategy,
} from '@white-label-store/marketing-preferences';

// Types
import type { UserData } from '@white-label-types/stores';

// Components
import Checkbox from '../checkbox/checkbox.vue';
import { TranslateResult } from 'vue-i18n';

interface FieldsToShow {
  sms: boolean;
}

export default defineComponent({
  name: 'MarketingChannels',

  components: {
    Checkbox,
    ExpansionPanel,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },

    showFields: {
      type: Object as PropType<FieldsToShow>,
      required: true,
    },

    showDropdown: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    emailTickValue: {
      /**
       * Get the correct email checkbox value depending on marketing strategy
       * @example
       * opt-in: ticked = subscribed
       * opt-out: ticked = unsubscribed
       */
      get(): boolean {
        return this.marketingStrategy === 'opt-in'
          ? this.value['email']
          : !this.value['email'];
      },
      /**
       * Set the correct user subscription (email) value depending on checkbox value and marketing strategy
       */
      set(newValue: boolean): void {
        const subscribed =
          this.marketingStrategy === 'opt-in' ? newValue : !newValue;

        this.handleMarketingChannelChange({
          ...this.value,
          email: subscribed,
        });
      },
    },

    smsTickValue: {
      /**
       * Get the correct sms checkbox value depending on marketing strategy
       * @example
       * opt-in: ticked = subscribed
       * opt-out: ticked = unsubscribed
       */
      get(): boolean {
        return this.marketingStrategy === 'opt-in'
          ? this.value['sms']
          : !this.value['sms'];
      },
      /**
       * Set the correct user subscription (sms) value depending on checkbox value and marketing strategy
       */
      set(newValue: boolean): void {
        const subscribed =
          this.marketingStrategy === 'opt-in' ? newValue : !newValue;

        this.handleMarketingChannelChange({
          ...this.value,
          sms: subscribed,
        });
      },
    },
    partnerMarketingPreferences(): ReturnType<typeof readMarketingPreferences> {
      return readMarketingPreferences(this.$store);
    },

    marketingStrategy(): ReturnType<typeof readMarketingStrategy> {
      return readMarketingStrategy(this.$store);
    },

    showMarketingChannels(): boolean {
      return this.isSmsFieldAvailable || this.isEmailFieldAvailable;
    },

    isEmailFieldAvailable(): boolean {
      return this.isMarketingChannelAvailable('email');
    },

    isSmsFieldAvailable(): boolean {
      return this.isMarketingChannelAvailable('sms') && this.showFields.sms;
    },

    getCurrentLocale(): string {
      return this.$i18n.locale;
    },
  },

  methods: {
    handleMarketingChannelChange(
      marketingPreferences: UserData['marketing_channels']
    ): void {
      const marketingPreferencesToSave: UserData['marketing_channels'] = {};

      if (this.isEmailFieldAvailable) {
        marketingPreferencesToSave.email = !!marketingPreferences?.email;
      }

      if (this.isMarketingChannelAvailable('sms')) {
        marketingPreferencesToSave.sms = !!marketingPreferences?.sms;
      }

      this.$emit('input', marketingPreferencesToSave);
    },

    isMarketingChannelAvailable(channel: string): boolean {
      return !!this.partnerMarketingPreferences?.marketing_channels?.includes(
        channel
      );
    },

    getMarketingChannelLabel(channel: 'email' | 'sms'): TranslateResult {
      const isCustomMarketingTextFeatureOn = this.$launchDarkly.variation(
        'CUS_2042_MARKETING_PREFERENCES_CUSTOM_TEXT'
      );
      if (isCustomMarketingTextFeatureOn) {
        // Get the partners custom marketing text for each channel using the strategy, and locale
        const locale = this.getCurrentLocale;
        const customText = this.partnerMarketingPreferences?.custom_text;
        const strategy = this.marketingStrategy;
        const customTextChannelLabel =
          customText?.[strategy]?.[channel]?.[locale];

        if (customTextChannelLabel) {
          // if the custom text is available for the channel and the marketing strategy, return it
          return customTextChannelLabel;
        }
      }

      // if the custom text is not available, return the default text
      if (channel === 'email') {
        return this.marketingStrategy === 'opt-in'
          ? this.$t('shared.marketingPreferences.optInEmail')
          : this.$t('shared.marketingPreferences.optOutEmail');
      }
      return this.marketingStrategy === 'opt-in'
        ? this.$t('shared.marketingPreferences.optInSMS')
        : this.$t('shared.marketingPreferences.optOutSMS');
    },
  },
});
