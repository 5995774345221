
import { defineComponent } from 'vue';

// Components
import DotsLoader from '../dots-loader/dots-loader.vue';
import ManageBookingCancelHeader from '../manage-booking-cancel-header/manage-booking-cancel-header.vue';
import StickySidebar from '../sticky-sidebar/sticky-sidebar.vue';
import TicketPreloader from '../ticket-preloader/ticket-preloader.vue';
import SummaryPreloader from '../summary-preloader/summary-preloader.vue';

export default defineComponent({
  name: 'CancelBookingPreloader',
  components: {
    DotsLoader,
    MBCancelHeader: ManageBookingCancelHeader,
    StickySidebar,
    TicketPreloader,
    SummaryPreloader,
  },
});
