
import { defineComponent } from 'vue';
import BTicketWrap from '../b-ticket-wrap/b-ticket-wrap.vue';

export default defineComponent({
  name: 'TicketPreloader',

  components: {
    BTicketWrap,
  },

  props: {
    hideBottomSection: {
      type: Boolean,
      default: false,
    },
  },
});
