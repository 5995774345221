import { Plugin } from '@nuxt/types';
import contentPreloader from './store/content-preloader';

const namespace = 'contentPreloader';

export const contentPreloaderModule: Plugin = (context, inject) => {
  context.store.registerModule(namespace, contentPreloader({ namespace }), {
    // if the store module already exists, preserve it
    preserveState: Boolean(context.store.state[namespace]),
  });

  function globalSetContentPreloader(state: boolean): void {
    context.store.commit('contentPreloader/updateStatus', state);
  }

  inject('global_enableContentPreloader', () => globalSetContentPreloader(true));
  inject('global_disableContentPreloader', () => globalSetContentPreloader(false));

  context.global_enableContentPreloader = () => globalSetContentPreloader(true);
  context.global_disableContentPreloader = () => globalSetContentPreloader(false);
};
