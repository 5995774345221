
import { defineComponent, PropType } from 'vue';

// Helpers
import { trackCheckoutStep } from '@white-label-helper/helper-tracking';
import { trackViewItem } from '@white-label-helper/ga-tracking';
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { initFeefoReviews, updateFeefoStyles } from '@white-label-helper/init-feefo-reviews';
import { gaDataLayer } from '@white-label-helper/ga-data-layer';

// Types
import type { SearchProducts } from '@white-label-types/parking-booking';

// Store
import { readFeaturedProducts, readYourSearchProductId } from '@white-label-store/search-results';
import { readItemListName } from '@white-label-store/cart';
import CavuButton from '../cavu-button/cavu-button.vue';
import SearchParkingTransfer from '../search-parking-transfer/search-parking-transfer.vue';
import SearchParkingPrice from '../search-parking-price/search-parking-price.vue';

export default defineComponent({
  name: 'SearchParkingProductCard',

  components: {
    ControlButton: CavuButton,
    IconArrow: () => import('@white-label-icon/icon-arrow'),
    IconButton: () => import('@white-label-icon/icon-button'),
    IconCheck: () => import('@white-label-icon/icon-check'),
    IconPlus: () => import('@white-label-icon/icon-plus'),
    IconClose: () => import('@white-label-icon/icon-close'),
    IconSuccess: () => import('@white-label-icon/icon-success'),
    SearchParkingPrice,
    SearchParkingTransfer,
  },

  props: {
    product: {
      type: Object as PropType<SearchProducts>,
      default: () => {},
    },
    multiBasket: {
      type: Boolean,
      default: false,
    },
    inBasket: {
      type: Boolean,
      default: false,
    },
    processAddingToCart: {
      type: Boolean,
      default: false,
    },
    selectedId: {
      type: [String, Number] as PropType<string | number>,
      required: true,
    },
    productKey: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      productFullInfoExpanded: false,
    };
  },

  computed: {
    showUrgentMessage(): boolean {
      if (!this.product.capacity) { return false; }
      const { message_threshold, left } = this.product.capacity;
      return typeof message_threshold === 'number' && typeof left === 'number' && left <= message_threshold;
    },

    featuredProducts(): ReturnType<typeof readFeaturedProducts> {
      return readFeaturedProducts(this.$store);
    },

    isYourSearchProduct(): boolean {
      return readYourSearchProductId(this.$store) === this.product.id;
    },

    isFeatured(): boolean {
      return !this.isYourSearchProduct
        && !!this.featuredProducts?.some((product) => product.product_id === this.product.id);
    },

    urgencyMessage(): string {
      return this.$tc('searchProductCard.urgencyMessage', this.product?.capacity?.left);
    },

    showBookBtnLoader(): boolean {
      return this.processAddingToCart
        && this.product.id === this.selectedId;
    },

    bookBtnDisabled(): boolean {
      return this.processAddingToCart && this.product.id !== this.selectedId;
    },

    validFeatures(): string[] {
      return this.product?.features?.filter((i) => !!i) || [];
    },

    displayProductFeatures(): boolean {
      return !!(this.validFeatures?.length);
    },

    isMoreInfoExists(): boolean {
      return !!(this.product?.description || this.product?.map_image?.src);
    },

    isCurrentBooking(): boolean {
      return !!this.product.isCurrentBooking;
    },

    showProductFullInfo(): boolean {
      if (!this.isCurrentBooking || !this.isMobileResolution) { return true; }

      return this.productFullInfoExpanded;
    },

    showProductLogo(): boolean {
      return getAppVariable('is_product_logo_visible') && !!this.product.product_logo?.src;
    },

    isReviewsEnabled(): boolean {
      return !!getAppVariable('review_providers[0].is_enabled') && this.$launchDarkly.variation('WL-ProductCardReviewStars') === true;
    },

    brandColor(): string {
      // TODO: ECOM-1455
      // use `colours.brand` if available, otherwise fallback to `brand_color` (to be deprecated soon)
      const brandColour = getAppVariable('colours.brand') ?? getAppVariable('brand_color');
      if (brandColour) {
        return brandColour.substring(0, brandColour.lastIndexOf(','));
      }
      return '';
    },
  },

  mounted() {
    if (this.isReviewsEnabled && this.productKey === 0) {
      initFeefoReviews(getAppVariable('review_providers[0].merchant_id'));
      updateFeefoStyles(this.brandColor);
    }
  },

  methods: {
    onBookLotClick(): void {
      if (this.product.upgradeObject?.id) {
        this.$emit('show-upgrade-modal', this.product.id);
      } else {
        this.$emit('update:selectedId', this.product.id);

        trackCheckoutStep(this.$gtm, 2, 'Select product');
        this.$emit('on-parking-select', this.product);
      }
    },

    onRemoveLotClick(): void {
      this.$emit('update:selectedId', this.product.id);
      this.$emit('on-parking-remove', this.product);
    },

    onMoreInfoClick(openOnReviewTab: boolean): void {
      const itemListName = readItemListName(this.$store);

      this.$gtm.push(
        gaDataLayer({
          partnerName: getAppVariable('partner_name'),
        })
      );
      trackViewItem(this.$gtm, this.product, this.productKey, itemListName);
      this.$emit('more-info-click', this.product.id, openOnReviewTab, this.product.isCurrentBooking);
    },
  },
});
