import { getStoreAccessors } from 'typesafe-vuex';
import { RootState } from '@white-label-types/stores';
import type { ParkingFormData, LoungeFormData } from '@white-label-types/search-box';


export type State = {
  formData: ParkingFormData | LoungeFormData;
  formType: string;
  timestamp: Date | string | null;
  params?: { [key: string]: string };
};
const searchBoxState = (): State => ({
  formData: {
    entryDate: '',
    entryTime: null,
    exitDate: '',
    exitTime: null,
    terminal: undefined,
    date: '',
    discount: null,
  },
  params: {},
  formType: '',
  timestamp: null,
});

const getters = {
  formData: (state: State): State['formData'] => state.formData,
  formType: (state: State): State['formType'] => state.formType,
  timestamp: (state: State): State['timestamp'] => state.timestamp,
  timeName: (state: State): string | undefined => state.formData?.time?.name,
  airportDisplayName: (state: State): string | undefined =>
    state.formData?.airport?.display?.full,
};

const mutations = {
  storeData(state: State, payload: State) {
    state.formData = { ...payload.formData };
    state.timestamp = payload.timestamp;
  },

  clearStoreData(state: State) {
    state.formData = {
      entryDate: '',
      entryTime: null,
      exitDate: '',
      exitTime: null,
      terminal: undefined,
      date: '',
      discount: null,
    };
    state.params = {};
    state.formType = '';
    state.timestamp = null;
  },

  saveParams(state: State, payload: { id: string; data: object }) {
    if (payload.id && payload.data && state.params)
      state.params[payload.id] = JSON.stringify(payload.data);
  },
};

const actions = {
  getParams({ state }: { state: State }, id: string) {
    return state.params ? JSON.parse(state.params[id] || '{}') : {};
  },
};

const { commit, read } = getStoreAccessors<State, RootState>('searchBox');

export const readFormData = read(getters.formData);
export const readFormType = read(getters.formType);
export const readTimestamp = read(getters.timestamp);
export const readTimeName = read(getters.timeName);
export const readAirportDisplayName = read(getters.airportDisplayName);

export const commitStoreData = commit(mutations.storeData);
export const commitClearStoreData = commit(mutations.clearStoreData);

export default {
  namespaced: true,
  state: searchBoxState,
  actions,
  getters,
  mutations,
};
