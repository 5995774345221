
import { defineComponent } from 'vue';

import IconArrowDown from '@white-label-icon/icon-arrow-down';
import IconCancel from '@white-label-icon/icon-cancel';
import IconClose from '@white-label-icon/icon-close';
import IconEnvelope from '@white-label-icon/icon-envelope';
import IconLoungeBooking from '@white-label-icon/icon-lounge-booking';
import IconPlus from '@white-label-icon/icon-plus';
import IconPrinter from '@white-label-icon/icon-printer';
import IconSettings from '@white-label-icon/icon-settings';
import IconShare from '@white-label-icon/icon-share';
import IconUser from '@white-label-icon/icon-user';
import IconParking from '@white-label-icon/icon-parking';
import IconBookmark from '@white-label-icon/icon-bookmark';

export default defineComponent({
  name: 'IconButton',

  components: {
    IconArrowDown,
    IconCancel,
    IconClose,
    IconEnvelope,
    IconLoungeBooking,
    IconPlus,
    IconPrinter,
    IconSettings,
    IconShare,
    IconUser,
    IconParking,
    IconBookmark
  },

  props: {
    iconName: {
      type: String,
      default: '',
    },
  },
});
