var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"passwordContainer",class:[
    'password-container',
    {
      'password-container--error': _vm.passwordError,
      'password-container--warning': _vm.passwordWarning,
      'password-container--success': _vm.passwordSuccess,
    },
  ]},[_c('TextField',{ref:"textField",attrs:{"label":"Password","type":"password","placeholder":"Create Your Password","value":_vm.value,"error":_vm.error},on:{"input":_vm.onInput,"focus":_vm.onFocus,"blur":_vm.onBlur,"keypress":_vm.onKeypress}}),_vm._v(" "),_c('button',{staticClass:"password-visibility-toggle",on:{"click":_vm.togglePasswordVisibility}},[(_vm.showPassword)?_c('IconHide',{staticClass:"text-mag-elements-icons-on-dark"}):_c('IconShow',{staticClass:"text-mag-elements-icons-on-dark"})],1),_vm._v(" "),(_vm.displayPasswordStrength)?_c('div',{class:['password-strength', `password-strength-${_vm.passwordStrength}`]},_vm._l((_vm.passwordHints.length),function(index){return _c('span',{key:index})}),0):_vm._e(),_vm._v(" "),(_vm.displayPasswordHints)?_c('div',{staticClass:"password-hints"},[_c('p',{staticClass:"text-sm"},[_vm._v("Password must :")]),_vm._v(" "),_vm._l((_vm.passwordHints),function(hint,index){return _c('div',{key:index,staticClass:"flex flex-row items-center mt-2"},[_c('div',{class:[
          'w-2 h-2 rounded-full mr-2',
          hint.match ? 'bg-success-100' : 'bg-mag-elements-icons-on-dark',
        ]}),_vm._v(" "),_c('p',{staticClass:"text-xs text-mag-elements-icons-on-dark"},[_vm._v("\n        "+_vm._s(hint.text)+"\n      ")])])})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }