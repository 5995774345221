
import { defineComponent, PropType } from 'vue';
import type { DropdownItems } from '@white-label-types/dropdown';
import DropdownList from '../dropdown-list/dropdown-list.vue';
import ErrorMessage from '../error-message/error-message.vue';

export default defineComponent({
  name: 'DropdownWrapper',
  components: {
    DropdownList,
    ErrorMessage,
    IconArrow: () => import('@white-label-icon/icon-arrow'),
  },

  props: {
    dropdownOpened: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array as PropType<DropdownItems>,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
      required: true,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    errorHtml: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    localDropdownOpened: {
      get(): boolean {
        return this.dropdownOpened;
      },
      set(val: boolean) {
        this.$emit('update:dropdownOpened', val);
      },
    },

    localValue: {
      get(): string {
        return this.value?.name;
      },

      set(val: boolean) {
        this.$emit('input', val);
        this.localDropdownOpened = false;
      },
    },

    scopedSlots() {
      return this.$scopedSlots;
    },
  },

  methods: {
    onClickDropdownTarget(): void {
      if (!this.readonly) {
        this.localDropdownOpened = !this.localDropdownOpened;
      }
    },

    clickOutSide(): void {
      if (this.isDesktopResolution) {
        this.localDropdownOpened = false;
      }
    },
  },
});
