
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'RadioButton',

  props: {
    name: {
      type: String,
      required: true,
      default: '',
    },

    option: {
      type: String,
      required: true,
      default: '',
    },

    color: {
      type: String,
      default: 'brand',
    },

    width: {
      type: [String, Number],
      default: 20,
    },

    value: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      initial: this.value,
    };
  },

  methods: {
    onChange (value: string) {
      this.$emit('input', value);
    },
  },
});
