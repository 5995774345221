
// Packages
import { defineComponent } from 'vue';

// Components
import IconPrinter from '@white-label-icon/icon-printer';

// Mixins
import print from '@white-label-helper/mixin-print';

export default defineComponent({
  name: 'PrintButton',
  components: {
    IconPrinter
  },
  mixins: [print],

  props: {
    id: {
      type: String,
      default: '',
    },
  },
});
