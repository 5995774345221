
// Packages
import { defineComponent, PropType } from 'vue';

// Helpers
import {
  format,
  add,
  isBefore,
  DATE_TIME_FORMATS,
  utcToZonedTime,
  parseISO,
  guessTimezone,
} from '@white-label-helper/date-utilities';
import isEqual from 'lodash/isEqual';
import { required } from '@white-label-helper/vuelidate';
import { getAppVariable } from '@white-label-helper/get-app-variable';

// Constants
import {
  PARKINGS_TIMEPICKER_STEPS,
  NAMED_ROUTES,
} from '@white-label-configuration/constants';

import ControlButton from '../control-button/control-button.vue';
import DateTimePicker from '../date-time-picker/date-time-picker.vue';
import DropdownWrapper from '../dropdown-wrapper/dropdown-wrapper.vue';
import TextField from '../text-field/text-field.vue';

// Types
import type VueI18n from 'vue-i18n';
import type {
  FormData,
  Terminal,
  Time,
  ParkingFormData,
} from '@white-label-types/component';
import { Airport } from '@white-label-types/search-box';

export default defineComponent({
  name: 'SearchParkingEditForm',

  components: {
    ControlButton,
    DateTimePicker,
    DropdownWrapper,
    IconButton: () => import('@white-label-icon/icon-button'),
    TextField,
  },

  props: {
    timeZone: {
      type: String,
      default: guessTimezone(),
    },

    formData: {
      type: Object as PropType<ParkingFormData>,
      default: () => {},
    },

    updatingSearch: {
      type: Boolean,
      default: false,
    },
    apiRequestRunning: {
      type: Boolean,
      default: false,
    },
    customErrorMessages: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      terminals: [] as Terminal[],
      airport: '' as ParkingFormData['airport'],
      terminal: '' as ParkingFormData['terminal'],
      entryDate: '' as ParkingFormData['entryDate'],
      entryTime: null as ParkingFormData['entryTime'],
      exitDate: '' as ParkingFormData['exitDate'],
      exitTime: null as ParkingFormData['exitTime'],
      dropdownStatuses: {
        entryDateTimePickerFocused: false,
        exitDateTimePickerFocused: false,
        discountFieldFocused: false,
        terminalOpened: false,
      },
      exitDateRequiredMessage: this.$t(
        'validationError.exitDateTime.dateError'
      ) as VueI18n.TranslateResult,
      exitTimeRequiredMessage: this.$t(
        'validationError.exitDateTime.timeError'
      ) as VueI18n.TranslateResult,
      exitDateTimeRequiredMessage: this.$t(
        'validationError.exitDateTime.dateTimeError'
      ) as VueI18n.TranslateResult,
      entryDateTimeRequiredMessage: this.$t(
        'validationError.entryDateTime.dateTimeError'
      ) as VueI18n.TranslateResult,
      entryDateRequiredMessage: this.$t(
        'validationError.entryDateTime.dateError'
      ) as VueI18n.TranslateResult,
      entryTimeRequiredMessage: this.$t(
        'validationError.entryDateTime.timeError'
      ) as VueI18n.TranslateResult,
      exitTimeBeforeEntryErrorMessage: this.$t(
        'validationError.exitTimeIsBeforeEntryTime'
      ) as VueI18n.TranslateResult,
      PARKINGS_TIMEPICKER_STEPS,
      maxDatePickerDate: add(new Date(), { years: 2 }) as Date,
      discount: null as FormData['discount'],
      entryTimeHaveChanged: false,
    };
  },

  computed: {
    airports(): Airport[] {
      return getAppVariable('pois') || [];
    },

    isMultiAirportPartner(): boolean {
      return this.airports.length > 1;
    },

    multiAirportTerminals(): Terminal[] {
      const allSelectedTerminals: Terminal[] = getAppVariable('terminals');
      const airportTerminals = this.airports.find((item) => item.code === this.formData.airport)?.terminals;

      if (airportTerminals) {
        return airportTerminals.filter((t1) => allSelectedTerminals.some((t2) => t1.id === t2.id));
      } else {
        return [];
      }
    },

    terminalsWithDefault(): Terminal[] {
      return [
        ...(
          this.isMultiAirportPartner
            ? this.multiAirportTerminals
            : this.terminals
        ),
        {
          name: this.$t('searchBox.dontKnowOption'),
          id: 'dontKnow',
        },
      ];
    },

    computedTerminal: {
      get(): Terminal {
        return (
          this.terminalsWithDefault.find(
            (i) => i.id.toString() === this.terminal?.toString()
          ) || {
            name: '',
            id: '',
          }
        );
      },
      set(val: Terminal): void {
        this.terminal = val.id.toString();
      },
    },

    timezoneOffset(): Date {
      return utcToZonedTime(new Date(), this.timeZone);
    },

    isTerminalsExists(): boolean {
      return this.terminals?.length > 1;
    },

    formHasChanges(): boolean {
      const isEntryTimeChanged = !isEqual(
        this.entryTime,
        this.formData.entryTime
      );
      const isExitTimeChanged = !isEqual(this.exitTime, this.formData.exitTime);
      const isEntryDateChanged = this.entryDate !== this.formData.entryDate;
      const isExitDateChanged = this.exitDate !== this.formData.exitDate;
      const isTerminalChangedIfTerminalsExists = this.isTerminalsExists
        ? this.terminal !== this.formData.terminal
        : false;
      const isDiscountChanged = this.discount !== this.formData.discount;

      return (
        isEntryTimeChanged ||
        isExitTimeChanged ||
        isEntryDateChanged ||
        isExitDateChanged ||
        isTerminalChangedIfTerminalsExists ||
        isDiscountChanged
      );
    },

    updateFormBtnEnabled(): boolean {
      return this.formHasChanges;
    },
    exitNotBeforeEntryError(): boolean {
      // @ts-ignore
      return !this.$v.time.exitNotBeforeEntry;
    },
    showExitCommonErrorMessage(): boolean {
      if (this.apiRequestRunning) return false;
      // @ts-ignore
      return !this.$v.time.exitNotBeforeEntry;
    },
    entryDateError(): boolean {
      if (this.apiRequestRunning) return false;
      if (!this.customErrorMessages?.date1?.length) return false;
      if (this.customErrorMessages.date1) {
        if (this.entryTimeHaveChanged) return false;
        return true;
      }
      // @ts-ignore
      return this.$v.entryDate.$error;
    },

    entryTimeError(): boolean {
      if (this.apiRequestRunning) return false;
      if (!this.customErrorMessages?.time1?.length) return false;
      if (this.customErrorMessages.time1.length > 0) {
        if (this.entryTimeHaveChanged) return false;
        return true;
      }
      // @ts-ignore
      return this.$v.entryTime.$error;
    },

    exitDateError(): boolean {
      if (this.apiRequestRunning) return false;
      if (!this.customErrorMessages?.date2?.length) return false;
      if (this.customErrorMessages.date2) {
        if (this.entryTimeHaveChanged) return false;
        return true;
      }
      // @ts-ignore
      return this.$v.exitDate.$error;
    },

    exitTimeError(): boolean {
      if (this.apiRequestRunning) return false;
      if (!this.customErrorMessages?.time2?.length) return false;
      if (this.customErrorMessages.time2) {
        if (this.entryTimeHaveChanged) return false;
        return true;
      }
      // @ts-ignore
      return this.$v.exitTime.$error;
    },

    terminalError(): boolean {
      // @ts-ignore
      return this.$v.terminal.$error;
    },
    entryErrorMessage(): VueI18n.TranslateResult | string {
      if (this.apiRequestRunning) return '';
      // @ts-ignore
      if (this.customErrorMessages?.hasOwnProperty('time1')) {
        // @ts-ignore
        return this.customErrorMessages.time1;
      }
      if (this.customErrorMessages?.hasOwnProperty('date1')) {
        // @ts-ignore
        return this.customErrorMessages.date1;
      }
      if (this.entryDateError && this.entryTimeError) {
        return this.entryDateTimeRequiredMessage;
      }
      if (this.entryDateError) {
        return this.entryDateRequiredMessage;
      }
      if (this.entryTimeError) {
        return this.entryTimeRequiredMessage;
      }
      return '';
    },

    exitErrorMessage(): VueI18n.TranslateResult | string {
      if (this.apiRequestRunning) return '';
      // @ts-ignore
      if (this.customErrorMessages?.hasOwnProperty('time2')) {
        // @ts-ignore
        return this.customErrorMessages.time2;
      }
      if (this.customErrorMessages?.hasOwnProperty('date2')) {
        // @ts-ignore
        return this.customErrorMessages.date2;
      }
      if (this.exitDateError && this.exitTimeError) {
        return this.exitDateTimeRequiredMessage;
      }
      if (this.exitDateError) {
        return this.exitDateRequiredMessage;
      }
      if (this.exitTimeError) {
        return this.exitTimeRequiredMessage;
      }
      // @ts-ignore
      if (!this.$v.time.exitNotBeforeEntry) {
        return this.exitTimeBeforeEntryErrorMessage;
      }
      return '';
    },

    minExitDate(): Date {
      const currentDate = new Date();

      const formattedCurrentTime = format(
        currentDate,
        DATE_TIME_FORMATS.time_24hr
      );

      return this.entryDate
        ? parseISO(`${this.entryDate}T${formattedCurrentTime}`)
        : currentDate;
    },

    editFormClasses(): string {
      if (this.isTerminalsExists && this.isDiscountFieldEnabled) {
        return 'edit-form__body edit-form__body__with-multi-terminals-and-discount';
      } else if (this.isTerminalsExists) {
        return 'edit-form__body edit-form__body__with-multi-terminals';
      } else if (this.isDiscountFieldEnabled) {
        return 'edit-form__body edit-form__body__with-discount';
      }
      return 'edit-form__body edit-form__body__single-terminal-without-discount';
    },

    isDiscountFieldEnabled(): boolean {
      return (
        getAppVariable(
          'discount_visibility_rules.is_enabled_in_results_page'
        ) && this.$route.path.startsWith(NAMED_ROUTES.search)
      );
    },
  },

  validations() {
    const validationObject = {
      time: {
        exitNotBeforeEntry: () =>
          this.entryDate &&
          this.exitDate &&
          isBefore(
            this.getDate(this.entryDate, this.entryTime, this.timeZone),
            this.getDate(this.exitDate, this.exitTime, this.timeZone)
          ),
      },
      entryTime: { required },
      entryDate: { required },
      exitTime: { required },
      exitDate: { required },
      terminal: {},
    };
    if (this.isTerminalsExists) {
      validationObject.terminal = {
        required,
      };
    }
    return validationObject;
  },

  watch: {
    formData: {
      deep: true,
      handler() {
        this.updateFields();
      },
    },
    dropdownStatuses: {
      deep: true,
      handler(val) {
        if (Object.values(val).some((item) => item) || this.formHasChanges) {
          this.updateDesktopBackdrop(true);
          return;
        }
        this.updateDesktopBackdrop(false);
      },
    },
  },

  created() {
    this.terminals = getAppVariable('terminals');
  },

  mounted() {
    this.updateFields();
  },

  methods: {
    updateFields() {
      this.airport = this.formData.airport;
      this.entryDate = this.formData.entryDate;
      this.entryTime = this.formData.entryTime
        ? { ...this.formData.entryTime }
        : null;
      this.exitDate = this.formData.exitDate;
      this.exitTime = this.formData.exitTime
        ? { ...this.formData.exitTime }
        : null;
      if (this.formData.terminal) {
        this.terminal = this.formData.terminal as string;
      } else if (this.isTerminalsExists) {
        this.terminal = 'dontKnow';
      }
      if (this.formData.discount) {
        this.discount = this.formData.discount as string;
      }
    },
    getDate(date: string, timeObj: Time | null, timezone?: string): Date {
      const dateString = timeObj?.value
        ? `${date} ${timeObj?.value}`
        : `${date}`;
      if (timezone) {
        return utcToZonedTime(parseISO(dateString), timezone);
      }

      return parseISO(dateString);
    },

    onCancelChanges(): void {
      this.$emit('cancel-changes');
    },

    onSubmit(): void {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.$v.$reset();
        this.$emit('update-search', {
          airport: this.airport,
          entryDate: this.entryDate,
          entryTime: this.entryTime,
          exitDate: this.exitDate,
          exitTime: this.exitTime,
          terminal: this.terminal,
          discount: this.discount,
        });
        this.updateDesktopBackdrop(false);
      }
      this.entryTimeHaveChanged = false;
    },

    onEntryDateChange(date: string): void {
      this.entryDate = date;
      this.entryTimeHaveChanged = true;

      const entryTimeIsNotAvailable =
        this.entryTime &&
        isBefore(
          parseISO(`${this.entryDate} ${this.entryTime.value}`),
          new Date()
        );

      if (entryTimeIsNotAvailable) {
        this.entryTime = null;
      }
    },

    onExitDateChange(date: string): void {
      this.exitDate = date;
      this.entryTimeHaveChanged = true;

      const exitTimeIsNotAvailable =
        this.exitTime &&
        isBefore(
          parseISO(`${this.exitDate} ${this.exitTime.value}`),
          new Date()
        );

      if (exitTimeIsNotAvailable) {
        this.exitTime = null;
      }
    },

    onEntryTimeChange(time: Time | null): void {
      this.entryTime = time;
      this.entryTimeHaveChanged = true;
    },

    onExitTimeChange(time: Time | null): void {
      this.exitTime = time;
    },

    updateDesktopBackdrop(value: boolean): void {
      this.$emit('update-desktop-bg', value);
    },
  },
});
