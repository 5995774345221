
import { defineComponent } from 'vue';

// Components
import DotsLoader from '../dots-loader/dots-loader.vue';

export default defineComponent({
  components: {
    DotsLoader,
  },

  props: {
    message: {
      type: String,
      required: false,
      default: '',
    },
  },

  computed: {
    show(): boolean {
      return !!this.message;
    },
  },
});
