
import { defineComponent, PropType } from 'vue';

import type { ExtrasProduct } from '@white-label-types/search-box';

import DropdownWrapper from '../dropdown-wrapper/dropdown-wrapper.vue';

export default defineComponent({
  name: 'SearchBoxExtrasProductInput',
  components: {
    DropdownWrapper,
    IconGlass: () => import('@white-label-icon/icon-glass'),
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    extraProducts: {
      type: Array as PropType<ExtrasProduct[]>,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    }
  },

  data() {
    return {
      dropdownOpen: false,
    };
  },

  computed: {
    selectedExtraProduct() {
      return (
        this.extraProducts.find((i) => i.id === this.value)
        || {
          name: '',
          id: '',
        }
      );
    },
  },
  methods: {
    onSelectExtraProduct(value: ExtrasProduct) {
      this.dropdownOpen = false;
      this.$emit('input', value.id);
    },
  },
});
