
import { defineComponent, PropType } from 'vue';

// Types
import type { Policy } from '@white-label-types/parking-booking';

// Store
import {
  readBookingStatus,
  readIsConfirmed,
  readIsInProgress,
  readIsCancelled,
  readIsPastBooking,
  readProductTypesCategories,
} from '@white-label-store/manage-booking';
import type { ManageBookingState as State } from '@white-label-types/stores';

import auth from '@white-label-helper/mixin-auth';
import multiBasket from '@white-label-helper/mixin-multi-basket';
import BookingStatus from '../booking-status/booking-status.vue';
import BTicketAdditionalInfo from '../b-ticket-additional-info/b-ticket-additional-info.vue';
import BTicket from '../b-ticket/b-ticket.vue';
import ManageBookingShareButton from '../manage-booking-share-button/manage-booking-share-button.vue';
import { getAppHeroProduct } from '@white-label-helper/get-app-hero-product';
import Tooltip from '../tooltip/tooltip.vue';

export default defineComponent({
  name: 'ManageBookingOrderDetails',

  components: {
    BookingStatus,
    BTicketAdditionalInfo,
    BTicket,
    ManageBookingShareButton,
    Tooltip,
    IconButton: () => import('@white-label-icon/icon-button'),
  },

  mixins: [auth, multiBasket],

  props: {
    orderData: {
      type: Object as PropType<State['manageBookingState']>,
      required: true,
    },

    modifications: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    showMultipleOrderItems(): boolean {
      return (this.isMultiBasket || this.crossSellEnabled) && this.orderData.items.length > 1;
    },
    crossSellEnabled() {
      // TODO: Remove condition when ECM_2_CROSS_SELL_POST_PURCHASE is enabled
      return (
        getAppHeroProduct('parking') === 'parking' &&
        this.$launchDarkly.variation('ECM_2_CROSS_SELL_POST_PURCHASE')
      );
    },
    getBookingStatus(): ReturnType<typeof readBookingStatus> {
      return readBookingStatus(this.$store);
    },

    isConfirmed(): ReturnType<typeof readIsConfirmed> {
      return readIsConfirmed(this.$store);
    },

    isInProgress(): ReturnType<typeof readIsInProgress> {
      return readIsInProgress(this.$store);
    },

    isCancelled(): ReturnType<typeof readIsCancelled> {
      return readIsCancelled(this.$store);
    },

    isPastBooking(): ReturnType<typeof readIsPastBooking> {
      return readIsPastBooking(this.$store);
    },

    cancellationPolicies(): Policy[] {
      if (this.orderData.cancellationProtection) {
        return this.orderData.cancellationProtection.cancellation_policies;
      }
      return this.orderData.cancellationPolicies || [];
    },

    subItems(): { title: string }[] {
      if (this.orderData.groups) {
        return this.orderData.groups.map((title: string) => ({ title }));
      }
      return [];
    },

    entryDateTime(): string {
      return this.orderData.entryDateTime || '';
    },
    exitDateTime(): string {
      return this.orderData.exitDateTime || '';
    },
  },

  methods: {
    itemIsParkingProduct(item: State['manageBookingState']['items'][0]): boolean {
      return item.product_code === 'parking';
    },
    isProductTypeIncluded(key: string): boolean {
      return readProductTypesCategories(this.$store)?.includes(key);
    },
    cancelBookingHandler() {
      console.log('cancelBookingHandler');
    },
    amendBookingHandler() {
      console.log('amendBookingHandler');
    },
  },
});
