
import { defineComponent, PropType } from 'vue';
// Types
import type { EntryMethod } from '@white-label-types/parking-booking';
// Constants
import { BARRIER_ACCESS_TYPES } from '@white-label-configuration/constants';

import BTicketGuests from '../b-ticket-guests/b-ticket-guests.vue';
import BTicketPrivateLounge from '../b-ticket-private-lounge-details/b-ticket-private-lounge-details.vue';

export default defineComponent({
  name: 'BTicketAccessInfoGuests',
  components: {
    BTicketGuests,
    BTicketPrivateLounge,
  },
  props: {
    accessInfo: {
      type: [Object, String] as PropType<EntryMethod['entryMethod'] | string>,
      required: true,
    },
    accessInfoFromEntranceInfo: {
      type: Boolean,
      default: false,
    },
    subItems: {
      type: Array,
      default() {
        return [];
      },
    },
    showProviderLogo: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      required: false,
    },
  },
  computed: {
    formattedAccessType(): string {
      return this.accessInfo?.type?.toLowerCase();
    },
    displayImg(): boolean {
      return this.accessInfo?.type === BARRIER_ACCESS_TYPES.QRCODE;
    },
    isPrivateLounge() {
      return (
        this.product &&
        (this.product?.productOption === 'lounge_private' ||
          this.product?.inventory_item?.product_option.code ===
            'lounge_private')
      );
    },
  },
});
