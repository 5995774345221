import { getStoreAccessors } from 'typesafe-vuex';
import { RootState } from '@white-label-types/stores';

export type State = {
    discount: string | null;
}

const state = (): State => ({
    discount: null,
})

const getters = {
    discount: (state: State) => state.discount,
}

const mutations = {
    storeDiscount(state: State, payload: string) {
        state.discount = payload;
    }
}

const { commit, read } = getStoreAccessors<State, RootState>('deepLink');
export const readDiscount = read(getters.discount);
export const commitDiscount = commit(mutations.storeDiscount);

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
