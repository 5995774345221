// Packages
import { datadogRum } from '@datadog/browser-rum';

// Types
import type { BookingOrderBody, UpdateBookingOrderBody } from '@white-label-helper/api-parkings-bookings';

// Constants
import { pusherErrorTimeout } from '@white-label-configuration/constants';

// Helpers
import { handlePaymentRequestOriginal, handlePaymentRequestManageBooking, handleBookingWithoutPayment, handleAmendBookingWithoutPayment } from '@white-label-helper/api-parkings-bookings';
import getPusherChannel from '@white-label-helper/pusher-channel';

const channel = getPusherChannel();

export type PusherEvent = {
  status: string;
  step: string;
  forward: {
    saga_id: string;
    saga_message_id: string;
  };
  payment_client_secret?: string;
}

export function handlePaymentHelper(flowKey: 'original' | 'manageBooking') {
  let eventName: string;

  switch (flowKey) {
    case 'original': {
      eventName = 'OrderCreatingFailedEvent';
      break;
    }
    case 'manageBooking': {
      eventName = 'OrderUpdatingFailedEvent';
      break;
    }
    default: break;
  }

  let channelName: string;
  let timeoutId: NodeJS.Timeout;

  function destroyHandlePayment() {
    channel.unsubscribe(channelName);
  }

  function handleBookingOrderWithoutPayment(cartToken: string, paymentData: BookingOrderBody | UpdateBookingOrderBody) {
    return new Promise<PusherEvent | void>((resolve, reject) => {
      channelName = `order.${cartToken}`;

      if ('original_order_item_id' in paymentData) {
        handleAmendBookingWithoutPayment({ ...paymentData, send_distribution_api_emails: true })
          .then(() => {
            resolve();
          })
          .catch((error: unknown) => {
            clearTimeout(timeoutId);
            reject(error);
          });
      } else {
        handleBookingWithoutPayment({ ...paymentData, send_distribution_api_emails: true })
          .then(() => {
            resolve();
          })
          .catch((error) => {
            clearTimeout(timeoutId);
            reject(error);
          });
      }
    })
      .catch((error: unknown) => {
        datadogRum.addError(`pusher-handle-payment-request~handleBookingOrderWithoutPayment: ${error}`);
      });
  }

  function handlePayment(cartToken: string, paymentData: BookingOrderBody | UpdateBookingOrderBody) {
    return new Promise<PusherEvent>((resolve, reject) => {
      channelName = `order.${cartToken}`;

      const localPusher = channel.subscribe(channelName);

      localPusher.bind('OrderPostAuthorizedStatusEvent', (data: PusherEvent) => {
        resolve(data);
      });

      localPusher.bind('error', (error:unknown) => {
        reject(error);
      });

      localPusher.bind(eventName, () => {
        clearTimeout(timeoutId);
        reject(new Error(eventName));
      });

      if ('original_order_item_id' in paymentData) {
        handlePaymentRequestManageBooking(paymentData)
          .catch((error: unknown) => {
            clearTimeout(timeoutId);
            reject(error);
          });
      } else {
        handlePaymentRequestOriginal(paymentData)
          .catch((error: unknown) => {
            clearTimeout(timeoutId);
            reject(error);
          });
      }

      // Default timeout to reject request if it's frozen
      timeoutId = setTimeout(() => {
        const oneSecond = 1000;
        reject(new Error(`Timed out after ${pusherErrorTimeout / oneSecond} seconds`));
      }, pusherErrorTimeout);
    })
      .catch((error: unknown) => {
        datadogRum.addError(`pusher-handle-payment-request~handlePayment ${error}`);
      });
  }

  return {
    handlePayment,
    destroyHandlePayment,
    handleBookingOrderWithoutPayment,
  };
}
