var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ui-modal-button-wrapper"},[(_vm.typeIsButton)?_c('button',{class:[
      'ui-button button button--full',
      _vm.getTypeOfStateClass,
      _vm.getEnvClass,
      _vm.getDisabledState,
      _vm.getJustifyClass,
    ],attrs:{"id":_vm.id,"type":_vm.type,"disabled":_vm.disabled},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.btnClick.apply(null, arguments)}}},[(_vm.loading)?_c('DotsLoader'):[(_vm.$slots['prependedIcon'])?_c('div',{staticClass:"mr-2"},[_vm._t("prependedIcon")],2):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),(_vm.$slots['appendedIcon'])?_c('div',{staticClass:"ml-2"},[_vm._t("appendedIcon")],2):_vm._e()]],2):_vm._e(),_vm._v(" "),(_vm.typeIsLink)?_c('NuxtLink',{class:[
      'ui-button button button--full',
      _vm.getTypeOfStateClass,
      _vm.getEnvClass,
      _vm.getDisabledState,
      _vm.getJustifyClass,
    ],attrs:{"id":_vm.id,"to":_vm.to,"type-of-element":"button","button-type":"primary"}},[(_vm.loading)?_c('DotsLoader'):[(_vm.$slots['prependedIcon'])?_c('div',{staticClass:"mr-2"},[_vm._t("prependedIcon")],2):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),(_vm.$slots['appendedIcon'])?_c('div',{staticClass:"ml-2"},[_vm._t("appendIcon")],2):_vm._e()]],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }