
// Packages
import { PropType, defineComponent } from 'vue';

import IconBus from '@white-label-icon/icon-bus';

import IconWrapper from '../icon-wrapper/icon-wrapper.vue';

// Helpers
import { getAppVariable } from '@white-label-helper/get-app-variable';

export default defineComponent({
  name: 'SearchParkingTransfer',

  components: {
    IconBus,
    IconWalk: () => import('@white-label-icon/icon-walk'),
    IconWrapper,
  },

  props: {
    transfer: {
      type: Object as PropType<{
        code: string;
        distance: string;
        name: string;
        time: string;
      }>,
      required: true,
    },
  },

  computed: {
    transferToTerminal(): boolean {
      return (
        this.transfer.code === 'shuttle' || this.transfer.code === 'transfer'
      );
    },

    shortWarkToTerminal(): boolean {
      return this.transfer.code === 'walk';
    },

    transferText(): string {
      if (this.transferToTerminal) {
        return 'searchParkingTransfer.transferToTerminal';
      }
      if (this.shortWarkToTerminal) {
        return 'searchParkingTransfer.walkToTerminal';
      }
      return '';
    },

    showDistanceInMiles(): boolean {
      return getAppVariable('poi.country.code') === 'US' || getAppVariable('poi.country.code') === 'GB';
    },

    distanceInMiles(): string {
      return (Number(this.transfer.distance) / 1.609).toFixed(2);
    },
  },
});
