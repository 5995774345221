
import { formatPrice } from '@white-label-helper/helper-payment';
import { defineComponent } from 'vue';
import { getAppVariable } from '@white-label-helper/get-app-variable';

export default defineComponent({
  name: 'ProductSummaryPaymentGuests',
  filters: {
    formatPrice
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    taxesInclusive() {
      return getAppVariable('is_tax_inclusive');
    },
    feesInclusive() {
      return getAppVariable('is_tax_inclusive');
    },
    guestPrices() {
      return this.product.inventory_option.sub_options.map(guest => {
        console.group('Guest: ' + guest.details.name_formatted);
        let guestPrice = {
          quantity: guest.totals.quantity,
          name_formatted: guest.details.name_formatted,
          total: this.guestTotal(guest),
          total_formatted: formatPrice(this.guestTotal(guest)),
          discount: {
            amount: 0,
            amount_formatted: '',
            description: '',
          },
        };

        if (guest.discount !== null) {
          guestPrice.discount = guest.discount;
          guestPrice.discount.amount_formatted = formatPrice(guestPrice.discount.amount);
        }
        console.groupEnd();
        return guestPrice;
      })
    }
  },

  methods: {
    guestTotal(guest) {
      let key = 'total';
      let totals = guest.totals;
      if (guest.discount !== null) {
        totals = guest.original_amounts.totals;
      }
      return totals[key];
    }
  }
});

