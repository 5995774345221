
import { defineComponent, PropType } from 'vue';
import type VueI18n from 'vue-i18n/types';
import { BOOKING_STATUSES } from '@white-label-configuration/constants';

type Config = {
  wrapperClass: string,
  circleClass?: string,
  label: VueI18n.TranslateResult
}

type BookingStatues = keyof typeof BOOKING_STATUSES

export default defineComponent({
  props: {
    status: {
      type: String as PropType<BookingStatues>,
      required: true,
    },
    small: {
      type: Boolean,
    },
    isMA: {
      type: Boolean,
    },
  },

  computed: {
    localConfig (): Config | null {
      return this.isMA ? this.configMA : this.config;
    },

    config (): Config | null {
      switch (this.status) {
        case BOOKING_STATUSES.CANCELLED: return {
          wrapperClass: 'bg-alert-8',
          circleClass: 'bg-alert-100',
          label: this.$t('UI.bookingStatuses.cancelled'),
        };
        case BOOKING_STATUSES.CONFIRMED: return {
          wrapperClass: 'bg-success-8',
          circleClass: 'bg-success-100',
          label: this.$t('UI.bookingStatuses.confirmed'),
        };
        case BOOKING_STATUSES.IN_PROGRESS: return {
          wrapperClass: 'bg-highlight-8',
          circleClass: 'bg-highlight-100',
          label: this.$t('UI.bookingStatuses.inProgress'),
        };
        case BOOKING_STATUSES.PAST_BOOKING: return {
          wrapperClass: 'bg-info-8',
          circleClass: 'bg-info-100',
          label: this.$t('UI.bookingStatuses.pastBooking'),
        };
        default: return null;
      }
    },
    configMA (): Config | null {
      switch (this.status) {
        case BOOKING_STATUSES.CANCELLED: return {
          wrapperClass: 'bg-alert-8',
          label: this.$t('UI.bookingStatuses.cancelled'),
        };
        case BOOKING_STATUSES.IN_PROGRESS: return {
          wrapperClass: 'bg-success-8',
          circleClass: 'bg-success-100',
          label: this.$t('UI.bookingStatuses.live'),
        };
        case BOOKING_STATUSES.PAST_BOOKING: return {
          wrapperClass: 'bg-mag-elements-dividers',
          label: this.$t('UI.bookingStatuses.pastBooking'),
        };
        default: return null;
      }
    },
  },
});
