
import { defineComponent } from 'vue';
import { required } from '@white-label-helper/vuelidate';
import type VueI18n from 'vue-i18n';
import type { Time, Terminal } from '@white-label-types/component';
import {
  format,
  add,
  differenceInMinutes,
  isEqual,
  isAfter,
  isBefore,
  isDate,
  DATE_TIME_FORMATS,
  utcToZonedTime,
  parseISO,
  guessTimezone,
} from '@white-label-helper/date-utilities';
import {
  PARKINGS_TIMEPICKER_STEPS,
  SEARCH_BOX_SESSION_DURATION,
  NAMED_ROUTES,
  PARTNER_TYPES_CODES,
} from '@white-label-configuration/constants';
import { trackCheckoutStep } from '@white-label-helper/helper-tracking';
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { readTimestamp, readFormData } from '@white-label-store/search-box';
import type {
  ParkingFormData,
  QueryParams,
} from '@white-label-types/search-box';
import { Partners } from '@white-label-types/partners';
import { isBookingPortal } from '@white-label-helper/is-booking-portal';

import IconCheck from '@white-label-icon/icon-check';
import DropdownWrapper from '../dropdown-wrapper/dropdown-wrapper.vue';
import DateTimePicker from '../date-time-picker/date-time-picker.vue';
import IconMessage from '../icon-message/icon-message.vue';
import AirportSelect from '../airport-select/airport-select.vue';
import TextField from '../text-field/text-field.vue';

interface DateTimePickerHTMLElement extends HTMLElement {
  dateDropdownOpened?: boolean;
  timeDropdownOpened?: boolean;
}

export default defineComponent({
  name: 'SearchBoxParkings',

  components: {
    DropdownWrapper,
    DateTimePicker,
    IconMessage,
    AirportSelect,
    TextField,
    IconCheck,
  },

  data() {
    return {
      PARKINGS_TIMEPICKER_STEPS,
      formData: {
        airport: '',
        entryDate: '',
        entryTime: null,
        exitDate: '',
        exitTime: null,
        terminal: '',
        discount: null,
      } as ParkingFormData,
      maxDatePickerDate: add(new Date(), { years: 2 }) as Date,
      terminalOpened: false,
      discountFromQuery: null as null | string,
      exitDateBeforeEntryDate: false,
    };
  },

  validations() {
    const validationObject = {
      formData: {
        entryDate: { required },
        entryTime: { required },
        exitDate: { required },
        exitTime: { required },
        terminal: {},
        airport: {},
      },
    };

    if (this.isMultiAirportPartner) {
      validationObject.formData.airport = { required };
    }

    if (
      (this.isSingleAirportTerminalsExist && !this.isMultiAirportPartner) ||
      (this.isMultiAirportPartner && this.isMultiAirportTerminalsExist)
    ) {
      validationObject.formData.terminal = { required };
    }

    return validationObject;
  },

  computed: {
    pageTitle(): string | VueI18n.TranslateResult {
      // TODO: Remove condition when feature flag 'ECOM-1233' is no longer needed
      if (
        this.$launchDarkly.variation('ECOM_1233_WL_MULTI_LANGUAGE') === true
      ) {
        return getAppVariable(`page_title[${this.$i18n.locale}]`);
      }
      return this.$t('searchBox.title');
    },

    isMultiAirportPartner(): boolean {
      if (isBookingPortal) {
        return Array.isArray(this.airports) && this.airports.length > 1;
      }
      return getAppVariable('partner_type_code') === PARTNER_TYPES_CODES.WL_ONLY;
    },

    airports(): Partners['pois'] {
      return getAppVariable('pois');
    },

    showSingleAirportTerminalSelect(): boolean {
      return this.isSingleAirportTerminalsExist && !this.isMultiAirportPartner;
    },

    showMultiAirportTerminalSelect(): boolean {
      return (
        this.isMultiAirportPartner &&
        this.formData.airport &&
        this.isMultiAirportTerminalsExist
      );
    },

    partnerTimeZone(): string {
      return getAppVariable('poi.timezone') || guessTimezone();
    },

    singleAirportTerminals(): Terminal[] {
      return getAppVariable('terminals');
    },

    singleAirportTerminalsWithDefault(): Terminal[] {
      return [
        ...this.singleAirportTerminals,
        {
          name: this.$t('searchBox.dontKnowOption'),
          id: 'dontKnow',
        },
      ];
    },

    multiAirportTerminals(): Terminal[] {
      const allSelectedTerminals: Terminal[] = getAppVariable('terminals');
      const airportTerminals = this.airports.find((item) => item.code === this.formData.airport)?.terminals;

      if (airportTerminals) {
        return airportTerminals.filter((t1) => allSelectedTerminals.some((t2) => t1.id === t2.id));
      } else {
        return [];
      }
    },

    multiAirportTerminalsWithDefault(): Terminal[] {
      return [
        ...this.multiAirportTerminals,
        {
          name: this.$t('searchBox.dontKnowOption'),
          id: 'dontKnow',
        },
      ];
    },

    computedSingleAirportTerminal: {
      get(): Terminal {
        return (
          this.singleAirportTerminalsWithDefault.find(
            (i) => i.id.toString() === this.formData.terminal?.toString()
          ) || {
            name: '',
            id: '',
          }
        );
      },
      set(val: Terminal): void {
        this.formData.terminal = val.id.toString();
      },
    },

    computedMultiAirportTerminal: {
      get(): Terminal {
        return (
          this.multiAirportTerminalsWithDefault.find(
            (i) => i.id.toString() === this.formData.terminal?.toString()
          ) || {
            name: '',
            id: '',
          }
        );
      },
      set(val: Terminal): void {
        this.formData.terminal = val.id.toString();

        // Automatically open entryDate field if empty
        if (!this.formData.entryDate && this.$refs.entryDate) {
          (
            this.$refs.entryDate as DateTimePickerHTMLElement
          ).dateDropdownOpened = true;
        }
      },
    },

    isSingleAirportTerminalsExist(): boolean {
      return this.singleAirportTerminals?.length > 1;
    },

    isMultiAirportTerminalsExist(): boolean {
      return this.multiAirportTerminals?.length > 1;
    },

    minExitDate(): Date {
      const currentDate = new Date();

      const formattedCurrentTime = format(
        currentDate,
        DATE_TIME_FORMATS.time_24hr
      );

      return this.formData.entryDate
        ? parseISO(`${this.formData.entryDate}T${formattedCurrentTime}`)
        : currentDate;
    },

    minDatePickerDate(): Date {
      const currentDateInPartnerTimeZone = utcToZonedTime(
        new Date(),
        this.partnerTimeZone
      );

      if (currentDateInPartnerTimeZone.getHours() >= 23) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.formData.entryDate = '';
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.formData.entryTime = null;
        return add(currentDateInPartnerTimeZone, { days: 1 });
      }
      return currentDateInPartnerTimeZone;
    },

    allDateTimeFieldsFilled(): boolean {
      const propertiesToKeep = [
        'entryDate',
        'entryTime',
        'exitDate',
        'exitTime',
      ];
      const dateTimeFields = propertiesToKeep.reduce((result, key) => {
        if (this.formData.hasOwnProperty(key)) {
          result[key] = this.formData[key];
        }
        return result;
      }, {});
      return !Object.values(dateTimeFields).some((item) => !item);
    },

    validationError(): boolean {
      return (
        this.$v.$error ||
        this.exitDateBeforeEntryDate ||
        this.exitTimeIsBeforeEntryTime ||
        this.entryDateBeforeCurrentDate
      );
    },
    exitTimeIsBeforeEntryTime(): boolean {
      const entryDate = parseISO(
        `${this.formData.entryDate} ${this.formData.entryTime?.value || ''}`
      );
      const exitDate = parseISO(
        `${this.formData.exitDate} ${this.formData.exitTime?.value || ''}`
      );
      const exitTimeIsSameOfBeforeAsEntry =
        isAfter(entryDate, exitDate) || isEqual(entryDate, exitDate);
      return this.allDateTimeFieldsFilled && exitTimeIsSameOfBeforeAsEntry;
    },
    entryDateBeforeCurrentDate(): boolean {
      const entryDate = parseISO(
        `${this.formData.entryDate} ${this.formData.entryTime?.value || ''}`
      );
      const currentDate = utcToZonedTime(new Date(), this.partnerTimeZone);

      const entryDateBeforeCurrentDate =
        isBefore(entryDate, currentDate) || isEqual(entryDate, currentDate);

      return this.allDateTimeFieldsFilled && entryDateBeforeCurrentDate;
    },
    displayEntryDateTimeCommonError(): boolean {
      return this.entryDateBeforeCurrentDate;
    },

    displayExitDateTimeCommonError(): boolean {
      return this.exitDateBeforeEntryDate || this.exitTimeIsBeforeEntryTime;
    },
    terminalError(): boolean {
      // @ts-ignore
      return !!this.$v.formData?.terminal?.$error;
    },

    airportError(): string {
      // @ts-ignore
      return this.$v.formData?.airport?.$error;
    },

    entryDateError(): boolean {
      // @ts-ignore
      return !!this.$v.formData?.entryDate?.$error;
    },

    entryTimeError(): boolean {
      // @ts-ignore
      return !!this.$v.formData?.entryTime?.$error;
    },

    entryDateTimeError(): VueI18n.TranslateResult {
      if (this.entryDateError && this.entryTimeError) {
        return this.$t('validationError.entryDateTime.dateTimeError');
      }
      if (this.entryDateError) {
        return this.$t('validationError.entryDateTime.dateError');
      }
      if (this.entryTimeError) {
        return this.$t('validationError.entryDateTime.timeError');
      }
      if (this.entryDateBeforeCurrentDate) {
        return this.$t('validationError.chooseFutureTime');
      }
      return '';
    },

    exitDateError(): boolean {
      // @ts-ignore
      return !!this.$v.formData?.exitDate?.$error;
    },

    exitTimeError(): boolean {
      // @ts-ignore
      return !!this.$v.formData?.exitTime?.$error;
    },

    exitDateTimeError(): VueI18n.TranslateResult {
      if (this.exitDateError && this.exitTimeError) {
        return this.$t('validationError.exitDateTime.dateTimeError');
      }
      if (this.exitDateError) {
        return this.$t('validationError.exitDateTime.dateError');
      }
      if (this.exitTimeError) {
        return this.$t('validationError.exitDateTime.timeError');
      }
      if (this.exitDateBeforeEntryDate) {
        return this.$t('validationError.exitDateBeforeEntryDate');
      }
      if (this.exitTimeIsBeforeEntryTime) {
        return this.$t('validationError.exitTimeIsBeforeEntryTime');
      }
      return '';
    },

    /**
     * A selection of classes to use for multi airport partner
     * @returns - The class
     */
    multiAirportClasses(): string {
      if (this.isMultiAirportTerminalsExist && this.isDiscountFieldEnabled) {
        return 'search-box search-box__multi-airport-with-multi-terminals-and-discount';
      }

      if (this.isMultiAirportTerminalsExist) {
        return 'search-box search-box__multi-airport-with-multi-terminals';
      }

      if (this.isDiscountFieldEnabled) {
        return 'search-box search-box__multi-airport-with-discount';
      }

      return 'search-box search-box__multi-airport';
    },

    /**
     * A selection of classes to use for single airport partner
     */
    singleAirportClasses(): string {
      if (this.isDiscountFieldEnabled) {
        return 'search-box search-box__single-airport-with-multi-terminals-and-discount';
      }

      return 'search-box search-box__single-airport-with-multi-terminals';
    },

    searchBoxClasses(): string {
      if (this.isMultiAirportPartner) {
        return this.multiAirportClasses;
      }

      if (this.isSingleAirportTerminalsExist) {
        return this.singleAirportClasses;
      }

      if (this.isDiscountFieldEnabled) {
        return 'search-box search-box__single-airport-with-discount';
      }

      return 'search-box search-box__single-airport';
    },

    isDiscountFieldEnabled(): boolean {
      return getAppVariable(
        'discount_visibility_rules.is_enabled_in_landing_page'
      );
    },
  },

  watch: {
    formData: {
      deep: true,
      handler(formData) {
        this.$store.commit('searchBox/storeData', {
          formData,
          timestamp: new Date(),
        });
      },
    },
    'formData.airport': function(val) {
      if (this.multiAirportTerminals.length && !this.formData.terminal) {
        this.terminalOpened = true;
      } else if (!this.formData.entryDate && this.$refs.entryDate) {
          (
            this.$refs.entryDate as DateTimePickerHTMLElement
          ).dateDropdownOpened = true;
        }
    },
  },

  mounted() {
    this.loadStoredData();

    if (typeof this.$route.query?.discount === 'string') {
      this.formData.discount = this.$route.query.discount;
      this.discountFromQuery = this.$route.query.discount;
    }
  },

  methods: {
    loadStoredData(): void {
      const timestamp = readTimestamp(this.$store);

      const formData = readFormData(this.$store);

      if (formData.discount) {
        this.discountFromQuery = formData.discount;
      }

      const timestampDate: Date = (
        isDate(timestamp) ? timestamp : parseISO(timestamp as string)
      ) as Date;
      const sessionDuration = differenceInMinutes(new Date(), timestampDate);

      if (formData && sessionDuration < SEARCH_BOX_SESSION_DURATION) {
        this.formData = { ...formData };
      }
    },

    onEntryDateChange(date: string): void {
      this.formData.entryDate = date;

      const exitDateBeforeEntryDate =
        this.formData.exitDate &&
        isAfter(parseISO(date), parseISO(this.formData.exitDate));

      const entryTimeIsNotAvailable =
        this.formData.entryTime &&
        isBefore(
          parseISO(`${date} ${this.formData.entryTime.value}`),
          new Date()
        );

      if (exitDateBeforeEntryDate) {
        this.formData.exitDate = '';
        this.exitDateBeforeEntryDate = true;
      }
      if (entryTimeIsNotAvailable) {
        this.formData.entryTime = null;
      }

      // Automatically open entryTime field if empty
      if (!this.formData.entryTime && this.$refs.entryDate) {
        (this.$refs.entryDate as DateTimePickerHTMLElement).timeDropdownOpened =
          true;
      }
    },

    onEntryTimeChange(time: Time | null): void {
      this.formData.entryTime = time;

      // Automatically open exitDate field if empty
      if (!this.formData.exitDate && this.$refs.exitDate) {
        (this.$refs.exitDate as DateTimePickerHTMLElement).dateDropdownOpened =
          true;
      }
    },

    onExitDateChange(date: string): void {
      this.formData.exitDate = date;
      this.exitDateBeforeEntryDate = false;

      const exitTimeIsNotAvailable =
        this.formData.exitTime &&
        isBefore(
          parseISO(`${date} ${this.formData.exitTime.value}`),
          new Date()
        );

      if (exitTimeIsNotAvailable) {
        this.formData.exitTime = null;
      }

      // Automatically open exitTime field if empty
      if (!this.formData.exitTime && this.$refs.exitDate) {
        (this.$refs.exitDate as DateTimePickerHTMLElement).timeDropdownOpened =
          true;
      }
    },

    onExitTimeChange(time: Time | null): void {
      this.formData.exitTime = time;

      // Automatically open terminal field if empty
      if (!this.formData.terminal && !this.isMultiAirportPartner) {
        this.terminalOpened = true;
      }
    },

    submitForm(): void {
      // @ts-ignore
      this.$v.formData.$touch();

      if (this.isMultiAirportPartner) {
        if (this.multiAirportTerminals.length === 1) {
          // If we have only one terminal we take it by default
          this.formData.terminal = this.multiAirportTerminals[0].id.toString();
        }
        if (this.multiAirportTerminals.length === 0) {
          // If there are no terminals, we take the first item from 'multiAirportTerminalsWithDefault'
          // this variable always has at least one element - 'Don't know'
          this.formData.terminal =
            this.multiAirportTerminalsWithDefault[0].id.toString();
        }
      } else {
        if (this.singleAirportTerminals.length === 1) {
          // If we have only one terminal we take it by default
          this.formData.terminal = this.singleAirportTerminals[0].id.toString();
        }
        if (this.singleAirportTerminals.length === 0) {
          // If there are no terminals, we take the first item from 'singleAirportTerminalsWithDefault'
          // this variable always has at least one element - 'Don't know'
          this.formData.terminal =
            this.singleAirportTerminalsWithDefault[0].id.toString();
        }
      }

      if (!this.validationError) {
        const terminal =
          typeof this.formData.terminal === 'string'
            ? this.formData.terminal
            : '';

        const queryParams: QueryParams = {
          entryDate: this.formData.entryDate || '',
          entryTime: this.formData.entryTime?.value || '',
          exitDate: this.formData.exitDate || '',
          exitTime: this.formData.exitTime?.value || '',
        };

        if (this.formData.discount) {
          queryParams.discount = this.formData.discount;
        }
        if (this.formData.airport) {
          queryParams.airport = this.formData.airport;
        }
        if (terminal) {
          queryParams.terminal = terminal;
        }

        trackCheckoutStep(this.$gtm, 1, 'Select dates');

        this.$router.push({
          path: NAMED_ROUTES.search,
          query: queryParams,
        });
      }
    },
  },
});
