
import { defineComponent, PropType } from 'vue';
import { required, requiredIf } from '@white-label-helper/vuelidate';
import type VueI18n from 'vue-i18n';
import { datadogRum } from '@datadog/browser-rum';
import type { Time } from '@white-label-types/time-picker';
import type {
  Terminal,
  Airport,
  AirlineVisibilityRules,
  BaggageVisibilityRules,
  SearchCriteriaConfig,
  BaggageType,
  LoungeFormData,
} from '@white-label-types/search-box';
import type {
  CustomersOptions,
  CustomersValues,
} from '@white-label-types/customers';

import {
  add,
  isBefore,
  parseISO,
  guessTimezone,
} from '@white-label-helper/date-utilities';
import { convertTimeStringToObject } from '@white-label-helper/time-helpers';
import {
  LOUNGES_TIMEPICKER_STEPS,
  ROUTE_NAMES,
  BAGGAGE_SELECTOR_OPTIONS,
} from '@white-label-configuration/constants';
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { gaDataLayerLounges } from '@white-label-helper/ga-data-layer';
import { getAppHeroProduct } from '@white-label-helper/get-app-hero-product';
import {
  readContinueClicked,
  commitContinueClicked,
} from '@white-label-store/multi-basket';

import DropdownWrapper from '../dropdown-wrapper/dropdown-wrapper.vue';
import DateTimePicker from '../date-time-picker/date-time-picker.vue';
import IconMessage from '../icon-message/icon-message.vue';
import CustomersSelector from '../customers-selector/customers-selector.vue';
import SearchBoxExtrasProductInput from '../search-box-extras-product-input/search-box-extras-product-input.vue';
import { trackAction } from '@white-label-helper/helper-tracking';

interface DateTimePickerHTMLElement extends HTMLElement {
  $el: HTMLElement;
  dateDropdownOpened?: boolean;
  timeDropdownOpened?: boolean;
}

export default defineComponent({
  name: 'SearchBoxLounges',
  components: {
    IconCheck: () => import('@white-label-icon/icon-check'),
    IconUser: () => import('@white-label-icon/icon-user'),
    IconMessage,
    IconTerminal: () => import('@white-label-icon/icon-terminal'),
    IconSwap: () => import('@white-label-icon/icon-swap'),
    DropdownWrapper,
    DateTimePicker,
    CustomersSelector,
    SearchBoxExtrasProductInput,
  },
  props: {
    submitLabel: {
      type: String as PropType<string>,
      default: 'searchBox.buttonLabel',
    },
    disabledParams: {
      type: Object as PropType<{ [key: string]: any }>,
      required: false,
      default() {
        return {} as { [key: string]: any };
      },
    },
    customErrorMessages: {
      type: Object,
      required: false,
      default: () => {},
    },
    apiRequestRunning: {
      type: Boolean,
      default: false,
    },
    enableSwap: {
      type: Boolean,
      default: false,
    },
    enableExtrasProductInput: {
      type: Boolean,
      default: false,
    },
    showAgeRanges: {
      type: Boolean,
      default: true,
    },
    minEntryDate: {
      type: [String, Date] as PropType<string | Date>,
      default: () => new Date(),
    },
  },
  data() {
    return {
      customersData: {
        adult: 0,
        child: 0,
        senior: 0,
        infant: 0,
      },
      customersError: '',
      terminals: [] as Terminal[],
      searchCriteriaConfig: {} as SearchCriteriaConfig,
      primaryLanguage: '',
      customerGroups: {},
      airlines: [],
      airlinesVisibilityRules: {} as AirlineVisibilityRules,
      baggageVisibilityRules: {} as BaggageVisibilityRules,
      LOUNGES_TIMEPICKER_STEPS,
      formData: {
        airport: '',
        entryDate: '',
        entryTime: null,
        exitDate: '',
        exitTime: null,
        terminal: '',
        airline: '',
        customers: {} as CustomersValues,
        discount: '',
        swapped: false,
        baggage_type: '',
        extrasProduct: '1',
      } as unknown as LoungeFormData & { swapped: boolean },
      maxDatePickerDate: add(new Date(), { years: 2 }) as Date,
      exitDateOpened: false,
      terminalOpened: false,
      arilineOpened: false,
      customersOpened: false,
      entryTimeHaveChanged: false,
      airportOpened: false,
      isEntryDateTimePickerOpen: false,
      isExitDateTimePickerOpen: false,
      baggageOpened: false,
    };
  },

  validations(): object {
    const validationObject = {
      formData: {
        airport: {},
        entryDate: { required },
        entryTime: { required },
        exitTime: {},
        exitDate: {},
        terminal: {},
        airline: { required: requiredIf(() => this.showAirlinesSelector) },
        baggage_type: { required: requiredIf(() => this.showBaggageSelector) },
        customers: {
          validation: (value: {
            child: number;
            infant: number;
            adult: number;
            senior: number;
          }) => {
            this.customersError = '';
            if (!this.isCustomerGroupsExists) return true;
            if (!Object.values(value).some((item) => item > 0)) {
              this.customersError = 'Please select number of guests';
              return false;
            }
            if (
              value.child + value.infant > 0 &&
              value.senior + value.adult === 0
            ) {
              this.customersError = 'At least 1 adult required';
              return false;
            }
            return true;
          },
        },
      },
    };

    // at least one date-time pair required
    if (!this.hasOneDatePicker) {
      validationObject.formData.exitDate = {
        required: requiredIf(
          () =>
            (!this.formData.entryDate && !this.formData.entryTime?.value) ||
            this.formData.exitTime?.value
        ),
      };
      validationObject.formData.exitTime = {
        required: requiredIf(
          () =>
            (!this.formData.entryDate && !this.formData.entryTime?.value) ||
            this.formData.exitDate
        ),
      };
      validationObject.formData.entryDate = {
        required: requiredIf(
          () =>
            (!this.formData.exitDate && !this.formData.exitTime?.value) ||
            this.formData.entryTime?.value
        ),
      };
      validationObject.formData.entryTime = {
        required: requiredIf(
          () =>
            (!this.formData.exitDate && !this.formData.exitTime?.value) ||
            this.formData.entryDate
        ),
      };
    }

    if (this.isMultiAirportPartner) {
      validationObject.formData.airport = { required };
      validationObject.formData.terminal = this.isMultiAirportTerminalsExist
        ? { required }
        : {};
    } else if (this.isTerminalsExists) {
      validationObject.formData.terminal = { required };
    }

    return validationObject;
  },
  computed: {
    submitFromOutside(): ReturnType<typeof readContinueClicked> {
      return (
        readContinueClicked(this.$store) &&
        this.$route.name === ROUTE_NAMES.home
      );
    },
    discountFromQuery(): string {
      return this.$route.query?.discount as string;
    },

    partnerTimeZone(): string {
      return getAppVariable('poi.timezone') || guessTimezone();
    },

    terminalsWithDefault(): Terminal[] {
      return [
        ...this.terminals,
        {
          name: this.$t('searchBox.dontKnowOption'),
          id: 'dontKnow',
        },
      ];
    },

    baggageTypes(): BaggageType[] {
      return Object.keys(BAGGAGE_SELECTOR_OPTIONS).map((option) => ({
        id: option,
        name: this.$t(`components.baggageSelector.${option}`) as string,
      }));
    },

    isDisabledSearch(): boolean {
      return (
        JSON.stringify(this.disabledParams) === JSON.stringify(this.formData)
      );
    },

    computedTerminal: {
      get(): Terminal {
        return (
          this.terminalsWithDefault.find(
            (i) => i.id.toString() === this.formData.terminal?.toString()
          ) || {
            name: '',
            id: '',
          }
        );
      },

      set(val: Terminal): void {
        this.formData.terminal = val.id.toString();
      },
    },

    computedAirlines: {
      get() {
        return (
          this.airlines.find(
            (i) => i.iata.toString() === this.formData.airline?.toString()
          ) || {
            iata: '',
            name: '',
          }
        );
      },

      set(val: Terminal): void {
        this.formData.airline = val.iata.toString();
      },
    },

    computedBaggageTypes: {
      get() {
        return (
          this.baggageTypes.find(
            (i: BaggageType) => i.id === this.formData.baggage_type
          ) || {
            id: '',
            name: '',
          }
        );
      },

      set(val: BaggageType) {
        this.formData.baggage_type = val.id;
      },
    },

    computedCustomers(): { name: string; id: string } {
      const { adult, senior, infant, child } = this.customersData;
      const label = [];
      if (senior) label.push(`${senior} Senior${senior > 1 ? 's' : ''}`);
      if (adult) label.push(`${adult} Adult${adult > 1 ? 's' : ''}`);
      if (child) label.push(`${child} ${child > 1 ? 'Children' : 'Child'}`);
      if (infant) label.push(`${infant} Infant${infant > 1 ? 's' : ''}`);
      Object.assign(this.formData, { customers: { ...this.customersData } });
      return label.length
        ? { id: 'id', name: label.join(', ') }
        : {
            name: '',
            id: '',
          };
    },

    isSearchConfigExists() {
      return (
        this.searchCriteriaConfig &&
        Object.keys(this.searchCriteriaConfig).length > 0
      );
    },

    hasOneDatePicker() {
      if (!this.isSearchConfigExists) {
        return true;
      }

      return this.searchCriteriaConfig.picker_type === 'one';
    },

    isTerminalsExists(): boolean {
      return this.terminals?.length > 1;
    },

    isCustomerGroupsExists(): boolean {
      return this.customerGroups && Object.keys(this.customerGroups).length > 1;
    },

    allDateTimeFieldsFilled(): boolean {
      const { ...rest } = this.formData;
      return !Object.values(rest).some((item) => !item);
    },

    terminalError(): boolean {
      // @ts-ignore
      return !!this.$v.formData?.terminal?.$error;
    },

    airlineError(): boolean {
      // @ts-ignore
      return !!this.$v.formData?.airline?.$error;
    },

    baggageError(): boolean {
      // @ts-ignore
      return !!this.$v.formData?.baggage_type?.$error;
    },

    entryDateError(): boolean {
      if (this.apiRequestRunning) return false;
      if (
        !this.customErrorMessages?.entryDate?.length &&
        !this.$v.formData?.entryDate?.$error
      )
        return false;
      if (this.customErrorMessages?.entryDate) {
        if (this.entryTimeHaveChanged) return false;
        return true;
      }
      return !!this.$v.formData?.entryDate?.$error;
    },

    entryTimeError(): boolean {
      if (this.apiRequestRunning) return false;
      if (
        !this.customErrorMessages?.entryTime?.length &&
        !this.$v.formData?.entryTime?.$error
      )
        return false;
      // @ts-ignore

      if (this.customErrorMessages?.entryTime) {
        if (this.entryTimeHaveChanged) return false;
        return true;
      }
      // @ts-ignore
      return !!this.$v.formData?.entryTime?.$error;
    },

    entryDateTimeError(): VueI18n.TranslateResult {
      if (this.apiRequestRunning) return '';
      if (this.customErrorMessages?.entryDate.length) {
        return this.customErrorMessages.entryDate;
      }
      if (this.customErrorMessages?.entryTime.length) {
        return this.customErrorMessages.entryTime;
      }
      if (this.entryDateError && this.entryTimeError) {
        return this.$t('validationError.entryDateTime.dateTimeError');
      }
      if (this.entryDateError) {
        return this.$t('validationError.entryDateTime.dateError');
      }
      if (this.entryTimeError) {
        return this.$t('validationError.entryDateTime.timeError');
      }
      // if (this.entryDateBeforeCurrentDate) {
      //   return this.$t('validationError.chooseFutureTime');
      // }
      return '';
    },

    exitDateError(): boolean {
      // @ts-ignore
      return !!this.$v.formData?.exitDate?.$error;
    },

    exitTimeError(): boolean {
      // @ts-ignore
      return !!this.$v.formData?.exitTime?.$error;
    },

    exitDateTimeError(): VueI18n.TranslateResult {
      if (this.exitDateError && this.exitTimeError) {
        return this.$t('validationError.exitDateTime.dateTimeError');
      }
      if (this.exitDateError) {
        return this.$t('validationError.exitDateTime.dateError');
      }
      if (this.exitTimeError) {
        return this.$t('validationError.exitDateTime.timeError');
      }
      return '';
    },

    formDataKey(): string {
      return JSON.stringify(this.formData);
    },

    // Multi Airport start
    airports(): Airport[] {
      return getAppVariable('pois');
    },

    isMultiAirportPartner(): boolean {
      return this.airports?.length > 1;
    },

    airportError(): string {
      // @ts-ignore
      return this.$v.formData?.airport?.$error;
    },

    computedAirport: {
      get(): Airport {
        return (
          this.airports?.find(
            (i) => {
              if (this.formData && this.formData.airport) {
                return i.code.toString() === this.formData.airport.toString();
              }
              return false;
            }
          ) || {
            name: '',
            id: '',
          }
        );
      },
      set(val: Airport): void {
        this.formData.airport = val.code.toString();
      },
    },

    multiAirportTerminals(): Terminal[] {
      return (
        this.airports.find(
          (item) => item.code.toString() === this.formData.airport
        )?.terminals || []
      );
    },

    isMultiAirportTerminalsExist(): boolean {
      return this.multiAirportTerminals?.length;
    },

    showMultiAirportTerminalSelect(): boolean {
      return (
        this.isMultiAirportPartner &&
        this.formData.airport &&
        this.isMultiAirportTerminalsExist
      );
    },

    multiAirportTerminalsWithDefault(): Terminal[] {
      return [
        ...this.multiAirportTerminals,
        {
          name: this.$t('searchBox.dontKnowOption'),
          id: 'dontKnow',
        },
      ];
    },

    computedMultiAirportTerminal: {
      get(): Terminal {
        return (
          this.multiAirportTerminalsWithDefault.find(
            (i) => i.id.toString() === this.formData.terminal?.toString()
          ) || {
            name: '',
            id: '',
          }
        );
      },
      set(val: Terminal): void {
        this.formData.terminal = val.id.toString();
      },
    },

    isHomePage() {
      return this.$nuxt.context.route.path === '/';
    },

    showMultiAirportsDropdown() {
      return this.isMultiAirportPartner && this.isHomePage;
    },
    // Multi Airport end

    showAirlinesSelector(): boolean {
      return (
        this.airlinesVisibilityRules?.is_enabled_in_landing_page &&
        this.airlines &&
        this.airlines.length > 0
      );
    },

    showBaggageSelector(): boolean {
      return this.baggageVisibilityRules?.is_enabled_in_landing_page;
    },
    heroProduct: () => {
      return getAppHeroProduct('parking');
    },
    isCustomersEmpty() {
      return (
        Object.keys(this.formData.customers).reduce(
          (a, key) => a + this.formData.customers[key],
          0
        ) === 0
      );
    },
  },

  watch: {
    formDataKey() {
      this.$emit('formUpdate');
    },
    computedCustomers() {
      this.customersOpened = false;
    },
    submitFromOutside() {
      this.submitForm();
    },
    computedAirport() {
      if (!this.isMultiAirportTerminalsExist) {
        this.formData.terminal = 'dontKnow';
      }
    },
  },

  created() {
    this.terminals = getAppVariable('terminals');
    this.airlines = getAppVariable('products.lounges.airlines_list');
    this.airlinesVisibilityRules = getAppVariable('airlines_visibility_rules');
    this.searchCriteriaConfig = getAppVariable(
      'search_criteria',
      {}
    ) as SearchCriteriaConfig;
    this.baggageVisibilityRules = getAppVariable('baggage_visibility_rules');
    this.primaryLanguage = getAppVariable('default_language');
    this.customerGroups = getAppVariable(
      'products.lounges.customer_groups'
    ) as CustomersOptions;
    if (this.customerGroups) {
      if (
        'adult' in this.customerGroups &&
        !('senior' in this.customerGroups)
      ) {
        this.customersData.adult = 1;
      }
    }
    this.$emit('created');
  },

  mounted() {
    this.$emit('mounted');
  },

  beforeDestroy() {
    commitContinueClicked(this.$store, false);
  },
  methods: {
    onCustomersSelectorChange($event: { [key: string]: any }) {
      this.customersOpened = false;
      this.searchBoxTrackAction('Search widget: Guests select', {
        event: JSON.stringify($event),
      });
    },

    searchBoxTrackAction(actionName: string, context: Record<string, string>) {
      const trackingSharedContext = {
        productType: 'lounges',
        productFlow: this.heroProduct,
      };

      trackAction(actionName, { ...trackingSharedContext, ...context });
    },

    pushGTM() {
      this.$gtm.push(gaDataLayerLounges(this.getSearchCriteria()));
    },

    focus() {
      if (this.$refs.entryDate) {
        const datePicker = this.$refs.entryDate as DateTimePickerHTMLElement;
        if(!datePicker) return
        datePicker.dateDropdownOpened = true;
        datePicker.$el.scrollIntoView({ behavior: 'smooth' });
      }
    },

    focusOnExitDate() {
      const datePicker = this.$refs.exitDate as DateTimePickerHTMLElement;
      if(!datePicker) return
      datePicker.dateDropdownOpened = true;
      datePicker.$el.scrollIntoView({ behavior: 'smooth' });
    },

    onEntryDateChange(date: string): void {
      this.formData.entryDate = date;
      this.entryTimeHaveChanged = true;
      const entryTimeIsNotAvailable =
        this.formData.entryTime &&
        isBefore(
          parseISO(`${date} ${this.formData.entryTime.value}`),
          new Date()
        );

      if (entryTimeIsNotAvailable) {
        this.formData.entryTime = null;
      }

      // Automatically open entryTime field if empty
      if (!this.formData.entryTime && this.$refs.entryDate) {
        (this.$refs.entryDate as DateTimePickerHTMLElement).timeDropdownOpened =
          true;
      }

      this.searchBoxTrackAction('Search widget: Entry date select', {
        data: JSON.stringify({
          date: date,
          time: this.formData.entryTime?.value,
        }),
      });
    },

    onEntryTimeChange(time: Time | null): void {
      this.formData.entryTime = time;
      this.entryTimeHaveChanged = true;

      if (!this.formData.swapped) {
        this.focusOnExitDate();
      } else if (!this.formData.terminal && this.$refs.selectTerminal) {
        this.terminalOpened = true;
      } else if (this.isCustomersEmpty) {
        this.customersOpened = true;
      }
    },

    onExitDateChange(date: string): void {
      this.formData.exitDate = date;
      this.entryTimeHaveChanged = true;
      const entryTimeIsNotAvailable =
        this.formData.exitTime &&
        isBefore(
          parseISO(`${date} ${this.formData.exitTime.value}`),
          new Date()
        );

      if (entryTimeIsNotAvailable) {
        this.formData.exitTime = null;
      }

      if (!this.formData.exitTime && this.$refs.exitDate) {
        (this.$refs.exitDate as DateTimePickerHTMLElement).timeDropdownOpened =
          true;
      }

      this.searchBoxTrackAction('Search widget: Exit date select', {
        data: JSON.stringify({
          date: date,
          time: this.formData.exitTime?.value,
        }),
      });
    },

    onExitTimeChange(time: Time | null): void {
      this.formData.exitTime = time;
      this.entryTimeHaveChanged = true;
      if (!this.formData.terminal && this.$refs.selectTerminal) {
        this.terminalOpened = true;
      } else if (this.formData.swapped) {
        this.focus();
      } else if (this.isCustomersEmpty) {
        this.customersOpened = true;
      }
    },

    submitForm(): void {
      // @ts-ignore
      this.$v.formData.$touch();
      this.$emit('submit', {
        // @ts-ignore
        error: this.$v.formData.$error,
        form: this.formData,
        searchCriteria: this.getSearchCriteria(),
        searchCriteriaSerialized: this.getSearchCriteria(true),
      });
      this.entryTimeHaveChanged = false;
      this.searchBoxTrackAction('Search widget: Search CTA', {
        form: JSON.stringify(this.formData),
      });
    },
    getSearchCriteria(serialize = false): {
      [key: string]: any;
      groups?: { [key: string]: any } | string;
    } {
      const result: { [key: string]: any } = {
        date: this.formData?.entryDate || '',
        time: this.formData?.entryTime?.value || '',
        exitDate: this.formData?.exitDate || '',
        exitTime: this.formData?.exitTime?.value || '',
        terminal: `${this.formData.terminal || ''}`,
        airline: `${this.formData.airline || ''}`,
        swapped: this.formData.swapped || false,
        groups: {} as { [key: string]: any },
      };

      if (this.formData.airport) {
        result.airport = this.formData.airport;
      }

      if (!this.hasOneDatePicker) {
        result['date2'] = this.formData.exitDate || '';
        result['time2'] = this.formData.exitTime?.value || '';
      }

      if (this.formData.baggage_type) {
        result['baggage_type'] = this.formData.baggage_type;
      }

      const { customers } = this.formData;
      Object.keys(customers)
        .filter((key) => customers[key])
        .forEach((key: string) => {
          result.groups[key] = customers[key];
        });
      if (serialize) {
        result.groups = JSON.stringify(result.groups);
      }
      if (this.formData.discount) {
        result.discount = this.formData.discount;
      }
      return result;
    },

    setSearchCriteria(data: { [key: string]: any }) {
      this.formData.entryDate = data.date || '';
      this.formData.exitDate = data.exitDate || '';
      this.formData.airport = data.airport || '';
      this.formData.terminal = data.terminal || '';
      this.formData.airline = data.airline || '';
      this.formData.swapped = data.swapped || false;
      this.formData.discount = data.discount || '';
      this.formData.baggage_type = data.baggage_type || '';
      this.formData.entryTime = data.time
        ? convertTimeStringToObject(data.time)
        : null;
      this.formData.exitTime = data.exitTime
        ? convertTimeStringToObject(data.exitTime)
        : null;
      const customers = data.groups || data.customers || {};
      this.customersData =
        typeof customers === 'string'
          ? JSON.parse(customers)
          : { ...customers };
      if (!this.hasOneDatePicker) {
        this.formData.exitDate = data['date2'] || '';
        this.formData.exitTime = data['time2']
          ? convertTimeStringToObject(data['time2'])
          : null;
      }
    },
    swapDatePickers() {
      this.formData.swapped = !this.formData.swapped;
    },
  },
});
