// Types
import type { Cart, ParsedDiscount } from '@white-label-types/parking-checkout';

export function parseDiscountCodeData(parse: Cart): ParsedDiscount | null  {
  const items = parse?.items;
  const item = parse?.items[0];
  const discount = item?.discount;

  if (discount) {
    const { code, amount, description } = discount;
    /* eslint-disable-next-line no-unsafe-optional-chaining */
    const { subtotal, total } = item?.totals;

    return {
      code,
      amount,
      description,
      subtotal: subtotal,
      total,
      orderTotals: parse.totals,
      items,
    };
  }

  return null;
}
