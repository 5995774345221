import type { AppURL, Domain } from '../types'

/**
 * Allows basic navigation between white label applications
 *
 * @param {Domain} domain - The domain of the app to switch to.
 * @param {AppURL} appURL - The URL to switch to.
 * @param {string} queryParams - The query parameters to append to the URL.
 */
export function goToApp(domain: Domain, appURL: AppURL, queryParams?: string | Record<string, string>): void {
  if (domain === undefined) throw new Error('The provided domain must not be "undefined"');

  // Create URL from the provided domain
  const url = new URL(domain);

  // add the
  url.pathname = appURL

  if (queryParams) {
    const searchParams = new URLSearchParams(queryParams)
    searchParams.forEach((value, key) => url.searchParams.set(key, value));
  }

  window.location.assign(url);
}
