import { render, staticRenderFns } from "./bookings-no-results.vue?vue&type=template&id=40883af1&scoped=true"
import script from "./bookings-no-results.vue?vue&type=script&lang=ts"
export * from "./bookings-no-results.vue?vue&type=script&lang=ts"
import style0 from "./bookings-no-results.vue?vue&type=style&index=0&id=40883af1&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40883af1",
  null
  
)

export default component.exports