
// Packages
import { defineComponent, PropType } from 'vue';
import { ResizeObserver } from 'resize-observer';
import debounce from 'lodash/debounce';
import L from 'leaflet';

// Types
import type { SearchProducts } from '@white-label-types/parking-booking';

// Store
import { readFeaturedProducts } from '@white-label-store/search-results';

// Constants
import { PARKING_CATEGORY_TYPES, PARTNER_TYPES_CODES } from '@white-label-configuration/constants';

// Heplers
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { initFeefoReviews, updateFeefoStyles } from '@white-label-helper/init-feefo-reviews';

import IconBus from '@white-label-icon/icon-bus';
import IconPlane from '@white-label-icon/icon-plane';
import IconService from '@white-label-icon/icon-service';

import SearchParkingMoreInfoModalIcon from './search-parking-more-info-modal-icon.png';

type SearchCriteria = {
  entryDate: string;
  entryTime: {
    name: string;
    value: string;
  };
  exitDate: string;
  exitTime: {
    name: string;
    value: string;
  }
};

// Components
import CavuButton from '../cavu-button/cavu-button.vue';
import SearchParkingTransfer from '../search-parking-transfer/search-parking-transfer.vue';
import SearchParkingPrice from '../search-parking-price/search-parking-price.vue';
import EntryExitInfo from '../entry-exit-info/entry-exit-info.vue';

export default defineComponent({
  name: 'SearchParkingMoreInfoModal',

  components: {
    ControlButton: CavuButton,
    EntryExitInfo,
    IconArrow: () => import('@white-label-icon/icon-arrow'),
    IconCar: () => import('@white-label-icon/icon-car'),
    IconCheck: () => import('@white-label-icon/icon-check'),
    IconClose: () => import('@white-label-icon/icon-close'),
    IconHouse: () => import('@white-label-icon/icon-house'),
    IconKey: () => import('@white-label-icon/icon-key'),
    SearchParkingTransfer,
    SearchParkingPrice
  },

  resizeObserver: null,

  props: {
    product: {
      type: Object as PropType<SearchProducts>,
      required: true,
    },

    processAddingToCart: {
      type: Boolean,
      default: false,
    },

    openOnReviewTab: {
      type: Boolean,
      default: false,
    },

    searchCriteria: {
      type: Object as PropType<SearchCriteria>,
      required: true,
    },

    displayBookButton: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      tabs: [{
        id: 'overview',
        translationKey: 'overview',
        visible: true,
        active: true,
      }, {
        id: 'on-arrival',
        translationKey: 'onArrival',
        visible: true,
        active: false,
      }, {
        id: 'on-return',
        translationKey: 'onReturn',
        visible: true,
        active: false,
      }, {
        id: 'map',
        translationKey: 'map',
        visible: false,
        active: false,
      }, {
        id: 'reviews',
        translationKey: 'reviews',
        visible: getAppVariable('review_providers[0].is_enabled'),
        active: false,
      }],
      isTabsScrollable: false,
      isTabsScrollAtTheEnd: false,
    };
  },

  computed: {
    visibleTabs(): { id: string, translationKey: string, visible: boolean, active: boolean }[] {
      return this.tabs.filter((tab) => tab.visible);
    },

    featuredProducts(): ReturnType<typeof readFeaturedProducts> {
      return readFeaturedProducts(this.$store);
    },

    isFeatured(): boolean {
      return !!this.featuredProducts?.some((product) => product.product_id === this.product.id);
    },

    categoryTypeIcon(): Vue.Component | null {
      switch (this.product.product_option.code) {
        case PARKING_CATEGORY_TYPES.MEET_AND_GREET_NO_TRANSFER:
          return IconService;
        case PARKING_CATEGORY_TYPES.OFFSITE_PARK_AND_RIDE:
          return IconBus;
        case PARKING_CATEGORY_TYPES.ON_AIRPORT:
          return IconPlane;
        default:
          return null;
      }
    },

    categoryTypeText(): string {
      switch (this.product.product_option.code) {
        case PARKING_CATEGORY_TYPES.MEET_AND_GREET_NO_TRANSFER:
          return this.$t('searchParkingMoreInfoModal.meetAndGreet');
        case PARKING_CATEGORY_TYPES.OFFSITE_PARK_AND_RIDE:
          return this.$t('searchParkingMoreInfoModal.parkAndRide');
        case PARKING_CATEGORY_TYPES.ON_AIRPORT:
          return this.$t('searchParkingMoreInfoModal.onAirport');
        default:
          return '';
      }
    },

    entryDateTime(): string {
      return `${this.searchCriteria.entryDate} ${this.searchCriteria.entryTime.value}`;
    },

    exitDateTime(): string {
      return `${this.searchCriteria.exitDate} ${this.searchCriteria.exitTime.value}`;
    },

    totalMessage(): string {
      const isTaxInclusive = getAppVariable('is_tax_inclusive');
      const isFeeInclusive = getAppVariable('is_fee_inclusive');

      if (isTaxInclusive && isFeeInclusive) {
        return this.$t('searchParkingMoreInfoModal.includingTaxesAndFees');
      }
      if (isTaxInclusive && !isFeeInclusive) {
        return this.$t('searchParkingMoreInfoModal.includingTaxes');
      }
      if (!isTaxInclusive && !isFeeInclusive) {
        return this.$t('searchParkingMoreInfoModal.excludingTaxesAndFees');
      }
      return this.$t('searchParkingMoreInfoModal.excludingTaxes');
    },

    isReviewsEnabled(): boolean {
      return !!getAppVariable('review_providers[0].is_enabled')
        && this.$launchDarkly.variation('WL-ProductCardReviewStars') === true;
    },

    isUpgradeAvailable(): boolean {
      return this.product.upgrade.is_available;
    },

    brandColor(): string {
      // TODO: ECOM-1455
      // use `colours.brand` if available, otherwise fallback to `brand_color` (to be deprecated soon)
      const brandColour = getAppVariable('colours.brand') ?? getAppVariable('brand_color');
      if (brandColour) {
        return brandColour.substring(0, brandColour.lastIndexOf(','));
      }
      return '';
    },

    isInteractiveMapAvailable(): boolean {
      return Boolean(this.product.geo.latitude && this.product.geo.longitude);
    },

    isMapImageAvailable(): boolean {
      return this.product.is_map_image_active && Boolean(this.product.map_image?.src);
    },

    isMapSectionAvailable(): boolean {
      return this.isInteractiveMapAvailable || (this.isMapImageAvailable && !this.forceInteractiveMap);
    },

    forceInteractiveMap(): boolean {
      const wlOnlyPartner = getAppVariable('partner_type_code') === PARTNER_TYPES_CODES.WL_ONLY;
      const providerAndWlPartner = getAppVariable('partner_type_code') === PARTNER_TYPES_CODES.PROVIDER_AND_WHITE_LABEL;
      const thirdPartyProduct = this.product.productHeader.inventory_provider.toLowerCase() !== 'internal';

      return wlOnlyPartner || (providerAndWlPartner && thirdPartyProduct);
    },

    showProductLogo(): boolean {
      return getAppVariable('is_product_logo_visible') && Boolean(this.product.product_logo?.src);
    },
  },

  mounted() {
    if (this.isReviewsEnabled) {
      initFeefoReviews(getAppVariable('review_providers[0].merchant_id'));
      updateFeefoStyles(this.brandColor);
    }
    this.initIntersectionObserver();
    if (this.openOnReviewTab) {
      this.observeComponentResize();
    }
    this.initMap();

    const mapIndex = this.tabs.findIndex((tab) => tab.id === 'map');
    this.tabs[mapIndex].visible = this.isMapSectionAvailable;
    this.$nextTick(() => {
      this.checkIfTabsScrollable();
    });
  },

  methods: {
    initIntersectionObserver(): void {
      const onIntersection = (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
          const link = document.querySelector(`.more-info-modal__tab--${entry.target.id}`);
          const index = this.tabs.findIndex((item) => item.id === entry.target.id);
          if (entry.isIntersecting && link instanceof HTMLElement) {
            this.tabs[index].active = true;
          } else if (link instanceof HTMLElement) {
            this.tabs[index].active = false;
          }
        });
      };

      const observer = new IntersectionObserver(onIntersection, {
        root: document.querySelector('.more-info-modal__scroll-area'),
        threshold: [0.1, 0.3],
      });

      const children = document.querySelector('.more-info-modal__scroll-area')?.children;
      if (children instanceof HTMLCollection) {
        Array.from(children).forEach((child) => {
          observer.observe(child);
        });
      }
    },

    bookParking(): void {
      if (this.isUpgradeAvailable) {
        this.$emit('show-upgrade-modal', this.product.id);
      } else {
        this.$emit('book-parking', this.product);
      }
    },

    scrollToElement(id: string): void {
      const el = document.getElementById(id);

      if (el instanceof HTMLElement) {
        el.scrollIntoView({ block: 'start', behavior: 'smooth' });

        setTimeout(() => {
          this.tabs.forEach((tab, index) => {
            if (tab.id === id) {
              this.tabs[index].active = true;
            } else {
              this.tabs[index].active = false;
            }
          });
        }, 500);
      }
    },

    checkIfTabsScrollable(): void {
      const el = document.querySelector('.more-info-modal__nav-tabs');

      if (el instanceof HTMLElement) {
        this.isTabsScrollable = el.scrollWidth > el.clientWidth;
      }
    },

    observeComponentResize(): void {
      this.$options.resizeObserver = new ResizeObserver(debounce(() => this.showReviewSection(), 250));
      this.$options.resizeObserver.observe(this.$refs.reviewsSection);
    },

    unobserveComponentResize(): void {
      this.$options.resizeObserver.unobserve(this.$refs.reviewsSection);
    },

    showReviewSection(): void {
      const el = document.getElementById('reviews');
      if (el instanceof HTMLElement) {
        el.scrollIntoView({ block: 'start', behavior: 'auto' });
      }
      this.unobserveComponentResize();
    },

    initMap(): void {
      if (!this.isInteractiveMapAvailable || this.isMapImageAvailable) {
        return;
      }

      const coordinates = L.latLng(this.product.geo.latitude, this.product.geo.longitude);

      const map = L.map('leaflet-map').setView(coordinates, 13);
      L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
      }).addTo(map);

      const icon = L.icon({
        iconUrl: SearchParkingMoreInfoModalIcon,
        iconSize: [32, 44],
        iconAnchor: [16, 44],
      });
      L.marker(coordinates, { icon }).addTo(map);
    },

    navTabsScrollHandler(el: Event): void {
      if (el.target instanceof HTMLElement) {
        this.isTabsScrollAtTheEnd = el.target.scrollLeft === el.target.scrollWidth - el.target.clientWidth;
      }
    },

    scrollLeftIconClickHandler(): void {
      const el = document.querySelector('.more-info-modal__nav-tabs');

      if (el instanceof HTMLElement) {
        el.scrollTo({
          left: -100,
          behavior: 'smooth',
        });
      }
    },

    scrollRightIconClickHandler() : void {
      const el = document.querySelector('.more-info-modal__nav-tabs');

      if (el instanceof HTMLElement) {
        el.scrollTo({
          left: 100,
          behavior: 'smooth',
        });
      }
    },
  },
});
