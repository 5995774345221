var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ui-modal-button-wrapper"},[(_vm.typeOfElement === _vm.typesOfElement.button)?_c('button',{class:[
      'ui-button button button--full',
      _vm.getTypeOfStateClass,
      _vm.getEnvClass,
      _vm.getDisabledState,
      _vm.getJustifyClass,
    ],attrs:{"id":_vm.id,"data-testid":_vm.testId,"type":_vm.type,"disabled":_vm.disabled},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.btnClick.apply(null, arguments)}}},[(_vm.loading)?_c('DotsLoader'):[(_vm.prependIconComponent)?_c(_vm.prependIconComponent,{tag:"component",staticClass:"mr-2"}):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),(_vm.appendIconComponent)?_c(_vm.appendIconComponent,{tag:"component",staticClass:"ml-2"}):_vm._e()]],2):_vm._e(),_vm._v(" "),(_vm.typeOfElement === _vm.typesOfElement.link)?_c('NuxtLink',{class:[
      'ui-button button button--full',
      _vm.getTypeOfStateClass,
      _vm.getEnvClass,
      _vm.getDisabledState,
      _vm.getJustifyClass,
    ],attrs:{"id":_vm.id,"to":_vm.to,"type-of-element":"button","button-type":"primary"}},[(_vm.loading)?_c('DotsLoader'):[(_vm.prependIconComponent)?_c(_vm.prependIconComponent,{tag:"component",staticClass:"mr-2"}):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),(_vm.appendIconComponent)?_c(_vm.appendIconComponent,{tag:"component",staticClass:"ml-2"}):_vm._e()]],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }