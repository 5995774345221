import { render, staticRenderFns } from "./bookings.vue?vue&type=template&id=604a5ee5&scoped=true"
import script from "./bookings.vue?vue&type=script&lang=ts"
export * from "./bookings.vue?vue&type=script&lang=ts"
import style0 from "./bookings.vue?vue&type=style&index=0&id=604a5ee5&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "604a5ee5",
  null
  
)

export default component.exports