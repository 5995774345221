
import { defineComponent, PropType } from 'vue';
import type {
  CustomersValues,
  CustomersOptions,
  CustomerItem,
} from '@white-label-types/customers';

import NumSelector from '../num-selector/num-selector.vue';

const defaultCustomerValues: CustomersValues = {
  adult: 0,
  child: 0,
  senior: 0,
  infant: 0,
};

export default defineComponent({
  name: 'CustomersSelector',
  components: {
    NumSelector,
  },
  props: {
    config: {
      type: Object as PropType<CustomersOptions>,
      default(): CustomersOptions {
        return {
          max_tickets: 9,
          adult: {
            min_age: 16,
            max_age: 55,
          },
          senior: {
            min_age: 55,
          },
          child: {
            min_age: 3,
            max_age: 30,
          },
          infant: {
            max_age: 3,
          },
        };
      },
    },
    minAdults: {
      type: Number,
      default: 0,
    },
    value: {
      type: Object as PropType<CustomersValues>,
      default() {
        return {};
      },
    },
    showAgeRanges: {
      type: Boolean,
      default: false,
    },
    isMobilePopUp: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      values: {
        ...defaultCustomerValues,
      } as CustomersValues,
    };
  },
  computed: {
    defaultList(): CustomerItem[] {
      return [
        {
          id: 'senior',
          title: 'Senior',
          isAdult: true,
        },
        {
          id: 'adult',
          title: 'Adult',
          isAdult: true,
        },
        {
          id: 'child',
          title: 'Child',
        },
        {
          id: 'infant',
          title: 'Infant',
        },
      ] as CustomerItem[];
    },
    list(): CustomerItem[] {
      const { defaultList, values, config } = this;
      const list = defaultList.filter((item) => config[item.id]);
      const { length } = list;
      let adultCount = 0;
      let childCount = 0;
      defaultList.forEach(({ isAdult, id }) => {
        if (isAdult) {
          adultCount += values[id];
        }
        if (!isAdult) {
          childCount += values[id];
        }
      });
      const totalCount = adultCount + childCount;
      return list.map((item, index) => {
        const ageRange = config[item.id];
        const min_age = ageRange?.min_age ?? 0;
        const max_age = ageRange?.max_age ?? '';
        const { isAdult = false } = item;
        let minus_disabled = false;
        let plus_disabled = false;
        const value = values[item.id];
        let label = `${min_age} - ${max_age}`;
        if (!max_age) {
          label = `${min_age}+`;
        }
        if (index === length - 1 && max_age) {
          label += ` year${max_age > 1 ? 's' : ''} old`;
        }

        if (!isAdult && adultCount === 0) {
          plus_disabled = true;
        }
        if (totalCount >= config.max_tickets) {
          plus_disabled = true;
        }
        if (value === 0 || (isAdult && adultCount === this.minAdults)) {
          minus_disabled = true;
        }
        return {
          ...item,
          label,
          minus_disabled,
          plus_disabled,
          value,
        };
      });
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.values = { ...defaultCustomerValues, ...value };
      },
    },
  },
  created() {
    this.$emit('init');
  },
  methods: {
    apply() {
      this.$emit('input', { ...this.values });
    },
    getCustomersValueText(values?: CustomersValues): string {
      const { adult, senior, infant, child } = values || this.values;
      const label = [];
      if (senior) {
        label.push(`${senior} senior${senior > 1 ? 's' : ''}`);
      }
      if (adult) {
        label.push(`${adult} adult${adult > 1 ? 's' : ''}`);
      }
      if (child) {
        label.push(`${child} ${child > 1 ? 'children' : 'child'}`);
      }
      if (infant) {
        label.push(`${infant} infant${infant > 1 ? 's' : ''}`);
      }
      return label.length ? label.join(', ') : '';
    },
    getCustomersError(values?: CustomersValues): string {
      let customersError = '';
      const customerValues = values ?? this.values;
      if (!Object.values(customerValues).some((item) => (item as number) > 0)) {
        customersError = 'At least 1 adult required ';
      } else if (
        customerValues.child + customerValues.infant > 0 &&
        customerValues.senior + customerValues.adult === 0
      ) {
        customersError = 'At least 1 adult required';
      }
      return customersError;
    },
  },
});
