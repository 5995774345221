
import { PropType, defineComponent } from 'vue'

export default defineComponent({
  props: {
    size: {
      default: () => 8,
      type: [String, Number] as PropType<string | number>
    },

    duration: {
      default: () => 1,
      type: Number
    }
  }
})
