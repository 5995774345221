var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('nav',{staticClass:"navbar kk3"},[_c('div',{staticClass:"navbar__first-row"},[_c('div',{staticClass:"navbar-container container"},[_c('Logo',{staticClass:"site-logo",attrs:{"home-page-url":_vm.homePageUrl,"image-url":_vm.logoUrl}}),_vm._v(" "),_c('client-only',[_vm._t("progression"),_vm._v(" "),(_vm.showLoginFlow && _vm.menuData)?[_c('UserMenuDesktop',{attrs:{"menu-data":_vm.menuData}}),_vm._v(" "),_c('UserMenuMobile',{attrs:{"menu-data":_vm.menuData,"tabs-data":_vm.tabsData}})]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex kk2",class:[{ 'order-3': !_vm.showMyBookingsButton }]},[_c('Button',{class:[
              'navbar__my-bookings-button',
              `navbar__my-bookings-button--${_vm.colourScheme}`,
              // {
              //   invisible: !showMyBookingsButton,
              //   'navbar__my-bookings-button--login': loggedInUser,
              // },
            ],attrs:{"id":"my-bookings order-1","button-type":"secondary","type-of-element":"button","type":"button"},on:{"btnClick":_vm.goToMyBookings}},[_vm._v("\n            "+_vm._s(_vm.loginButtonText)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"order-2 flex align-center"},[_vm._t("basket")],2)],1)],2)],1)]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loggedInUser),expression:"loggedInUser"}],staticClass:"footer-bottom-block container hide-on-mobile",class:{ 'flex-row-reverse': !_vm.hasTabLinks }},[_c('NavbarTabs',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.hasTabLinks),expression:"hasTabLinks"}]},'NavbarTabs',_vm.tabsData.tabsLinks,false)),_vm._v(" "),(!_vm.isBookingPortal)?_c('NavbarPartnerLink'):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }