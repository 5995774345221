

// Packages
import { defineComponent } from 'vue';

// Stores
import { commitUpdatePreloader } from '@white-label-store/preloader-controller';

export default defineComponent({
  name: 'Error',

  components: {
    ErrorPage: () => import('@white-label-frontend/error'),
  },

  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    };
  },

  // Remove preloader for avoid wrong preloader on this page
  created() {
    commitUpdatePreloader(this.$store, '');
  },

  mounted() {
    // TODO Need to delete when dev will moved to hosting, which supports Nuxt middleware
    this.browserSupportCheck();
  },

  methods: {
    goBack() {
      if (process.env.NUXT_ENV_BOOKING_APP_URL) {
        window.location.replace(process.env.NUXT_ENV_BOOKING_APP_URL);
      }
    },
  },
});
