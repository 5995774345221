
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BTicketHeader',

  props: {
    productName: {
      type: String,
      default: '',
    },

    airportName: {
      type: String,
      default: '',
    },

    orderId: {
      type: String,
      default: '',
    },
  },
});
