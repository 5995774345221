// Packages
import { ActionContext } from 'vuex';
import { getStoreAccessors } from 'typesafe-vuex';
import { datadogRum } from '@datadog/browser-rum';

// Constants
import { BOOKING_STATUSES } from '@white-label-configuration/constants';

// Types
import type { DateObject } from '@white-label-types/date-times';
import type { ManageBookingState as State, BookingStatus } from '@white-label-types/stores';
import type { SummaryDiscount } from '@white-label-types/summary';
import { RootState } from '@white-label-types/stores';

// Helpers
import { convertTimeObjToString } from '@white-label-helper/time-helpers';
import { createDiscountSummaryProp } from '@white-label-helper/discount-summary-prop-creator';
import { getManageBookingState } from '@white-label-helper/api-manage-booking';
import { cancelBookingHelper } from '@white-label-helper/pusher-cancel-booking';

type ManageBookingContext = ActionContext<State, RootState>;

const getManageBookingDefaultState = () => ({
  entryExitInfo: {},
  extras: [],
  groups: [],
  userInfo: {},
  orderData: {
    updated: {
      space: {},
      extras: [],
    },
    original: {
      space: {},
      extras: [],
    },
  },
  status: '' as BookingStatus,
  discount: null,
  amendmentFee: null,
  language: null,
  productCode: null,
  searchCriteria: null,
  /** The original order number. Only gets populated when product has been modified */
  originalOrderItemId: null,
  items: [],
  token: null
});

export const manageBookingState = (): State => ({
  manageBookingState: getManageBookingDefaultState(),
  showFields: {
    licensePlate: true,
  },
});

type Getters = {
  getCancellationPolicies: State['manageBookingState']['cancellationPolicies']
  getAmendmentPolicies: State['manageBookingState']['amendmentPolicies']
  getId: State['manageBookingState']['id']
  getPiiToken: State['manageBookingState']['piiToken']
  getUserInfo: State['manageBookingState']['user']
  getUserEmail: NonNullable<State['manageBookingState']['user']>['email']
  getEntryExitInfo: State['manageBookingState']['entryExitInfo']
  getOrderId: State['manageBookingState']['orderId']
  getOrderItemReference: State['manageBookingState']['orderItemReference']
  getCancellationProtection: State['manageBookingState']['cancellationProtection']
  getAllExtras: State['manageBookingState']['extras']
  getInventoryName: NonNullable<State['manageBookingState']['inventoryItem']>['display_name']
  getInventoryItem: State['manageBookingState']['inventoryItem']
  getAirportName: NonNullable<State['manageBookingState']['locationInfo']>['name']
  getOrderTotals: State['manageBookingState']['totals']
  getEntryTime: DateObject
  getExitTime: DateObject
  stringEntryTime: string
  stringExitTime: string
  getBookingStatus: BookingStatus
  getDiscount: SummaryDiscount | null
  isCancelled: boolean
  isConfirmed: boolean
  isInProgress: boolean
  isPastBooking: boolean
  isAmendable: State['manageBookingState']['isAmendable']
  isCancellable: State['manageBookingState']['isCancellable']
  getAmendmentFee: State['manageBookingState']['amendmentFee']
  getLanguage: State['manageBookingState']['language']
  getAvailableAdditionalFields: State['manageBookingState'],
  getProductTypeCategories: State['manageBookingState'],
  getProductCode: NonNullable<State['manageBookingState']['productCode']>
  getManageBookingToken: State['manageBookingState']['token']
  getManageBookingItems: State['manageBookingState']['items']
}

const manageBookingGetters = {
  getBooking: ((state: State, getters: Getters) => ({
    ...state.manageBookingState,
    entryDateTime: getters.stringEntryTime,
    exitDateTime: getters.stringExitTime,
    cancellationPolicies: getters.getCancellationPolicies,
    amendmentPolicies: getters.getAmendmentPolicies,
    amendmentFee: getters.getAmendmentFee,
  })),
  getCancellationPolicies: (state: State) => state.manageBookingState?.cancellationPolicies,
  getAmendmentPolicies: (state: State) => state.manageBookingState?.amendmentPolicies,
  getId: (state: State) => state.manageBookingState?.id,
  getPiiToken: (state: State) => state.manageBookingState?.piiToken,
  getUserInfo: (state: State) => state.manageBookingState?.user,
  getUserEmail: (state: State) => state.manageBookingState?.user?.email,
  getEntryExitInfo: (state: State) => state.manageBookingState?.entryExitInfo,
  getOrderId: (state: State) => state.manageBookingState?.orderId,
  getOrderItemReference: (state: State) => state.manageBookingState?.orderItemReference,
  getCancellationProtection: (state: State) => state.manageBookingState?.cancellationProtection,
  getManageBookingToken: (state: State) => state.manageBookingState?.token,
  getManageBookingItems: (state: State) => state.manageBookingState?.items,
  getAllExtras(state: State) {
    // Having to do this as it looks like the type changes at some point from an Array to an Object
    const { extras } = state.manageBookingState.extras as { extras: unknown };

    return extras;
  },
  getInventoryName: (state: State) => state.manageBookingState?.inventoryItem?.display_name,
  getInventoryItem: (state: State) => state.manageBookingState?.inventoryItem,
  getAirportName: (state: State) => state.manageBookingState?.locationInfo?.name,
  getOrderTotals: (state: State) => state.manageBookingState?.totals,
  getEntryTime: (state: State) => {
    if ('entryExitInfo' in state.manageBookingState) {
      const { entryDate, entryTime } = state.manageBookingState.entryExitInfo;
      return {
        date: entryDate,
        time: entryTime,
      };
    }

    return {};
  },
  getExitTime: (state: State) => {
    if ('entryExitInfo' in state.manageBookingState) {
      const { exitDate, exitTime } = state.manageBookingState.entryExitInfo;
      return {
        date: exitDate,
        time: exitTime,
      };
    }

    return {};
  },
  getGroups: (state: State) => state.manageBookingState?.groups,
  stringEntryTime: (_state: State, getters: Getters) => convertTimeObjToString(getters.getEntryTime),
  stringExitTime: (_state: State, getters: Getters) => convertTimeObjToString(getters.getExitTime),
  getBookingStatus: (state: State) => {
    const { status } = state.manageBookingState;
    return BOOKING_STATUSES[status];
  },
  getDiscount: (state: State) => createDiscountSummaryProp(state.manageBookingState),
  isCancelled: (_state: State, getters: Getters) => getters.getBookingStatus === BOOKING_STATUSES.CANCELLED,
  isConfirmed: (_state: State, getters: Getters) => getters.getBookingStatus === BOOKING_STATUSES.CONFIRMED,
  isInProgress: (_state: State, getters: Getters) => getters.getBookingStatus === BOOKING_STATUSES.IN_PROGRESS,
  isPastBooking: (_state: State, getters: Getters) => getters.getBookingStatus === BOOKING_STATUSES.PAST_BOOKING,
  isAmendable: (state: State) => state.manageBookingState?.isAmendable,
  isCancellable: (state: State) => state.manageBookingState?.isCancellable,
  getAmendmentFee: (state: State) => state.manageBookingState.amendmentFee,
  getLanguageISOCode: (state: State) => state.manageBookingState.language,
  getProductCode: (state: State) => state.manageBookingState?.productCode,

  /** A list of fields to show on the update details page */
  showPersonalDetailFields: (state: State) => {
    const { licensePlate } = state.showFields;
    return {
      licensePlate,
    };
  },
  getAvailableAdditionalFields: (state: State) => {
    const { inventoryItem } = state.manageBookingState;
    const fields = inventoryItem?.additional_fields;
    return {
      zipcode: {
        toShow: fields?.zipcode?.is_show || false,
        isRequired: fields?.zipcode?.is_required || false,
      },
      phone_number: {
        toShow: fields?.phone_number?.is_show || false,
        isRequired: fields?.phone_number?.is_required || false,
      },
      plate_number: {
        toShow: fields?.plate_number?.is_show || false,
        isRequired: fields?.plate_number?.is_required || false,
      },
      inbound_flight_number: {
        toShow: fields?.inbound_flight_number?.is_show || false,
        isRequired: fields?.inbound_flight_number?.is_required || false,
      },
      outbound_flight_number: {
        toShow: fields?.outbound_flight_number?.is_show || false,
        isRequired: fields?.outbound_flight_number?.is_required || false,
      }
    }
  },
  getProductTypeCategories(state: State, getters: Getters) {
    const productType = getters.getProductCode || 'parking';
    const categories: string[] = []
    switch (productType) {
      case 'parking':
        categories.push(
          'entry',
          'exit'
        );
        break;
      case 'lounges':
        categories.push(
          'entry',
          'guest'
        );
        break;
    }
    return categories;
  }
};

const mutations = {
  setEntryExitInfo(state: State, entryDate: State['manageBookingState']['entryExitInfo']) {
    state.manageBookingState = {
      ...state.manageBookingState,
      entryExitInfo: entryDate,
    };
  },
  setManageBookingState(state: State, payload: State['manageBookingState']) {
    state.manageBookingState = payload;
  },
  clearManageBookingState(state: State) {
    state.manageBookingState = getManageBookingDefaultState();
  },
  setManageBookingExtras(state: State, payload: State['manageBookingState']['extras']) {
    state.manageBookingState.extras = payload;
  },
  cancelBooking(state: State) {
    state.manageBookingState.status = BOOKING_STATUSES.CANCELLED;
  },
  setManageBookingToken(state: State, payload: State['manageBookingState']['token']) {
    state.manageBookingState.token = payload;
  },
  setIsCancellable(state: State, payload: boolean) {
    state.manageBookingState.isCancellable = payload;
  },

  /**
   * Update the state as to which fields to use on the update details page
   */
  fieldDisplay(state: State, payload: { licensePlate: boolean }) {
    state.showFields = { ...payload };
  },
  updateLoungeSearchCriteria(state: State, payload: object) {
    state.manageBookingState.searchCriteria = {
      lounges: {
        ...state.manageBookingState.searchCriteria?.lounges,
        ...payload,
      },
    }
  },
};

const actions = {
  async getState({ commit }: ManageBookingContext, token: string) {
    try {
      const state = await getManageBookingState(token);
      if (state) {
        commit('fieldDisplay', {
          licensePlate: state?.showLicensePlate,
        });
        commit('setManageBookingState', state);
        return state;
      }

      return null;
    } catch (e) {
      const errorMessage = new Error('Something when wrong setting manage booking state', { cause: e });
      datadogRum.addError(errorMessage, {
        partner: window.location.host,
      });

      return errorMessage;
    }
  },

  async cancelBooking({ commit }: ManageBookingContext, payload: { manageBookingToken: string, orderReference: string, isMultiBasket: boolean }) {
    try {
      const response = await cancelBookingHelper().cancelBooking(payload.manageBookingToken, payload.orderReference);

      let managementToken;
      if (payload.isMultiBasket) {
        const responseArr = response as unknown as Array<{ management_token: { token: string; expiration: number; }; order_reference: string; }>;
        managementToken = responseArr[responseArr.length - 1]?.management_token;
      } else {
        const responseObj = response as { management_token: { token: string; expiration: number; }; order_reference: string; };
        managementToken = responseObj?.management_token;
      }
      commit('setManageBookingToken', managementToken);
      commit('cancelBooking');

      return response;
    } catch (e) {
      const errorMessage = new Error('Something when wrong with booking cancellation', { cause: e });
      datadogRum.addError(errorMessage, {
        partner: window.location.host,
      });

      return e;
    }
  }
};

const { commit, read, dispatch } = getStoreAccessors<State, RootState>('manageBooking');

export const readBooking = read(manageBookingGetters.getBooking);
export const readCancellationPolicies = read(manageBookingGetters.getCancellationPolicies);
export const readAmendmentPolicies = read(manageBookingGetters.getAmendmentPolicies);
export const readId = read(manageBookingGetters.getId);
export const readPiiToken = read(manageBookingGetters.getPiiToken);
export const readUserInfo = read(manageBookingGetters.getUserInfo);
export const readUserEmail = read(manageBookingGetters.getUserEmail);
export const readEntryExitInfo = read(manageBookingGetters.getEntryExitInfo);
export const readOrderId = read(manageBookingGetters.getOrderId);
export const readOrderItemReference = read(manageBookingGetters.getOrderItemReference);
export const readCancellationProtection = read(manageBookingGetters.getCancellationProtection);
export const readAllExtras = read(manageBookingGetters.getAllExtras);
export const readInventoryName = read(manageBookingGetters.getInventoryName);
export const readInventoryItem = read(manageBookingGetters.getInventoryItem);
export const readAirportName = read(manageBookingGetters.getAirportName);
export const readOrderTotals = read(manageBookingGetters.getOrderTotals);
export const readEntryTime = read(manageBookingGetters.getEntryTime);
export const readExitTime = read(manageBookingGetters.getExitTime);
export const readGroups = read(manageBookingGetters.getGroups);
export const readStringEntryTime = read(manageBookingGetters.stringEntryTime);
export const readStringExitTime = read(manageBookingGetters.stringExitTime);
export const readBookingStatus = read(manageBookingGetters.getBookingStatus);
export const readDiscount = read(manageBookingGetters.getDiscount);
export const readIsCancelled = read(manageBookingGetters.isCancelled);
export const readIsConfirmed = read(manageBookingGetters.isConfirmed);
export const readIsInProgress = read(manageBookingGetters.isInProgress);
export const readIsPastBooking = read(manageBookingGetters.isPastBooking);
export const readIsAmendable = read(manageBookingGetters.isAmendable);
export const readIsCancellable = read(manageBookingGetters.isCancellable);
export const readAmendmentFee = read(manageBookingGetters.getAmendmentFee);
export const readPersonalDetailFields = read(manageBookingGetters.showPersonalDetailFields);
export const readLanguageISOCode = read(manageBookingGetters.getLanguageISOCode);
export const readAvailableAdditinalFields = read(manageBookingGetters.getAvailableAdditionalFields);
export const readProductTypesCategories = read(manageBookingGetters.getProductTypeCategories);
export const readProductCode = read(manageBookingGetters.getProductCode);
export const readManageBookingToken = read(manageBookingGetters.getManageBookingToken);
export const readManageBookingItems = read(manageBookingGetters.getManageBookingItems);

export const commitEntryExitInfo = commit(mutations.setEntryExitInfo);
export const commitManageBookingState = commit(mutations.setManageBookingState);
export const commitManageBookingExtras = commit(mutations.setManageBookingExtras);
export const commitCancelBooking = commit(mutations.cancelBooking);
export const commitIsCancellable = commit(mutations.setIsCancellable);
export const commitClearManageBookingState = commit(mutations.clearManageBookingState);
export const commitUpdateLoungeSearchCriteriaMutation = commit(mutations.updateLoungeSearchCriteria);
export const dispatchState = dispatch(actions.getState);
export const dispatchCancelBooking = dispatch(actions.cancelBooking);

export default {
  // Should this off been namespaced?
  state: manageBookingState,
  getters: manageBookingGetters,
  mutations,
  actions,
};
