
// Packages
import { defineComponent, PropType } from 'vue';

// Types
import type { SearchProducts } from '@white-label-types/parking-booking';

// Heplers
import { getAppVariable } from '@white-label-helper/get-app-variable';

// type SearchCriteria = {
//   entryDate: string;
//   entryTime: {
//     name: string;
//     value: string;
//   };
//   exitDate: string;
//   exitTime: {
//     name: string;
//     value: string;
//   }
// };

// Components
// import CavuButton from '../cavu-button/cavu-button.vue';
// import SearchParkingPrice from '../search-parking-price/search-parking-price.vue';
// import EntryExitInfo from '../entry-exit-info/entry-exit-info.vue';

export default defineComponent({
  name: 'SearchLoungesMoreInfoModal',

  components: {
    // CavuButton,
    // EntryExitInfo,
    IconArrow: () => import('@white-label-icon/icon-arrow'),
    IconCheck: () => import('@white-label-icon/icon-check'),
    IconClose: () => import('@white-label-icon/icon-close'),
    IconLeft: () => import('@white-label-icon/icon-left'),
    // SearchParkingPrice
  },

  // resizeObserver: null,

  props: {
    product: {
      type: Object as PropType<SearchProducts>,
      required: true,
    },

    // processAddingToCart: {
    //   type: Boolean,
    //   default: false,
    // },

    // searchCriteria: {
    //   type: Object as PropType<SearchCriteria>,
    //   required: true,
    // },

    // displayBookButton: {
    //   type: Boolean,
    //   default: true,
    // },
  },

  data() {
    return {
      tabs: [{
        id: 'overview',
        translationKey: 'overview',
        visible: true,
        active: true,
      }, {
        id: 'description',
        translationKey: 'description',
        visible: true,
        active: false,
      }, {
        id: 'directions',
        translationKey: 'directions',
        visible: true,
        active: false,
      }],
      isTabsScrollable: false,
      isTabsScrollAtTheEnd: false,
      photoIndex: 0,
    };
  },

  computed: {
    visibleTabs(): { id: string, translationKey: string, visible: boolean, active: boolean }[] {
      return this.tabs.filter((tab) => tab.visible);
    },

    // will be needed for ECOM-2372:

    // entryDateTime(): string {
    //   return `${this.searchCriteria.entryDate} ${this.searchCriteria.entryTime.value}`;
    // },

    // exitDateTime(): string {
    //   return `${this.searchCriteria.exitDate} ${this.searchCriteria.exitTime.value}`;
    // },

    // totalMessage(): string {
    //   const isTaxInclusive = getAppVariable('is_tax_inclusive');
    //   const isFeeInclusive = getAppVariable('is_fee_inclusive');

    //   if (isTaxInclusive && isFeeInclusive) {
    //     return this.$t('searchLoungesMoreInfoModal.includingTaxesAndFees');
    //   }
    //   if (isTaxInclusive && !isFeeInclusive) {
    //     return this.$t('searchLoungesMoreInfoModal.includingTaxes');
    //   }
    //   if (!isTaxInclusive && !isFeeInclusive) {
    //     return this.$t('searchLoungesMoreInfoModal.excludingTaxesAndFees');
    //   }
    //   return this.$t('searchLoungesMoreInfoModal.excludingTaxes');
    // },

    showProductLogo(): boolean {
      return getAppVariable('is_product_logo_visible') && Boolean(this.product.product_logo?.src);
    },
  },

  mounted() {
    this.initIntersectionObserver();

    this.$nextTick(() => {
      this.checkIfTabsScrollable();
    });
  },

  methods: {
    initIntersectionObserver(): void {
      const onIntersection = (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
          const link = document.querySelector(`.more-info-modal__tab--${entry.target.id}`);
          const index = this.tabs.findIndex((item) => item.id === entry.target.id);
          if (entry.isIntersecting && link instanceof HTMLElement) {
            this.tabs[index].active = true;
          } else if (link instanceof HTMLElement) {
            this.tabs[index].active = false;
          }
        });
      };

      const observer = new IntersectionObserver(onIntersection, {
        root: document.querySelector('.more-info-modal__scroll-area'),
        threshold: [0.1, 0.3],
      });

      const children = document.querySelector('.more-info-modal__scroll-area')?.children;
      if (children instanceof HTMLCollection) {
        Array.from(children).forEach((child) => {
          observer.observe(child);
        });
      }
    },

    // bookParking(): void {
    //   this.$emit('book-parking', this.product);
    // },

    scrollToElement(id: string): void {
      console.log('scrolling to element', id);
      const el = document.getElementById(id);

      if (el instanceof HTMLElement) {
        el.scrollIntoView({ block: 'start', behavior: 'smooth' });

        setTimeout(() => {
          this.tabs.forEach((tab, index) => {
            if (tab.id === id) {
              this.tabs[index].active = true;
            } else {
              this.tabs[index].active = false;
            }
          });
        }, 500);
      }
    },

    checkIfTabsScrollable(): void {
      const el = document.querySelector('.more-info-modal__nav-tabs');

      if (el instanceof HTMLElement) {
        this.isTabsScrollable = el.scrollWidth > el.clientWidth;
      }
    },

    navTabsScrollHandler(el: Event): void {
      if (el.target instanceof HTMLElement) {
        this.isTabsScrollAtTheEnd = el.target.scrollLeft === el.target.scrollWidth - el.target.clientWidth;
      }
    },

    scrollLeftIconClickHandler(): void {
      const el = document.querySelector('.more-info-modal__nav-tabs');

      if (el instanceof HTMLElement) {
        el.scrollTo({
          left: -100,
          behavior: 'smooth',
        });
      }
    },

    scrollRightIconClickHandler() : void {
      const el = document.querySelector('.more-info-modal__nav-tabs');

      if (el instanceof HTMLElement) {
        el.scrollTo({
          left: 100,
          behavior: 'smooth',
        });
      }
    },
  },
});
