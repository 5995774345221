
import { defineComponent, PropType } from 'vue';

export const typesOfIcon = {
  info: 'info',
  warning: 'warning',
  error: 'error',
  success: 'success',
  highlight: 'highlight',
} as const;

export const sizes = {
  big: 'big',
  full: 'full',
  small: 'small',
} as const;

export default defineComponent({
  name: 'IconWrapper',

  props: {
    type: {
      required: true,
      type: String as PropType<keyof typeof typesOfIcon>,
      validator (val: keyof typeof typesOfIcon) {
        return Object.keys(typesOfIcon).includes(val);
      },
    },

    size: {
      type: String as PropType<keyof typeof sizes>,
      default: sizes.big,
      validator (val: keyof typeof sizes) {
        return Object.keys(sizes).includes(val);
      },
    },
  },

  computed: {
    getTypeClass (): string {
      return this.type ? `icon-wrapper--${this.type}` : '';
    },

    getSizeClass (): string {
      return this.size ? `icon-wrapper--${this.size}` : '';
    },
  },
});
