// Helpers
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { isBookingPortal } from '@white-label-helper/is-booking-portal';
import { getPaymentProvider } from './helperPaymentProviderConfig';

// Constants
import { PAYMENT_METHODS} from "@white-label-configuration/constants";

// Types
import type { Partners } from '@white-label-types/partners';
import type { CheckoutFormMixin, This } from './checkout-form';

const checkoutForm: CheckoutFormMixin = {
  props: {
    checkoutForm_additionalFields: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      checkoutForm_PAYMENT_METHODS: PAYMENT_METHODS,
      checkoutForm_paymentMethod: 'card',
      checkoutForm_validationMessages: {
        license_plate: this.$t('UI.formErrors.licensePlateNumber'),
        first_name: this.$t('UI.formErrors.firstname'),
        last_name: this.$t('UI.formErrors.lastname'),
        phone: this.$t('UI.formErrors.phone'),
        email: this.$t('UI.formErrors.email'),
        emailConfirmation: this.$t('UI.formErrors.emailConfirmation'),
        zipcode: this.$t('UI.formErrors.zipCode'),
      },
      checkoutForm_paymentProviders: isBookingPortal ? [] : getPaymentProvider(),
    };
  },

  computed: {
    policyLinks() {
      const {
        terms_and_conditions,
        privacy_policy,
      }: {
        terms_and_conditions: Partners['page_texts']['terms_and_conditions'],
        privacy_policy: Partners['page_texts']['privacy_policy']
      } = getAppVariable('page_texts');

      const language: Partners['default_language'] = getAppVariable('default_language');
      const termsUrl = process.env.NUXT_ENV_TERMS_URL || `${process.env.NUXT_ENV_ECOMMERCE_URL}/terms-and-conditions`;
      const privacyUrl = process.env.NUXT_ENV_PRIVACY_URL || `${process.env.NUXT_ENV_ECOMMERCE_URL}/privacy-policy`;
      const termsString = terms_and_conditions?.value[language] || '';
      const privacyString = privacy_policy?.value[language] || '';

      return {
        checkoutForm_termsURL: terms_and_conditions?.type === 'text' ? termsUrl : termsString,
        checkoutForm_policyURL: privacy_policy?.type === 'text' ? privacyUrl : privacyString,
      };
    },
  },

  methods: {
    checkoutForm_handlePaymentCompleted(this: This, payload) {
      const { userDetails, metaData } = this;

      // Remove all white spacing from the licence plate number
      if (typeof userDetails.license_plate === 'string') {
        userDetails.license_plate = userDetails.license_plate.replace(/\s/g, '');
      }
      if (isBookingPortal) {
        const userDetailsWithBookingEmails = {
          ...userDetails,
          ...{
            booking_emails: {
              booker_email: userDetails.booker_email,
              others_email: userDetails.others_email,
              send_booker_email: userDetails.send_booker_email,
              send_customer_email: userDetails.send_customer_email,
              send_others_email: userDetails.send_others_email,
            },
          },
        };

        delete userDetailsWithBookingEmails.booker_email;
        delete userDetailsWithBookingEmails.others_email;
        delete userDetailsWithBookingEmails.send_customer_email;
        delete userDetailsWithBookingEmails.send_others_email;
        delete userDetailsWithBookingEmails.send_booker_email;

        if (userDetailsWithBookingEmails.additional_emails) {
          delete userDetailsWithBookingEmails.additional_emails;
        }

        this.$emit('paymentCompleted', { payload, userDetails: userDetailsWithBookingEmails, metaData });
      }

      if (!isBookingPortal) {
        this.$emit('paymentCompleted', { payload, userDetails, metaData });
      }
    },
    checkoutForm_handlePaymentMethodChange(this: This, payload) {
      this.disableButton = false;
      this.checkoutForm_paymentMethod = payload;
    },

    checkoutForm_checkFieldVisibility(fieldName) {
      if (fieldName in this.checkoutForm_additionalFields) {
        return this.checkoutForm_additionalFields[fieldName].is_show;
      }
      return false;
    },

    checkoutForm_checkIfFieldRequired(fieldName) {
      if (this.checkoutForm_additionalFields && fieldName in this.checkoutForm_additionalFields) {
        return this.checkoutForm_additionalFields[fieldName].is_required;
      }
      return false;
    },

    checkoutForm_handleStripePaymentIntentPayload(payload) {
      const { userDetails } = this;

      // Remove all white spacing from the licence plate number
      if (typeof userDetails.license_plate === 'string') {
        userDetails.license_plate = userDetails.license_plate.replace(/\s/g, '');
      }

      this.$emit('stripePaymentIntentPayload', { payload, userDetails });
    },
  },
};

export default checkoutForm;
