
import { defineComponent, PropType } from 'vue';
import type { Breakpoints } from '@white-label-types/styles';
// @ts-ignore - No type definitions for tailwindcss/resolveConfig
import resolveConfig from 'tailwindcss/resolveConfig';
import TailwindConfig from './tailwind.config.js';

const breakpoints = resolveConfig(TailwindConfig).theme.screens;

export default defineComponent({
  name: 'StickySidebar',

  props: {
    // on what breakpoint change main container flex-direction from 'column' to 'row'
    breakpoint: {
      type: String as PropType<Breakpoints>,
      default: 'lg',
      validator (val: Breakpoints) {
        return Object.keys(breakpoints).includes(val);
      },
    },
  },
});
