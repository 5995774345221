import { render, staticRenderFns } from "./dropdown-wrapper.vue?vue&type=template&id=34cfe4cf&scoped=true"
import script from "./dropdown-wrapper.vue?vue&type=script&lang=ts"
export * from "./dropdown-wrapper.vue?vue&type=script&lang=ts"
import style0 from "./dropdown-wrapper.vue?vue&type=style&index=0&id=34cfe4cf&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34cfe4cf",
  null
  
)

export default component.exports