
import { defineComponent } from 'vue';
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { format } from '@white-label-helper/date-utilities';
// import type { SearchLoungeForm } from '@/types/lounges';

export default defineComponent({
  name: 'SearchInfoPanel',

  components: {
    IconButton: () => import('@white-label-icon/icon-button'),
    IconPencil: () => import('@white-label-icon/icon-pencil'),
  },

  props: {
    searchCriteria: {
      type: Object,
      default: () => ({}),
    },
    groups: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      terminals: [] as any[],
      baggageTypes: {
        checked_baggage: this.$t(
          'privateLoungeFeatures.baggage.checked_baggage'
        ),
        cabin_baggage: this.$t('privateLoungeFeatures.baggage.cabin_baggage'),
      },
      airlines: [],
    };
  },

  computed: {
    selectedAirline() {
      return (
        this.airlines.find((i) => i.iata === this.searchCriteria?.airline) || {
          name: '',
        }
      );
    },
    editPanelTitle(): string {
      const { searchCriteria } = this;
      let dateCriteria = '';
      if (searchCriteria?.date && searchCriteria?.time) {
        dateCriteria = format(
          new Date(`${searchCriteria.date}T${searchCriteria?.time}`),
          'hh:mmaaa, MMM d'
        );
      }
      if (searchCriteria?.date2 && searchCriteria?.time2) {
        const exitDateString = format(
          new Date(`${searchCriteria.date2}T${searchCriteria?.time2}`),
          'hh:mmaaa, MMM d'
        );
        dateCriteria = dateCriteria
          ? `${dateCriteria} - ${exitDateString}`
          : exitDateString;
      }
      return `${dateCriteria}${
        this.terminalName ? `, (${this.terminalName})` : ''
      }`;
    },
    editPanelTitle2(): string {
      if (!this.groups) return '';
      const lines: string[] = [];
      if (this.searchCriteria.groups) {
        const keys = ['adult', 'senior', 'child', 'infant'];
        const nums = keys.map((key) => this.searchCriteria.groups[key] || 0);
        keys.forEach((_key, index) => {
          if (nums[index]) {
            const groupDisplayName = this.getGroupDisplayName(
              keys[index],
              nums[index]
            );
            lines.push(`${nums[index]} x ${groupDisplayName}`);
          }
        });
      }
      return lines.join(', ');
    },
    terminalName(): string {
      const terminal = this.terminals.find(
        (i) => i.id.toString() === this.searchCriteria?.terminal?.toString()
      );
      return `${terminal?.name || ''}`;
    },
  },
  created() {
    this.terminals = getAppVariable('terminals');
    this.airlines = getAppVariable('products.lounges.airlines_list') || [];
    if (this.$i18n && this.$i18n.locale) {
      if (this.terminals.length) {
        this.terminals.push({
          name: this.$t('searchBox.dontKnowOption'),
          id: 'dontKnow',
        });
      }
    }
  },
  methods: {
    getGroupDisplayName(group: string, num: number): string {
      let res: string = group.charAt(0).toUpperCase() + group.slice(1);

      if (num > 1) {
        res += 's';
        if (group === 'child') {
          res = 'Children';
        }
      }

      return res;
    },
  },
});
