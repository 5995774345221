import defaultsDeep from 'lodash/defaultsDeep';
import { Options } from './axios-config';
import BaseConfig from './http.base-instance-config';

const { interceptors } = BaseConfig;

interface UserInstance extends Options {
  baseURL: string | undefined;
}

const options: UserInstance = defaultsDeep(
  {
    baseURL: process.env['NUXT_ENV_USER_API_BASE_URL'],
    headers: {
      Authorization: `Bearer ${process.env['NUXT_ENV_AFFILIATE_APPLICATION_TOKEN']}`,
    },
  },
  BaseConfig.options,
);

export default {
  interceptors,
  options,
};
