var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.modalData)?_c('LibModalError',{staticClass:"global-modal",class:[
    _vm.modalData.class,
    {
      'global-modal__warning': _vm.modalData.isWarning,
      'global-modal__success': _vm.modalData.isSuccess,
      'global-modal__info': _vm.modalData.isInfo,
    },
  ],attrs:{"btn-type":_vm.modalData.btnType,"is-success":_vm.modalData.isSuccess,"is-warning":_vm.modalData.isWarning,"is-info":_vm.modalData.isInfo,"close-on-click-outside":_vm.modalData.closeOnClickOutside,"prop-class":"modal--global-error"},on:{"close-modal-error":function($event){return _vm.$emit('close', true)}},scopedSlots:_vm._u([(_vm.modalData.header)?{key:"header",fn:function(){return [_c('div',{staticClass:"global-modal--header"},[_vm._v("\n      "+_vm._s(_vm.modalData.header)+"\n    ")])]},proxy:true}:null,(_vm.modalData.body)?{key:"bodyTextSlot",fn:function(){return [_c('span',{staticClass:"global-modal--body",domProps:{"innerHTML":_vm._s(_vm.modalData.body.replace('\n', '<br>'))}})]},proxy:true}:null,(_vm.isModalButtonsExist)?{key:"btnGroup",fn:function(){return [_c('div',{staticClass:"global-modal__btn-group",class:{ 'global-modal__btn-one-element-group': _vm.isOneButton }},_vm._l((_vm.modalData.buttons),function(button){return _c('CavuButton',{key:button.btnText,staticClass:"global-modal__btn-group-btn",attrs:{"button-type":"secondary","type-of-element":"button","is-content-centered":""},on:{"btnClick":function($event){return _vm.$emit('close', button.callback)}}},[_vm._v("\n        "+_vm._s(button.btnText)+"\n      ")])}),1)]},proxy:true}:null,(_vm.isCustomButtonType)?{key:"custom",fn:function(){return [_c('CavuButton',{attrs:{"button-type":"secondary","prepend-icon":_vm.prependIcon,"is-content-centered":""},on:{"btnClick":function($event){return _vm.$emit('close', true)}}},[_vm._v("\n      "+_vm._s(_vm.modalData.btnText)+"\n    ")])]},proxy:true}:null],null,true)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }