
import { defineComponent } from 'vue';
import { formatPrice } from '@white-label-helper/helper-payment';
import EntryExitInfo from '../entry-exit-info/entry-exit-info.vue';
import IconWrapper from '../icon-wrapper/icon-wrapper.vue';
import PrivateLoungeInfo from '../b-ticket-private-lounge-details/b-ticket-private-lounge-details.vue';



import IconAlert from '@white-label-icon/icon-alert';
import IconArrowDown from '@white-label-icon/icon-arrow-down';
import IconUser from '@white-label-icon/icon-user';
import IconSuccess from '@white-label-icon/icon-success';

export default defineComponent({
  name: 'LoungesTicketInfo',
  filters: {
    formatPrice,
  },
  components: {
    EntryExitInfo,
    IconWrapper,
    IconAlert,
    IconArrowDown,
    IconUser,
    IconSuccess,
    PrivateLoungeInfo,
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    totals: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed:{
    productOption(): string {
      return this.items[0]?.inventory_item?.product_option?.code;
    },
    isPrivateLounge(): boolean {
      return this.productOption === 'lounge_private';
    },
    totalPaid(){
      if(this.totals?.total) {
        return formatPrice(this.totals.total)
      }
      return ''
    }
  }
});
