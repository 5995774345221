
// Packages
import { defineComponent, PropType } from 'vue';

// Types
import type { CartItem } from '@white-label-types/parking-checkout';

// Helpers
import { formatPrice } from '@white-label-helper/helper-payment';

import RadioButton from '../radio-button/radio-button.vue';

export default defineComponent({
  name: 'CancellationAndAmmendmentPolicy',

  components: {
    RadioButton,
    IconArrow: () => import('@white-label-icon/icon-arrow'),
    IconCheck: () => import('@white-label-icon/icon-check'),
  },

  props: {
    cancellationPolicies: {
      type: Array as PropType<CartItem['inventory_option']['cancellation_policies']>,
      default: () => [],
    },
    amendmentPolicies: {
      type: Array as PropType<CartItem['inventory_option']['amendment_policies']>,
      default: () => [],
    },
    isProtected: {
      type: Boolean,
      required: true,
    },
    cancellationProtection: {
      type: Object as PropType<CartItem['cancellation_protection']>,
      required: true,
    },
  },

  data() {
    return {
      standardPolicyExpanded: false,
      upgradedProtectionExpanded: true,
      selectedOption: 'standardOption',
    };
  },

  computed: {
    upgradedProtectionLabel() {
      return `
        ${this.$t('cancellationAndAmendment.upgradedCancellationProtection')}
        ${formatPrice(this.cancellationProtection?.totals?.total || 0)}
      `;
    },
  },

  mounted() {
    if (this.isProtected) {
      this.selectedOption = 'upgradedOption';
    }
  },

  methods: {
    onRadioSelect(value: string): void {
      this.selectedOption = value;

      if (value === 'standardOption') {
        this.$emit('remove-cancellation-protection');
      } else {
        this.$emit('add-cancellation-protection');
      }
    },
  },
});
