
import { defineComponent } from 'vue';
import { BOOKING_STATUSES } from '@white-label-configuration/constants';
import { readBookingStatus } from '@white-label-store/manage-booking';
import Button from '../button/button.vue';

type BookingStatus = typeof BOOKING_STATUSES[keyof typeof BOOKING_STATUSES];

export default defineComponent({
  name: 'ManageBookingCancelButtons',

  components: {
    ControlButton: Button,
  },

  props: {
    isLoading: {
      type: Boolean,
    },
  },

  computed: {
    orderStatus(): BookingStatus {
      return readBookingStatus(this.$store);
    },

    isButtonDisabled(): boolean {
      return this.orderStatus !== BOOKING_STATUSES.CONFIRMED;
    },
  },

  methods: {
    cancelBookingHandler(): void {
      this.$emit('cancel-booking');
    },

    keepBookingHandler(): void {
      this.$emit('keep-booking');
    },
  },
});
