
// Packages
import { defineComponent, PropType } from 'vue';

// Types
import type { SearchProducts } from '@white-label-types/parking-booking';

// Helpers
import { formatPrice } from '@white-label-helper/helper-payment';

export default defineComponent({
  name: 'SearchParkingPrice',

  props: {
    product: {
      type: Object as PropType<SearchProducts>,
      required: true,
    },

    // Only for current booking on modify flow
    showPricePaidLabel: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isCurrentBooking(): boolean {
      return !!this.product.isCurrentBooking;
    },

    hasDiscount(): boolean {
      return this.product?.hasDiscount;
    },

    price(): number {
      const productTotal = this.product?.productOptions[0]?.totals?.channel_based?.total;
      const totalDiscounted = this.product?.productOptions[0]?.totals?.channel_based?.total_discounted;

      return this.hasDiscount ? totalDiscounted : productTotal;
    },

    fullPrice(): string | Intl.NumberFormat {
      return formatPrice(this.product?.productOptions[0]?.totals?.channel_based?.total);
    },

    flooredPrice(): string {
      const formattedPrice = this.isCurrentBooking && this.product.total !== undefined
        ? formatPrice(this.product.total)
        : formatPrice(+this.price);

      return typeof formattedPrice === 'string' ? formattedPrice.split('.')[0] : '';
    },

    priceDecimal(): string {
      let priceDecimal = '';

      if (this.isCurrentBooking && this.product.total !== undefined) {
        const formattedPrice = formatPrice(this.product.total);
        priceDecimal = typeof formattedPrice === 'string' ? formattedPrice.split('.')[1] : '';
      } else {
        priceDecimal = formatPrice(this.price).toString().slice(-2);
      }

      return priceDecimal;
    },
  },
});
